import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { setUserData, setLoginStatus, setToken, setRefreshToken } from "../../actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
export default function ProductList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleLogin();
  }, []);

  async function handleLogin() {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");

    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/autologin", {
      id: id,
    });
    if (Response.data.success == true) {
      dispatch(setUserData(Response.data.data));
      dispatch(setLoginStatus(true));
      dispatch(setToken(Response.data.token));
      dispatch(setRefreshToken(Response.data.refreshtoken));

      toast.success(Response.data.message);
      navigate("/crm");
    } else {
      toast.error(Response.data.message);
    }
  }

  return (
    <>
      <div className="text-center h5 m-5">Please Wait...</div>
    </>
  );
}
