import React from "react";
import { Box, Anchor } from "../elements";
import { setUserData, setToken, setRefreshToken, setLoginStatus, setAdminData, setAdmin, setCompanyList, setSelectedCompany } from "../../actions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
export default function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function handleLogout() {
    setUserData([]);
    setAdminData([]);
    dispatch(setLoginStatus(false));
    setAdmin(false);
    setToken("");
    setRefreshToken("");
    navigate("/");
    setCompanyList([]);
    setSelectedCompany({});
    toast.success("Logged out");
  }

  return (
    <Box className="mt-5 text-center">
      <Anchor href={"/"} text={"Logout"} className="mc-btn primary sm" onClick={() => handleLogout()} />
    </Box>
  );
}
