import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, Card, Row, Col, Table, Spinner, ButtonGroup, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import qs from "qs";
import MCQ from "./MCQ";
import "suneditor/dist/css/suneditor.min.css";
import { FaLink } from "react-icons/fa6";
import { FaFilePdf } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa";
import SunEditor from "suneditor-react";

function ContentData({ selectedModule }) {
  const { userData, token, selectedCompany, refreshToken } = useSelector((state) => state.counter);
  const [title, setTitle] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [contentType, setContentType] = useState("");
  const [content, setContent] = useState("");
  const [uploadData, setUploadData] = useState(false);
  const [Saving, setSaving] = useState(false);
  const [retake, setRetake] = useState("no");
  const [contentId, setContentId] = useState("");
  const [eligibility, setEligibility] = useState("0");

  const [data, setData] = useState([]);
  useEffect(() => {
    LoadData();
  }, []);

  // Load All data To Display In Table
  function LoadData() {
    const params = {
      id: selectedModule,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    const options = {
      headers: { authorization: token },
    };

    axios.get(process.env.REACT_APP_API_URL + "app/modules/module-content?" + queryString, {}, options).then(
      (response) => {
        setData(response.data.result);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  // On File Select Load It To Memory
  function fileHandler(e) {
    const file = e.currentTarget.files[0];
    setUploadData(file);
  }

  async function handleModulesContentUpdate() {
    if (contentType == "") return toast.error("Content type is required");
    if (title == "") return toast.error("Title is required");

    setSaving(true);

    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const uploadFormData = new FormData();
    uploadFormData.append("type", contentType);
    uploadFormData.append("upload", uploadData);
    uploadFormData.append("title", title);
    uploadFormData.append("content", content);
    uploadFormData.append("retake", retake);
    uploadFormData.append("module", selectedModule);
    uploadFormData.append("id", contentId);
    uploadFormData.append("eligibility", eligibility);
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/modules/content-update", uploadFormData, options);
    if (Response.data.success == true) {
      setSaving(false);
      toast(Response.data.message);
      LoadData();
      setIsOpen2(false);

      setTitle("");
      setContentType("");
      setContent("");
      setUploadData(false);
      setRetake("no");
      setEligibility("");
    } else {
      toast(Response.data.message);
      setSaving(false);
    }
  }
  async function handleModulesContentCreate() {
    if (contentType == "") return toast.error("Content type is required");
    if (title == "") return toast.error("Title is required");
    if (contentType == "PDF" && !uploadData) return toast.error("PDF is required");

    setSaving(true);

    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const uploadFormData = new FormData();
    uploadFormData.append("type", contentType);
    uploadFormData.append("upload", uploadData);
    uploadFormData.append("title", title);
    uploadFormData.append("content", content);
    uploadFormData.append("retake", retake);
    uploadFormData.append("module", selectedModule);
    uploadFormData.append("eligibility", eligibility);

    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/modules/content-create", uploadFormData, options);
    if (Response.data.success == true) {
      setSaving(false);
      toast(Response.data.message);
      LoadData();
      setIsOpen(false);

      setTitle("");
      setContentType("");
      setContent("");
      setUploadData(false);
      setRetake("no");
      setEligibility("");
    } else {
      toast(Response.data.message);
      setSaving(false);
    }
  }
  function setEditData(data) {
    setContentType(data.type);
    setContent(data.content);
    setTitle(data.title);
    setContentId(data._id);
    setRetake(data.retake);
    setEligibility(data.eligibility);
  }
  // Soft Delete Record
  async function DeleteRecord(id) {
    const options = {
      headers: { authorization: token },
    };
    const data = qs.stringify({ id: id });

    axios.post(process.env.REACT_APP_API_URL + "app/modules/content-delete", data, options).then((response) => {
      if (response.data.success == true) {
        toast(response.data.message);
        LoadData();
      } else {
        toast("Something went wrong");
      }
    });
  }

  async function updateOrder() {
    var newOrderArray = [];
    data.length > 0 &&
      data.map((item, key) =>
        newOrderArray.push({
          id: item._id,
          order: document.getElementById(item._id).value,
        })
      );

    const options = {
      headers: { authorization: token },
    };
    const postData = qs.stringify({ data: JSON.stringify(newOrderArray) });

    axios.post(process.env.REACT_APP_API_URL + "app/modules/update-order", postData, options).then((response) => {
      if (response.data.success == true) {
        LoadData();
        toast.success(response.data.message);
      } else {
        toast("Something went wrong");
      }
    });
  }

  return (
    <>
      <div className="">
        <ButtonGroup className=" float-end">
          <button type="submit" className="btn btn-primary" onClick={(e) => setIsOpen(true)}>
            Add new content
          </button>
          <button type="submit" className="btn btn-primary" onClick={(e) => updateOrder()}>
            Update Order
          </button>
        </ButtonGroup>
      </div>
      <br /> <br />
      <div className="table-responsive">
        <Table className="text-nowrap mt-3 align-middle" borderless>
          <thead>
            <tr>
              <th>Order</th>
              <th>Title</th>
              <th>Type</th>
              <th>Preview</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item, key) => (
                <tr className="border-top" key={key}>
                  <td>
                    <input type="number" defaultValue={item.order} className="form-control" id={item._id} />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.type}</td>
                  <td>
                    {(item.type == "Video" || item.type == "Slides") && (
                      <a href={item.content} size="sm" color="danger" target={"_blank"}>
                        <FaLink />
                      </a>
                    )}

                    {item.type == "PDF" && (
                      <a href={item.file} size="sm" color="danger" target={"_blank"}>
                        <FaFilePdf />
                      </a>
                    )}
                  </td>

                  <td>
                    <ButtonGroup>
                      {item.type == "MCQ" && (
                        <Button
                          size="sm"
                          color="danger"
                          onClick={(e) => {
                            setIsOpen3(true);
                            setContentId(item._id);
                          }}
                        >
                          <FaBookOpen />
                        </Button>
                      )}

                      <Button
                        size="sm"
                        color="danger"
                        onClick={(e) => {
                          setIsOpen2(true);
                          setEditData(item);
                        }}
                      >
                        Edit
                      </Button>
                      <Button size="sm" color="danger" onClick={(e) => DeleteRecord(item._id)}>
                        Delete
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <Modal size="lg" show={isOpen} onHide={() => setIsOpen(false)}>
        <ModalHeader closeButton>Add Content</ModalHeader>
        <ModalBody>
          <Card>
            <Card.Body>
              <Row className="justify-content-center mt-2 mb-3">
                <Col className="col-md-2 pt-1">Module Type </Col>
                <Col className="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="pdf" checked={contentType == "PDF" ? true : false} onClick={() => setContentType("PDF")} />
                    <label class="form-check-label" for="pdf">
                      PDF
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="video" checked={contentType == "Video" ? true : false} onClick={() => setContentType("Video")} />
                    <label class="form-check-label" for="video">
                      Video
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="HTML" checked={contentType == "HTML" ? true : false} onClick={() => setContentType("HTML")} />
                    <label class="form-check-label" for="HTML">
                      HTML
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="mcq" checked={contentType == "MCQ" ? true : false} onClick={() => setContentType("MCQ")} />
                    <label class="form-check-label" for="mcq">
                      MCQ
                    </label>
                  </div>

                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="assignment" checked={contentType == "assignment" ? true : false} onClick={() => setContentType("assignment")} />
                    <label class="form-check-label" for="assignment">
                      Assignment
                    </label>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Title</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setTitle(e.target.value)} value={title} />
                </Col>
              </Row>

              {contentType != "PDF" && contentType != "MCQ" && contentType != "HTML" && contentType != "assignment" && (
                <Row className="justify-content-center mt-2">
                  <Col className="col-md-2 pt-1">URL </Col>
                  <Col className="col-md-6">
                    <input type="url" className="form-control" onChange={(e) => setContent(e.target.value)} value={content} />
                  </Col>
                </Row>
              )}

              {contentType == "PDF" && (
                <Row className="justify-content-center mt-2">
                  <Col className="col-md-2 pt-1">File</Col>
                  <Col className="col-md-6">
                    <input name="file" accept="application/pdf" type="file" className={"form-control"} onChange={(e) => fileHandler(e)} />
                  </Col>
                </Row>
              )}

              {contentType == "MCQ" && (
                <>
                  <Row className="justify-content-center mt-2">
                    <Col className="col-md-2 pt-1">Allow retake</Col>
                    <Col className="col-md-6">
                      <select className={"form-control"} value={retake} onChange={(e) => setRetake(e.target.value)}>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </select>
                    </Col>
                  </Row>{" "}
                  <Row className="justify-content-center mt-2">
                    <Col className="col-md-2 pt-1">Eligibility percentage</Col>
                    <Col className="col-md-6">
                      <input type="number" className="form-control" onChange={(e) => setEligibility(e.target.value)} value={eligibility} />
                    </Col>
                  </Row>
                </>
              )}

              {(contentType == "HTML" || contentType == "assignment") && (
                <>
                  <Row className="justify-content-center mt-5">
                    <Col className="col-md-8">
                      <SunEditor
                        setOptions={{
                          buttonList: [
                            ["font"],
                            ["fontSize", "formatBlock"],
                            ["align", "horizontalRule", "lineHeight", "list"],
                            ["bold", "underline", "italic", "strike"],
                            ["fontColor", "hiliteColor"],
                            ["removeFormat"],
                            ["blockquote", "outdent", "indent"],
                            ["table"],
                            ["link", "video"],
                            ["image"],
                            ["codeView", "fullScreen", "showBlocks"],
                          ],
                        }}
                        setContents={content}
                        height="250px"
                        onChange={setContent}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Row className="justify-content-center mt-4">
                <Col className="col-md-4 mt-3">
                  {Saving ? (
                    <button type="submit" className="btn btn-primary  mb-3" disabled={true}>
                      <Spinner as="span" animation="grow" size="sm" />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary  mb-3" onClick={() => handleModulesContentCreate()}>
                      Save
                    </button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </ModalBody>
      </Modal>
      <Modal size="lg" show={isOpen2} onHide={() => setIsOpen2(false)}>
        <ModalHeader closeButton>Edit Content</ModalHeader>
        <ModalBody>
          <Card>
            <Card.Body>
              <Row className="justify-content-center mt-2 mb-3">
                <Col className="col-md-2 pt-1">Module Type </Col>
                <Col className="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="pdf" checked={contentType == "PDF" ? true : false} onClick={() => setContentType("PDF")} />
                    <label class="form-check-label" for="pdf">
                      PDF
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="video" checked={contentType == "Video" ? true : false} onClick={() => setContentType("Video")} />
                    <label class="form-check-label" for="video">
                      Video
                    </label>
                  </div>

                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="HTML" checked={contentType == "HTML" ? true : false} onClick={() => setContentType("HTML")} />
                    <label class="form-check-label" for="HTML">
                      HTML
                    </label>
                  </div>

                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="mcq" checked={contentType == "MCQ" ? true : false} onClick={() => setContentType("MCQ")} />
                    <label class="form-check-label" for="mcq">
                      MCQ
                    </label>
                  </div>

                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="assignment" checked={contentType == "assignment" ? true : false} onClick={() => setContentType("assignment")} />
                    <label class="form-check-label" for="assignment">
                      Assignment
                    </label>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Title</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setTitle(e.target.value)} value={title} />
                </Col>
              </Row>

              {/* {contentType != "PDF" && contentType != "MCQ" && contentType != "HTML" && (
                                <Row className="justify-content-center mt-2">
                                    <Col className="col-md-2 pt-1">URL </Col>
                                    <Col className="col-md-6">
                                        <input type="url" className="form-control" onChange={(e) => setContent(e.target.value)} value={content} />
                                    </Col>
                                </Row>
                            )} */}

              {contentType != "PDF" && contentType != "MCQ" && contentType != "HTML" && contentType != "assignment" && (
                <Row className="justify-content-center mt-2">
                  <Col className="col-md-2 pt-1">URL </Col>
                  <Col className="col-md-6">
                    <input type="url" className="form-control" onChange={(e) => setContent(e.target.value)} value={content} />
                  </Col>
                </Row>
              )}

              {contentType == "PDF" && (
                <Row className="justify-content-center mt-2">
                  <Col className="col-md-2 pt-1">File</Col>
                  <Col className="col-md-6">
                    <input name="file" accept="application/pdf" type="file" className={"form-control"} onChange={(e) => fileHandler(e)} />
                  </Col>
                </Row>
              )}

              {contentType == "MCQ" && (
                <>
                  <Row className="justify-content-center mt-2">
                    <Col className="col-md-2 pt-1">Allow retake</Col>
                    <Col className="col-md-6">
                      <select className={"form-control"} value={retake} onChange={(e) => setRetake(e.target.value)}>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </select>
                    </Col>
                  </Row>

                  <Row className="justify-content-center mt-2">
                    <Col className="col-md-2 pt-1">Eligibility percentage</Col>
                    <Col className="col-md-6">
                      <input type="number" className="form-control" onChange={(e) => setEligibility(e.target.value)} value={eligibility} />
                    </Col>
                  </Row>
                </>
              )}

              {contentType == "HTML" && (
                <>
                  <Row className="justify-content-center mt-5">
                    <Col className="col-md-8">
                      <SunEditor
                        setOptions={{
                          buttonList: [
                            ["font"],
                            ["fontSize", "formatBlock"],
                            ["align", "horizontalRule", "lineHeight", "list"],
                            ["bold", "underline", "italic", "strike"],
                            ["fontColor", "hiliteColor"],
                            ["removeFormat"],
                            ["blockquote", "outdent", "indent"],
                            ["table"],
                            ["link", "video"],
                            ["image"],
                            ["codeView", "fullScreen", "showBlocks"],
                          ],
                        }}
                        setContents={content}
                        height="250px"
                        onChange={setContent}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {contentType == "HTML" ||
                (contentType == "assignment" && (
                  <>
                    <Row className="justify-content-center mt-5">
                      <Col className="col-md-8">
                        <SunEditor
                          setOptions={{
                            buttonList: [
                              ["font"],
                              ["fontSize", "formatBlock"],
                              ["align", "horizontalRule", "lineHeight", "list"],
                              ["bold", "underline", "italic", "strike"],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              ["blockquote", "outdent", "indent"],
                              ["table"],
                              ["link", "video"],
                              ["image"],
                              ["codeView", "fullScreen", "showBlocks"],
                            ],
                          }}
                          setContents={content}
                          height="250px"
                          onChange={setContent}
                        />
                      </Col>
                    </Row>
                  </>
                ))}

              <Row className="justify-content-center mt-4">
                <Col className="col-md-4 mt-3">
                  {Saving ? (
                    <button type="submit" className="btn btn-primary  mb-3" disabled={true}>
                      <Spinner as="span" animation="grow" size="sm" />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary  mb-3" onClick={() => handleModulesContentUpdate()}>
                      Save
                    </button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </ModalBody>
      </Modal>
      <Modal size="lg" show={isOpen3} onHide={() => setIsOpen3(false)}>
        <ModalHeader closeButton>MCQs</ModalHeader>
        <ModalBody>
          <MCQ selectedContent={contentId} />
        </ModalBody>
      </Modal>
    </>
  );
}
export default ContentData;
