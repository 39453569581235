import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { setSaveIndicator, setSelectedAppointment } from "../../actions";
import { useDispatch } from "react-redux";
import { useScreenshot } from "use-react-screenshot";

export default function ProductList() {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => takeScreenshot(ref.current);

  const [pathData, setPathData] = useState(null);
  const [sensation, setSensation] = useState("");
  const [zoneData, setZoneData] = useState({});
  const [selectedZone, setSelectedZone] = useState("");
  const [show, setShow] = useState(false);
  const { userData, token, refreshToken, selectedAppointmentId } = useSelector((state) => state.counter);
  const [anteriorZones, setAnteriorZones] = useState([]);

  useEffect(() => {
    const svgObject = document.querySelector('object[data="./images/basic_men_front.svg"]');
    if (svgObject) {
      svgObject.addEventListener("load", () => {
        const svgDocument = svgObject.contentDocument;
        const pathElements = svgDocument.querySelectorAll("path");

        pathElements.forEach((path) => {
          path.addEventListener("click", handlePathClick);

          // Add hover effect using inline style
          path.addEventListener("mouseover", () => {
            if (path.getAttribute("selected") != "true") {
              path.style.fill = "#A08E7A";
            }
          });

          path.addEventListener("mouseout", () => {
            if (path.getAttribute("selected") != "true") {
              path.style.fill = "#F2D5B6";
            }
          });
        });
        setTimeout(function () {
          getBody();
        }, 1000);
      });
    }
  }, []);

  useEffect(() => {
    if (selectedZone != "") {
      const matchingZone = anteriorZones.find((zone) => zone.id === Number(selectedZone));

      if (matchingZone) {
        matchingZone.pathData.style.fill = matchingZone.color;
        matchingZone.pathData.setAttribute("selected", matchingZone.selected);
      }
      syncBody();
    }
  }, [anteriorZones]);

  const getBody = async () => {
    dispatch(setSaveIndicator(true));
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/reports/detail?id=" + selectedAppointmentId, options);
    if (Response.data.success) {
      var anteriorViewMale = [];

      if (Response.data.result && Response.data.result.anteriorViewMale) {
        var result = Response.data.result.anteriorViewMale;
        var a = document.getElementById("basic_men_front");
        var svgDoc = a.contentDocument;

        for (let i = 0; i < result.length; i++) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = result[i].pathData;
          const svgElement = tempDiv.firstChild;

          var el = svgDoc.querySelectorAll('[data-id="' + result[i].id + '"]')[0];

          el.style.fill = result[i].color;
          el.setAttribute("selected", result[i].selected);
          console.log("el", result[i].selected);
          anteriorViewMale.push({
            zone: result[i].zone,
            color: result[i].color,
            id: result[i].id,
            level: result[i].level,
            selected: result[i].selected,
            sensation: result[i].sensation,
            timestamp: result[i].timestamp,
            pathData: svgElement,
            // pathData: result[i].pathData,
          });
        }

        setAnteriorZones(anteriorViewMale);
        dispatch(setSaveIndicator(false));
      } else {
        dispatch(setSaveIndicator(false));
      }
    } else {
      dispatch(setSaveIndicator(false));
      toast.error(Response.data.message);
    }
  };

  const syncBody = async () => {
    dispatch(setSaveIndicator(true));
    const options = {
      headers: { authorization: token },
    };

    var anteriorViewMale = [];
    if (anteriorZones) {
      for (let i = 0; i < anteriorZones.length; i++) {
        var s = new XMLSerializer();
        //if (anteriorZones[i].selected) {
        anteriorViewMale.push({
          zone: anteriorZones[i].zone,
          color: anteriorZones[i].color,
          id: anteriorZones[i].id,
          level: anteriorZones[i].level,
          selected: anteriorZones[i].selected,
          sensation: anteriorZones[i].sensation,
          timestamp: anteriorZones[i].timestamp,
          pathData: s.serializeToString(anteriorZones[i].pathData),
        });
        //}
      }

      const urlString = window.location.search;
      const urlParams = new URLSearchParams(urlString);
      const id = urlParams.get("id");

      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/appointment/sync?practitioner=" + userData._id + "&patient=" + id + "&id=" + selectedAppointmentId,
        {
          anteriorViewMale: anteriorViewMale,
        },
        options
      );
      if (Response.data.status == "success") {
        var anteriorViewMale = [];
        var result = Response.data.result.anteriorViewMale;
        var a = document.getElementById("basic_men_front");
        var svgDoc = a.contentDocument;

        for (let i = 0; i < result.length; i++) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = result[i].pathData;
          var el = svgDoc.querySelectorAll('[data-id="' + result[i].id + '"]')[0];
          el.style.fill = result[i].color;
          el.setAttribute("selected", result[i].selected);
        }

        dispatch(setSaveIndicator(false));

        dispatch(setSelectedAppointment(Response.data.id));

        var objectElement = document.getElementById("basic_men_front");
        // Access the document inside the object element
        const svgDocument = objectElement.contentDocument;
        // Extract the SVG element from the document
        const svgElement = svgDocument.querySelector("svg");
        var svgString = new XMLSerializer().serializeToString(svgElement);
        await axios.post(
          process.env.REACT_APP_API_URL + "app/appointment/create_image?id=" + selectedAppointmentId + "_basic_men_front",
          {
            image: svgString,
          },
          options
        );
      } else {
        dispatch(setSaveIndicator(false));
        toast.error(Response.data.message);
      }
    }
  };

  const handlePathClick = async (event) => {
    const path = event.target;
    setPathData(path);
    const dataId = path.getAttribute("data-id");
    if (dataId) {
      const options = {
        headers: { authorization: token },
      };
      const Response = await axios.get(process.env.REACT_APP_API_URL + "app/zone/get?id=" + dataId, options);
      setZoneData(Response.data.result);
      setSelectedZone(dataId);
      setSensation("");
      setShow(true);
    }
  };

  function addZone() {
    if (sensation == "") {
      return toast.error("Select sensation level");
    }

    var sensationText = "";
    var color = "";
    if (sensation <= 1) {
      sensationText = "No discomfort, feeling good";
      color = "#157347";
    } else if (sensation >= 2 && sensation <= 3) {
      sensationText = "Mild discomfort";
      color = "#FFDF28";
    } else if (sensation >= 4 && sensation <= 6) {
      sensationText = "Moderate, noticeable discomfort but still manageable.";
      color = "#FF6700";
    } else if (sensation >= 7 && sensation <= 8) {
      sensationText = "Severe discomfort, significantly impacting movement or quality of life";
      color = "#FF0808";
    } else {
      sensationText = "Extreme, debilitating discomfort needing treatment";
      color = "#530808";
    }

    const itemIndex = anteriorZones.findIndex((item) => item.id === zoneData.id);
    const updatedItems = [...anteriorZones];
    const newItem = { zone: zoneData.zone, selected: true, sensation: sensationText, level: sensation, id: zoneData.id, color: color, timestamp: Math.floor(Date.now()), pathData: pathData };
    if (itemIndex !== -1) {
      updatedItems[itemIndex] = newItem;
    } else {
      updatedItems.push(newItem);
    }
    setAnteriorZones(updatedItems);
    setShow(false);
  }

  function removeZone(zoneId) {
    setSelectedZone(zoneId);
    const updatedZones = anteriorZones.map((zone) => {
      if (zone.id === zoneId) {
        return { ...zone, selected: false, sensation: "", level: 0, color: "#F2D5B6", timestamp: Math.floor(Date.now()) };
      }
      return zone;
    });
    setAnteriorZones(updatedZones);
  }

  return (
    <>
      <div>
        <div className="row" ref={ref}>
          <div className="col-md-4">
            <div className="text-center">
              <object id="basic_men_front" data="./images/basic_men_front.svg" type="image/svg+xml" height="700"></object>
            </div>
          </div>
          <div className="col-md-8">
            {anteriorZones.filter((obj) => obj.selected).length == 0 && (
              <>
                <div class="alert alert-warning text-center mt-5" role="alert">
                  Please select the zone/zones in which you are feeling the sensations by clicking on the left-side image.
                </div>
              </>
            )}
            <div className="row">
              {anteriorZones.map((zone) => (
                <>
                  {zone.selected && (
                    <>
                      <div className="col-md-6">
                        <div className="card" key={zone.zone}>
                          <div className="card-header">
                            <div className="float-start">Zone {zone.zone}</div>
                            <div class="float-end">
                              <button type="button" class="btn-close" aria-label="Close" onClick={() => removeZone(zone.id)}></button>
                            </div>
                          </div>

                          <div className="card-body">
                            <p className="card-text">
                              <p>
                                <b>Level:</b> {zone.level}
                              </p>
                              <p>
                                <b>Sensation:</b> {zone.sensation}
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>{zoneData.desc}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Sensation level</label>
            <select className="form-control" value={sensation} onChange={(e) => setSensation(e.target.value)}>
              <option value=""> Select </option>
              <option value="1">01 - No discomfort, feeling good</option>
              <option value="2">02 - Mild discomfort</option>
              <option value="3">03 - Mild discomfort</option>
              <option value="4">04 - Moderate, noticeable discomfort but still manageable.</option>
              <option value="5">05 - Moderate, noticeable discomfort but still manageable.</option>
              <option value="6">06 - Moderate, noticeable discomfort but still manageable.</option>
              <option value="7">07 - Severe discomfort, significantly impacting movement or quality of life</option>
              <option value="8">08 - Severe discomfort, significantly impacting movement or quality of life</option>
              <option value="9">09 - Extreme, debilitating discomfort needing treatment</option>
              <option value="10">10 - Extreme, debilitating discomfort needing treatment</option>
            </select>
            <small className="form-text text-muted">Select numbers 1 to 10 to indicate your tolerance and functional capacity (sensation) levels.</small>
          </div>

          <button type="submit" className="btn btn-primary w-100 mt-3" onClick={() => addZone()}>
            Add
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
