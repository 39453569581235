import React, { useRef } from "react";
import { CardLayout } from "./../../components/cards";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "./../../components/elements";
import html2pdf from "html2pdf.js";
import moment from "moment";

export default function MyCourse() {
  const { userData } = useSelector((state) => state.counter);
  const contentRef = useRef(null);
  const [data, setData] = useState([]);

  // Calling Load data On Run Time
  useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);

    const params = {
      slug: urlParams.get("id"),
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    axios.get(process.env.REACT_APP_API_URL + "app/courses/certificate?" + queryString).then(
      (response) => {
        setData(response.data.data);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  const convertToPdf = () => {
    const content = contentRef.current;

    const options = {
      filename: "my-document.pdf",
      margin: 1,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 10 },
      jsPDF: {
        unit: "in",
        format: "letter",
        orientation: "landscape",
      },
    };

    html2pdf().set(options).from(content).save();
  };

  return (
    <div className="container">
      <Row>
        <Col xl={12}>
          <CardLayout>
            <div className="course-list-section">
              <div className="row d-flex justify-content-between align-items-center">
                <div className="filter-list"></div>
              </div>
              <div className="row mt-3">
                <Col xl={12}>
                  <div className="text-center mb-3">
                    <button onClick={convertToPdf} className="mc-btn primary">
                      Download
                    </button>
                  </div>

                  <Box className="mc-table-responsive page" id="divToPrint">
                    {data.complete == 1 && (
                      <div class="outer-border page" ref={contentRef}>
                        <div class="inner-dotted-border">
                          <span class="certification">Certificate of Completion</span>
                          <br />
                          <br />
                          <span class="certify">
                            <i>This is to certify that</i>
                          </span>
                          <br />
                          <br />
                          <span class="name">
                            <b>
                              {data.student && data.student.fname} {data.student && data.student.lname}
                            </b>
                          </span>
                          <br />
                          <br />
                          <span class="certify">
                            <i>has successfully completed the certification</i>
                          </span>{" "}
                          <br />
                          <br />
                          <span class="fs-20">{data.module && data.module.title}</span> <br />
                          <br />
                          <span>Date: {moment().format("MMM Do YYYY")}</span>{" "}
                        </div>
                      </div>
                    )}

                    {data.complete == 0 && <div class="outer-border text-center">This course is not completed. Certificate cannot be downloaded</div>}
                  </Box>
                </Col>
              </div>
            </div>
          </CardLayout>
        </Col>
      </Row>
    </div>
  );
}
