import React from "react";
import PageLayout from "../../layouts/PageLayout";
import toast from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import Content from "../../components/Content";

export default function Category() {
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.counter);
  const [cms, setCMS] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [contentData, setContentData] = useState("");
  const [expired, setExpired] = useState(false);
  const [desc, showDesc] = useState("");
  const [count, showCount] = useState(0);

  const [parentCourseName, setParentCourseName] = useState("");
  const [subCourseName, setSubCourseName] = useState("");
  const [courseName, setCourseName] = useState("");
  const [SelectedKey, setSelectedKey] = useState("S");
  const [slug, setSlug] = useState("");
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  // Calling Load data On Run Time
  useEffect(() => {
    LoadContent();
  }, []);

  // Load All data To Display In Table
  function LoadContent() {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);

    const params = {
      slug: urlParams.get("id"),
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    axios.get(process.env.REACT_APP_API_URL + "app/courses/detail?" + queryString).then(
      (response) => {
        setCMS(response.data.module);
        setParentCourseName(response.data.module.title);
        var arr = [];
        arr.push(response.data.module._id);
        setCourseName(cms.title);
        showCount(arr.length);
        LoadData(arr);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  ///////////////

  function LoadData(arr) {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    var slug = urlParams.get("id");
    const options = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios.get(process.env.REACT_APP_API_URL + "app/courses/content?id=" + arr.join() + "&student=" + userData._id + "&module=" + slug, options).then(
      (response) => {
        setData(response.data.content);
        setExpired(response.data.expired);
        showDesc(response.data.content[0].module.desc);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  function redarContent(data, totalModule, key, module, restricted, hours, parentCourseName, subCourseName, id) {
    if (expired) {
      toast.error("Oops! This module is expired");
      return;
    }

    if (restricted) {
      toast.error("Oops! Content is locked. Please try after " + hours + " hours");
      return;
    }

    // if (totalModule == key + 1) {
    const options = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios.get(process.env.REACT_APP_API_URL + "app/courses/complete-module?type=" + data.type + "&student=" + userData._id + "&module=" + data._id + "&id=" + module + "&main=" + cms._id, options).then(
      (response) => {
        console.log("Marked complete");
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
    // }

    setSubCourseName(subCourseName);
    setContentData({
      id: data._id,
      type: data.type,
      title: data.title,
      content: data.content,
      file: data.file,
      retake: data.retake,
    });

    setSelectedKey(id);
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="container">
            <div className="row">
              <div className="course-study-details row">
                <div className="col-md-12">
                  <h2>{parentCourseName}</h2>
                </div>
                <div className="study-left-side col-md-7">
                  <div className="study-view-part">
                    <Content content={contentData} desc={desc} module={slug} />
                  </div>
                </div>
                <div className={`study-right-side col-md-5 ${isOpen ? "open" : ""}`}>
                  <div className="mobile-toggle" onClick={toggleMenu}>
                    <i className="fas fa-arrow-left mr-2"></i>
                    <i className="fas fa-book-open"></i>
                  </div>
                  <div className="right-side-tab">
                    <div className="title-part">Course content</div>
                    <Accordion defaultActiveKey={"1"}>
                      <Accordion.Item>
                        <Accordion.Header
                          onClick={() => {
                            showDesc(cms.desc);
                            setContentData("");
                          }}
                        >
                          Introduction
                        </Accordion.Header>
                      </Accordion.Item>

                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <Accordion.Item eventKey={key}>
                            <Accordion.Header
                              onClick={() => {
                                showDesc(item.module.desc);
                                setContentData("");
                              }}
                            >
                              {Number(key) + 1}: {count > 1 ? item.module.altTitle.slice(0, 35) : item.module.title.slice(0, 35)}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="module-list" style={{ cursor: "pointer" }}>
                                {item.content?.length > 0 &&
                                  item.content.map((item2, key2) => (
                                    <>
                                      {item2.type != "Video" && (
                                        <>
                                          <div
                                            className="module-item"
                                            key={key2}
                                            onClick={() => redarContent(item2, item.content?.length, key2, item.module._id, item.restricted, item.hours, count > 1 ? item.module.altTitle : item.module.title, "Chapter " + (key2 + 1) + " - " + item2.title, item2._id)}
                                          >
                                            {SelectedKey == item2._id ? <b>- {item2.title}</b> : <>- {item2.title}</>}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ))}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
