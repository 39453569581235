import React from "react";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { CardLayout } from "./../../components/cards";
import { Anchor, Box, Button } from "./../../components/elements";
import { useNavigate } from "react-router-dom";
import { Col, Row, Table, Modal, ModalHeader, ModalBody, Spinner, closeButton } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import SweetAlert from "react-bootstrap-sweetalert";
export default function Category() {
  const { userData, token, selectedCompany, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [PerPage, setPerpage] = useState(10);
  const [TotalRecod, setTotalRecod] = useState(0);
  const [Data, setData] = useState({});
  const [EditData, setEditData] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [Mode, setMode] = useState("Add");
  const [Saving, setSaving] = useState(false);
  const [id, setId] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [FilterName, setFilterName] = useState("");
  const [FilterStatus, setFilterStatus] = useState("");

  // Add/Update Form Data
  async function handleData(data) {
    setSaving(true);

    const options = {
      headers: { authorization: token },
    };

    if (Mode == "Add") {
      try {
        const Response = await axios.post(process.env.REACT_APP_API_URL + "app/category/create", qs.stringify(data), options);
        if (Response.data.status == "success") {
          setIsOpen(false);
          toast(Response.data.message);
          LoadData(1);
          setSaving(false);
        } else {
          toast(Response.data.message);
          setSaving(false);
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (Mode == "Edit") {
      try {
        const Response = await axios.post(process.env.REACT_APP_API_URL + "app/category/update", qs.stringify(data), options);
        if (Response.data.status == "success") {
          setIsOpen(false);
          toast(Response.data.message);
          LoadData(1);
          setSaving(false);
        } else {
          toast(Response.data.message);
          setSaving(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  // Calling Load Data On Run Time
  useEffect(() => {
    LoadData(1);
  }, []);

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    LoadData(pageNumber);
  }

  // Filter The Result
  function doFilter() {
    LoadData(1);
  }

  // Reset The Filter
  function doReset() {
    setFilterStatus("");
    setFilterName("");
    LoadData(1);
    setIsFilterOpen(false);
  }

  // Load All Data To Display In Table
  function LoadData(pageNumber) {
    const params = {
      page: PerPage * (pageNumber - 1),
      limit: PerPage,
      name: FilterName,
      status: FilterStatus,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    const options = {
      headers: { authorization: token },
    };

    axios.get(process.env.REACT_APP_API_URL + "app/category/list?" + queryString, {}, options).then(
      (response) => {
        setData(response.data.result);
        setTotalRecod(response.data.totalCount);
        setIsFilterOpen(false);
      },
      (error) => {
        if (error.response.status == 401) {
        }
      }
    );
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };
    const data = qs.stringify({ id: id });
    try {
      axios.post(process.env.REACT_APP_API_URL + "app/category/delete/" + id, data, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          setShowSwal(false);
          LoadData(1);
        } else {
          toast("Something went wrong");
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  }

  // Update The Status
  function UpdateStatus(id) {
    const params = {
      id: id,
    };
    const options = {
      headers: { authorization: token },
    };
    try {
      axios.post(process.env.REACT_APP_API_URL + "app/category/status?", qs.stringify(params), options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          LoadData(1);
        } else {
          toast(response.data.message);
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Category"}>
              <li className="mc-breadcrumb-item">
                <Anchor
                  icon={"add"}
                  text={"Add"}
                  href={"#"}
                  className="mc-btn primary"
                  onClick={() => {
                    setIsOpen(true);
                    setMode("Add");
                  }}
                />
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <div className="table-responsive">
              <Table borderless>
                <thead>
                  <tr>
                    <th>Category</th>

                    <th>Status</th>
                    <th>Action</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Data.length > 0 &&
                    Data.map((item, key) => (
                      <tr className="border-top" key={key}>
                        <td>{item.name} </td>

                        <td>
                          {item.active === 0 ? (
                            <a href="#" onClick={() => UpdateStatus(item._id)} className="text-warning">
                              Inactive
                            </a>
                          ) : (
                            <a href="#" onClick={() => UpdateStatus(item._id)} className="text-success">
                              Active
                            </a>
                          )}
                        </td>
                        <td>
                          <Box className="mc-table-action">
                            <Button
                              title="Edit"
                              color="primary"
                              onClick={() => {
                                setIsOpen(true);
                                setMode("Edit");
                                setEditData(item);
                              }}
                              className="edit p-3"
                            >
                              Edit
                            </Button>

                            <Button
                              color="danger"
                              onClick={() => {
                                setId(item._id);
                                setShowSwal(true);
                              }}
                              className="delete p-3"
                            >
                              Delete
                            </Button>
                          </Box>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {Data.length > 0 && TotalRecod == 0 ? <div className="text-center">No record found</div> : <div className="text-center"> </div>}
                <nav aria-label="Page navigation example">
                  {TotalRecod > PerPage ? (
                    <Pagination
                      prevPageText="prev"
                      nextPageText="next"
                      firstPageText="first"
                      lastPageText="last"
                      activePage={activePage}
                      itemsCountPerPage={PerPage}
                      totalItemsCount={TotalRecod}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                      innerClass="pagination justify-content-center"
                    />
                  ) : (
                    ""
                  )}
                </nav>
              </div>
            </div>

            <Modal show={isOpen} onHide={() => setIsOpen(false)}>
              <ModalHeader closeButton>{Mode} Category</ModalHeader>
              <ModalBody>
                <Formik
                  initialValues={{
                    name: Mode == "Add" ? "" : EditData.name,
                    id: Mode == "Add" ? "" : EditData._id,
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Category name is required"),
                  })}
                  onSubmit={(fields) => {
                    handleData(fields);
                  }}
                >
                  {({ errors, status, touched, handleSubmit }) => (
                    <Form className="user">
                      <div className="form-group mb-3">
                        <label>Category name</label>
                        <Field name="name" type="text" className={"form-control  " + (errors.name && touched.name ? " is-invalid" : "")} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                      </div>

                      <Field name="id" type="hidden" />
                      <div className="form-group mt-4">
                        {Saving ? (
                          <button type="submit" className="btn btn-primary d-block w-100 mb-3" disabled={true}>
                            <Spinner as="span" animation="grow" size="sm" />
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary d-block w-100 mb-3">
                            Save
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>

            <Modal show={isFilterOpen} onHide={() => setIsFilterOpen(false)}>
              <ModalHeader closeButton>Filters</ModalHeader>
              <ModalBody>
                <div className="user"></div>
                <div className="form-group mb-3">
                  <input type="text" className="form-control" onChange={(e) => setFilterName(e.target.value)} placeholder="Filter by name" />
                </div>
                <div className="form-group mb-3">
                  <select className="form-control" onChange={(e) => setFilterStatus(e.target.value)}>
                    <option value=""> Filter by status</option>
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>

                <div className="form-group mt-4">
                  <button type="submit" className="btn btn-primary d-block w-100 mb-3" onClick={(e) => doFilter()}>
                    Filter Result
                  </button>
                  <button type="submit" className="btn btn-info d-block w-100" onClick={(e) => doReset()}>
                    Reset Result
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </CardLayout>
        </Col>
      </Row>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This data will be permanently deleted.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
