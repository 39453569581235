import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ProductList() {
  const { userData, token, selectedCompany, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [mode, setMode] = useState("add");
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");

  const [clientsView, setClientsView] = useState(false);
  const [clientsAdd, setClientsAdd] = useState(false);
  const [clientsEdit, setClientsEdit] = useState(false);
  const [clientsDelete, setClientsDelete] = useState(false);

  const [appointmentsView, setAppointmentsView] = useState(false);
  const [appointmentsAdd, setAppointmentsAdd] = useState(false);
  const [appointmentsEdit, setAppointmentsEdit] = useState(false);
  const [appointmentsDelete, setAppointmentsDelete] = useState(false);

  const [roleView, setRoleView] = useState(false);
  const [roleAdd, setRoleAdd] = useState(false);
  const [roleEdit, setRoleEdit] = useState(false);
  const [roleDelete, setRoleDelete] = useState(false);

  const [userView, setUserView] = useState(false);
  const [userAdd, setUserAdd] = useState(false);
  const [userEdit, setUserEdit] = useState(false);
  const [userDelete, setUserDelete] = useState(false);

  const [marketingView, setMarketingView] = useState(false);
  const [marketingAdd, setMarketingAdd] = useState(false);
  const [marketingEdit, setMarketingEdit] = useState(false);
  const [marketingDelete, setMarketingDelete] = useState(false);

  async function handleRoles() {
    if (!title && title == "") {
      toast.error("Enter title");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/role/add",
        {
          title: title,
          access: {
            clients: {
              view: clientsView,
              add: clientsAdd,
              edit: clientsEdit,
              delete: clientsDelete,
            },

            appointments: {
              view: appointmentsView,
              add: appointmentsAdd,
              edit: appointmentsEdit,
              delete: appointmentsDelete,
            },

            roles: {
              view: roleView,
              add: roleAdd,
              edit: roleEdit,
              delete: roleDelete,
            },

            users: {
              view: userView,
              add: userAdd,
              edit: userEdit,
              delete: userDelete,
            },

            marketing: {
              view: marketingView,
              add: marketingAdd,
              edit: marketingEdit,
              delete: marketingDelete,
            },
          },
          company: selectedCompany && selectedCompany ? selectedCompany._id : "",
        },
        options
      );
      if (Response.data.status == "success") {
        setTitle("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    } else {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/role/update/" + id,
        {
          title: title,
          access: {
            clients: {
              view: clientsView,
              add: clientsAdd,
              edit: clientsEdit,
              delete: clientsDelete,
            },

            appointments: {
              view: appointmentsView,
              add: appointmentsAdd,
              edit: appointmentsEdit,
              delete: appointmentsDelete,
            },

            roles: {
              view: roleView,
              add: roleAdd,
              edit: roleEdit,
              delete: roleDelete,
            },

            users: {
              view: userView,
              add: userAdd,
              edit: userEdit,
              delete: userDelete,
            },
            marketing: {
              view: marketingView,
              add: marketingAdd,
              edit: marketingEdit,
              delete: marketingDelete,
            },
          },
        },
        options
      );
      if (Response.data.status == "success") {
        setTitle("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    }
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      company: selectedCompany && selectedCompany ? selectedCompany._id : "",
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/role/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/role/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Roles"}>
              {userData.role && userData.role.access.roles.add && (
                <li className="mc-breadcrumb-item">
                  <Anchor
                    icon={"add"}
                    text={"Add"}
                    href={"#"}
                    className="mc-btn primary"
                    onClick={() => {
                      setShow(true);
                      setTitle("");
                      setMode("add");
                      setClientsView(false);
                      setClientsAdd(false);
                      setClientsEdit(false);
                      setClientsDelete(false);
                      setAppointmentsView(false);
                      setAppointmentsAdd(false);
                      setAppointmentsEdit(false);
                      setAppointmentsDelete(false);

                      setRoleView(false);
                      setRoleAdd(false);
                      setRoleEdit(false);
                      setRoleDelete(false);
                      setUserView(false);
                      setUserAdd(false);
                      setUserEdit(false);
                      setUserDelete(false);

                      setMarketingView(false);
                      setMarketingAdd(false);
                      setMarketingEdit(false);
                      setMarketingDelete(false);
                    }}
                  />
                </li>
              )}

              {userData.accountType == "1" && (
                <li className="mc-breadcrumb-item">
                  <Anchor
                    icon={"add"}
                    text={"Add"}
                    href={"#"}
                    className="mc-btn primary"
                    onClick={() => {
                      setShow(true);
                      setTitle("");
                      setMode("add");
                      setClientsView(false);
                      setClientsAdd(false);
                      setClientsEdit(false);
                      setClientsDelete(false);
                      setAppointmentsView(false);
                      setAppointmentsAdd(false);
                      setAppointmentsEdit(false);
                      setAppointmentsDelete(false);

                      setRoleView(false);
                      setRoleAdd(false);
                      setRoleEdit(false);
                      setRoleDelete(false);
                      setUserView(false);
                      setUserAdd(false);
                      setUserEdit(false);
                      setUserDelete(false);

                      setMarketingView(false);
                      setMarketingAdd(false);
                      setMarketingEdit(false);
                      setMarketingDelete(false);
                    }}
                  />
                </li>
              )}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Title</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <Tr key={key}>
                            <Td>{item.title}</Td>

                            <Td>
                              <Box className="mc-table-action">
                                {userData.role && userData.role.access.roles.edit && (
                                  <Anchor
                                    href="#"
                                    title="Edit"
                                    className="material-icons edit"
                                    onClick={() => {
                                      setMode("edit");
                                      setTitle(item.title);
                                      setId(item._id);
                                      setShow(true);

                                      setClientsView(item.access.clients.view);
                                      setClientsAdd(item.access.clients.view);
                                      setClientsEdit(item.access.clients.view);
                                      setClientsDelete(item.access.clients.view);

                                      setAppointmentsView(item.access.appointments.view);
                                      setAppointmentsAdd(item.access.appointments.view);
                                      setAppointmentsEdit(item.access.appointments.view);
                                      setAppointmentsDelete(item.access.appointments.view);

                                      setRoleView(item.access.roles.view);
                                      setRoleAdd(item.access.roles.add);
                                      setRoleEdit(item.access.roles.edit);
                                      setRoleDelete(item.access.roles.delete);
                                    }}
                                  >
                                    <Icon>edit</Icon>
                                  </Anchor>
                                )}

                                {userData.role && userData.role.access.roles.delete && (
                                  <Button
                                    title="Delete"
                                    className="material-icons delete"
                                    onClick={() => {
                                      setId(item._id);
                                      setShowSwal(true);
                                    }}
                                  >
                                    <Icon>delete</Icon>
                                  </Button>
                                )}

                                {userData.accountType == "1" && (
                                  <>
                                    {" "}
                                    <Anchor
                                      href="#"
                                      title="Edit"
                                      className="material-icons edit"
                                      onClick={() => {
                                        setMode("edit");
                                        setTitle(item.title);
                                        setId(item._id);
                                        setShow(true);

                                        setClientsView(item.access.clients.view);
                                        setClientsAdd(item.access.clients.add);
                                        setClientsEdit(item.access.clients.edit);
                                        setClientsDelete(item.access.clients.delete);

                                        setAppointmentsView(item.access.appointments.view);
                                        setAppointmentsAdd(item.access.appointments.add);
                                        setAppointmentsEdit(item.access.appointments.edit);
                                        setAppointmentsDelete(item.access.appointments.delete);

                                        setRoleView(item.access.roles.view);
                                        setRoleAdd(item.access.roles.add);
                                        setRoleEdit(item.access.roles.edit);
                                        setRoleDelete(item.access.roles.delete);
                                      }}
                                    >
                                      <Icon>edit</Icon>
                                    </Anchor>
                                    <Button
                                      title="Delete"
                                      className="material-icons delete"
                                      onClick={() => {
                                        setId(item._id);
                                        setShowSwal(true);
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </Button>
                                  </>
                                )}
                              </Box>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} Role</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <LabelField label="title" type="text" fieldSize="w-100 h-md" value={title} onChange={(e) => setTitle(e.target.value)} />
            </Col>
            <Col xl={12}>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Module</th>
                    <th scope="col">View</th>
                    <th scope="col">Add</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Clients</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={clientsView}
                        onClick={() => {
                          if (clientsView) {
                            setClientsView(false);
                          } else {
                            setClientsView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={clientsAdd}
                        onClick={() => {
                          if (clientsAdd) {
                            setClientsAdd(false);
                          } else {
                            setClientsAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={clientsEdit}
                        onClick={() => {
                          if (clientsEdit) {
                            setClientsEdit(false);
                          } else {
                            setClientsEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={clientsDelete}
                        onClick={() => {
                          if (clientsDelete) {
                            setClientsDelete(false);
                          } else {
                            setClientsDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Appointments</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={appointmentsView}
                        onClick={() => {
                          if (appointmentsView) {
                            setAppointmentsView(false);
                          } else {
                            setAppointmentsView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={appointmentsAdd}
                        onClick={() => {
                          if (appointmentsAdd) {
                            setAppointmentsAdd(false);
                          } else {
                            setAppointmentsAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={appointmentsEdit}
                        onClick={() => {
                          if (appointmentsEdit) {
                            setAppointmentsEdit(false);
                          } else {
                            setAppointmentsEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={appointmentsDelete}
                        onClick={() => {
                          if (appointmentsDelete) {
                            setAppointmentsDelete(false);
                          } else {
                            setAppointmentsDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Role</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={roleView}
                        onClick={() => {
                          if (roleView) {
                            setRoleView(false);
                          } else {
                            setRoleView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={roleAdd}
                        onClick={() => {
                          if (roleAdd) {
                            setRoleAdd(false);
                          } else {
                            setRoleAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={roleEdit}
                        onClick={() => {
                          if (roleEdit) {
                            setRoleEdit(false);
                          } else {
                            setRoleEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={roleDelete}
                        onClick={() => {
                          if (roleDelete) {
                            setRoleDelete(false);
                          } else {
                            setRoleDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>User</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={userView}
                        onClick={() => {
                          if (userView) {
                            setUserView(false);
                          } else {
                            setUserView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={userAdd}
                        onClick={() => {
                          if (userAdd) {
                            setUserAdd(false);
                          } else {
                            setUserAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={userEdit}
                        onClick={() => {
                          if (userEdit) {
                            setUserEdit(false);
                          } else {
                            setUserEdit(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={userDelete}
                        onClick={() => {
                          if (userDelete) {
                            setUserDelete(false);
                          } else {
                            setUserDelete(true);
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Marketing</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={marketingView}
                        onClick={() => {
                          if (marketingView) {
                            setMarketingView(false);
                          } else {
                            setMarketingView(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={marketingAdd}
                        onClick={() => {
                          if (marketingAdd) {
                            setMarketingAdd(false);
                          } else {
                            setMarketingAdd(true);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={marketingEdit}
                        onClick={() => {
                          if (marketingEdit) {
                            setMarketingEdit(false);
                          } else {
                            setMarketingEdit(true);
                          }
                        }}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={marketingDelete}
                        onClick={() => {
                          if (marketingDelete) {
                            setMarketingDelete(false);
                          } else {
                            setMarketingDelete(true);
                          }
                        }}
                        disabled={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleRoles()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This listing will be permanently deleted from your account.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
