import React from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import Spinner from "react-bootstrap/Spinner";

function Content({ content, desc, module }) {
  const { userData, token } = useSelector((state) => state.counter);

  const [quiz, setQuiz] = useState([]);
  const [ans, setAns] = useState([]);
  const [showAns, setShowAns] = useState(false);
  const [marks, setMarks] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pass, setPass] = useState(0);
  const [uploadData, setUploadData] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (content.type == "MCQ") {
      LoadQuiz(content.id);
    }
  }, [content]);

  function LoadQuiz(id) {
    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };
    setLoading(true);
    axios.get(process.env.REACT_APP_API_URL + "app/courses/quiz?id=" + id + "&student=" + userData._id, options).then(
      (response) => {
        setLoading(false);
        setQuiz(response.data.content);
        setPass(response.data.pass);
        if (response.data.quiz.obtainedMarks) {
          setCounter(response.data.counter);
          setMarks(response.data.quiz.obtainedMarks);
          setShowAns(true);
        }
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
          setLoading(false);
        }
      }
    );
  }

  async function setAnswer(id, option) {
    var a = ans;
    var index = a.findIndex((x) => x.id === id);
    if (index > -1) {
      a.push({
        id: id,
        option: option,
      });
      setAns(a);
    } else {
      a.splice(0, index);
      a.push({
        id: id,
        option: option,
      });
      setAns(a);
    }
  }

  function showAnswer() {
    setShowAns(true);
  }

  function fileHandler(e) {
    const file = e.currentTarget.files[0];
    setUploadData(file);
  }

  async function submitQuiz() {
    if (window.confirm("Are you sure?") == true) {
      var marks = 0;
      for (let i = 0; i < quiz.length; i++) {
        var index = ans.findIndex((x) => x.id === quiz[i]._id);
        if (index > -1) {
          if (Number(ans[index].option) == Number(quiz[i].correct)) {
            marks++;
          }
        }
      }

      if (quiz?.length > 0 && ans.length < quiz?.length) {
        //return toast.error("All question is mandatory");
      }
      showAnswer();
      setMarks(marks);
      const options = {
        headers: { authorization: token },
        "Content-Type": "application/x-www-form-urlencoded",
      };

      var fields = [];
      fields["student"] = userData._id;
      fields["moduleContent"] = content.id;
      fields["totalMarks"] = quiz.length;
      fields["obtainedMarks"] = marks;
      fields["module"] = module;

      var data = await axios.post(process.env.REACT_APP_API_URL + "app/courses/submit-quiz", qs.stringify(fields), options);

      setCounter(data.data.counter);
      setPass(data.data.pass);
      setAns([]);
    }
  }

  return (
    <>
      {!content && desc != "" && (
        <p className="p-3 text-muted bg-white">
          <div className="alert alert-primary" role="alert">
            <i className="text-20 fa fa-question-circle-o mr-15"></i>Navigate course content from right side menu. Open the accordion and click on particular topic. Content will show here.
          </div>

          {desc != "" && <div className="p-3 border rounded bg-white" dangerouslySetInnerHTML={{ __html: desc }} />}
        </p>
      )}

      {content && (
        <p className="p-3 text-muted bg-white">
          <h3>{content.title}</h3> <hr />
          {content.type == "PDF" && <object data={process.env.REACT_APP_IMG_URL + "content/" + content.file} type="application/pdf" width="100%" height="500"></object>}
          {content.type == "Video" && <ReactPlayer controls playing={true} url={content.content} width="300" />}
          {content.type == "HTML" && <div className="p-3 border rounded bg-white" dangerouslySetInnerHTML={{ __html: content.content }} />}
          {content.type == "MCQ" && !showAns && (
            <>
              {loading && (
                <div>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
              {quiz?.length > 0 &&
                quiz.map((item, key) => (
                  <>
                    <h5 className="mb-3">
                      {key + 1}. {item && item.question}
                    </h5>

                    {item.option1.trim() != "" && (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={"radio" + key}
                          id={"radio" + 1 + key}
                          onClick={() => {
                            setAnswer(quiz[key]._id, 1);
                          }}
                        />
                        {"A)"} <label className="form-check-label">{item.option1}</label>
                      </div>
                    )}

                    {item.option2.trim() != "" && (
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name={"radio" + key} id={"radio" + 2 + key} onClick={() => setAnswer(quiz[key]._id, 2)} />
                        {"B)"} <label className="form-check-label">{item.option2}</label>
                      </div>
                    )}

                    {item.option3.trim() != "" && (
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name={"radio" + key} id={"radio" + 3 + key} onClick={() => setAnswer(quiz[key]._id, 3)} />
                        {"C)"} <label className="form-check-label">{item.option3}</label>
                      </div>
                    )}

                    {item.option4.trim() != "" && (
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name={"radio" + key} id={"radio" + 4 + key} onClick={() => setAnswer(quiz[key]._id, 4)} />
                        {"D)"} <label className="form-check-label">{item.option4}</label>
                      </div>
                    )}

                    <hr />
                  </>
                ))}

              {!loading && (
                <a className="btn btn-primary w-100" href="#" style={{ backgroundColor: "rgb(32, 149, 149)" }} onClick={() => submitQuiz()}>
                  Review & Submit
                </a>
              )}
            </>
          )}
          {content.type == "MCQ" && showAns && (
            <>
              {pass == 1 && (
                <h3 className="mt-5 text-center bg-success p-3 text-white rounded">
                  You scored {marks} out of {marks}
                  <div>
                    <small style={{ fontSize: 12 }}>Please go to my courses and download your certificate.</small>
                  </div>
                </h3>
              )}

              {pass == 0 && (
                <h3 className="mt-5 text-center bg-danger p-3 text-white rounded">
                  You scored {marks} out of {quiz.length}
                  <div>
                    {content.retake && Number(counter) >= 3 && (
                      <p className="text-center" style={{ fontSize: 12 }}>
                        No retake left. Kindly contact site admin
                      </p>
                    )}
                  </div>
                </h3>
              )}

              {content.retake && Number(counter) < 3 && (
                <p className="text-center">
                  <a href="#" className="text-primary" onClick={() => setShowAns(false)}>
                    <b>Click here to retake</b>
                  </a>
                </p>
              )}
            </>
          )}
        </p>
      )}
    </>
  );
}
export default Content;
