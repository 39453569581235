// HelpButton.js
import React from "react";

const HelpButton = () => {
  const handleClick = () => {
    // Navigate to the help page or open a new tab/window
    window.open("https://atsdhst.com/help.html", "_blank");
  };

  return (
    <button
      style={{
        position: "fixed",
        right: "10px",
        top: "50%",
        backgroundColor: "rgb(0, 100, 0)",
        color: "rgb(255, 255, 255)",
        padding: "20px 30px",
        borderRadius: "5px",
        cursor: "pointer",
        border: "none",
        zIndex: "1000",
        transformOrigin: "bottom right",
        transform: "rotate(90deg) translateY(50%)",
      }}
      onClick={handleClick}
    >
      Help
    </button>
  );
};

export default HelpButton;
