import React from "react";
import { Box, Form, Heading, Button, Anchor, Image } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setUserData, setLoginStatus, setToken, setRefreshToken, setLoginAccountType, setAdmin, setAdminData, setCompanyList, setSelectedCompany, setRole } from "../../actions";

const mapStateToProps = (state) => ({
  counter: state.counter.counter,
});

const mapDispatchToProps = {
  setUserData,
  setLoginStatus,
  setToken,
  setRefreshToken,
  setAdmin,
  setAdminData,
  setCompanyList,
  setSelectedCompany,
  setRole,
  setLoginAccountType,
};

const Login = ({ setUserData, setLoginStatus, setToken, setRefreshToken, setLoginAccountType, setAdminData, setCompanyList, setSelectedCompany, setRole }) => {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Saving, setSaving] = useState(false);

  async function handleLogin() {
    if (!Email && Email == "") {
      toast.error("Enter email address");
      return;
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!Email.match(mailformat)) {
      toast.error("Enter valid email address");
      return;
    }

    if (!Password && Password == "") {
      toast.error("Enter password");
      return;
    }
    setSaving(true);
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/login", {
      email: Email,
      password: Password,
    });
    if (Response.data.success == true) {
      if (Response.data.data.isAdmin) {
        setAdmin(true);
        setAdminData(Response.data.data);
      } else {
        setUserData(Response.data.data);
        setCompanyList(Response.data.company);
        setSelectedCompany(Response.data.company[0]);
        // if (Response.data.data.role) {
        //   setRole(Response.data.data.role.access);
        // }
      }

      setLoginAccountType(Response.data.data.accountType);
      setLoginStatus(true);
      setToken(Response.data.token);
      setRefreshToken(Response.data.refreshtoken);
      setSaving(false);
      toast.success(Response.data.message);
      //return;
      navigate("/crm");
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <Box className="mc-auth">
      <Image src={data?.pattern.src} alt={data?.pattern.alt} className="mc-auth-pattern" />
      <Box className="mc-auth-group">
        <Logo src={data?.logo.src} alt={data?.logo.alt} href={data?.logo.path} className="mc-auth-logo" />

        <Form className="mc-auth-form">
          <IconField icon={"email"} type={"email"} classes={"w-100 h-sm"} placeholder={"Enter your email"} onChange={(e) => setEmail(e.target.value)} />
          <IconField icon={"lock"} type={"password"} classes={"w-100 h-sm"} placeholder={"Enter your password"} passwordVisible={true} onChange={(e) => setPassword(e.target.value)} />

          <Anchor className="mc-auth-forgot mb-2" href={data?.forgot.path}>
            {data?.forgot.text}
          </Anchor>

          <Button className={`mc-auth-btn ${data?.button.fieldSize}`} type={data?.button.type} disable={!Saving} onClick={() => handleLogin()}>
            {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>{data?.button.text}</>}
          </Button>

          <Anchor className="btn btn-dark w-100" href={"/register"}>
            Create account
          </Anchor>
        </Form>
      </Box>
    </Box>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
