import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import { Button } from "./../../components/elements";
import Spinner from "react-bootstrap/Spinner";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable, editable, etc.
import { utcToZonedTime } from "date-fns-tz";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

export default function CRM() {
  const navigate = useNavigate();
  const [targetTimeZone, setTargetTimeZone] = useState("");
  const { token, userData, adminData, selectedCompany } = useSelector((state) => state.counter);
  const [data, setData] = useState([]);
  const [myEventsList, setMyEventsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [payment, setPayment] = useState("");
  const [room, setRoom] = useState(0);
  const [roomData, setRoomData] = useState([]);
  const [mode, setMode] = useState("add");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [timeslots, setTimeSlots] = useState([]);
  const [timeslots2, setTimeSlots2] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [value, setValue] = useState("");

  const [patient, setPatient] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleChange2 = (event) => {
    setValue(event.target.value);
    if (event.target.value && event.target.value.length > 2) {
      setShowSuggestions(true);
    }
  };

  const handleSelect = (value) => {
    setValue(value.name);
    setPatient(value._id);
    setShowSuggestions(false);
    // You can perform any action here when an option is selected
  };

  const filteredOptions = allPatients.filter((option) => option.name.toLowerCase().includes(value.toLowerCase()));

  useEffect(() => {
    // Try to get the time zone using Intl.DateTimeFormat()
    let userTimeZone;
    try {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
      console.error("Error getting time zone using Intl.DateTimeFormat():", error.message);
      // Fallback to moment-timezone if there is an error
      userTimeZone = moment.tz.guess();
    }

    setTargetTimeZone(userTimeZone);
  }, []);

  useEffect(() => {
    fetchData();
    fetchPatient();
    fetchRoom();
  }, []);

  async function fetchData() {
    setLoading(true);
    const options = {
      headers: { authorization: token },
    };

    var company = selectedCompany ? selectedCompany._id : "";
    if (window.location.hostname == "account.atsdhst.com") {
      var id = userData._id;
    } else {
      var id = adminData._id;
    }

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/dasborad/admin?practitioner=" + id + "&company=" + company, options);
    if (Response && Response.data.status == "success") {
      if (Response.data.result) {
        var events = [];
        setData(Response.data.result);
        Response.data.result.bookings?.length > 0 &&
          Response.data.result.bookings.map((item2, key) => {
            if (item2 && item2.patientInfo && item2.patientInfo[0]) {
              events.push({
                id: item2.patientInfo[0]._id,
                title: item2.patientInfo[0].name + " #" + item2.uniqueId,
                start: utcToZonedTime(convertTimestampToAMPM2(item2.startTime * 1000), targetTimeZone),
                end: utcToZonedTime(convertTimestampToAMPM2(item2.endTime * 1000), targetTimeZone),
                appId: item2._id,
                patientId: item2.patientInfo[0]._id,
                date: item2.appointmentDate,
              });
            }
          });

        setMyEventsList(events);
        setLoading(false);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  function convertTimestampToAMPM2(timestamp) {
    const currentDate = new Date(timestamp);
    currentDate.setMinutes(currentDate.getMinutes() - 1);
    return currentDate;
  }

  const handleEventClick = (info) => {
    // if (moment(moment(selectedEvent.event.start).utc()).format("x") >= moment(moment().utc()).format("x")) {

    // }

    setSelectedEvent(info);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  // Function to convert 12-hour time to 24-hour time
  function convertTo24Hour(time12h) {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }

  // Function to check if a specific time is in the array
  function isTimeSlotAvailable(time) {
    const time24h = convertTo24Hour(time);
    for (let i = 0; i < timeslots.length; i++) {
      if (timeslots[i].time == time24h) {
        return timeslots[i].isAvailable;
      }
    }
    return false; // Return false if time slot not found
  }

  const handleDateClick = (arg) => {
    setAppointmentDate(new Date(moment(arg.date).local().format("MM/DD/YYYY")));
    setStartTime(moment(moment.utc(arg.date).local()).format("h:mm A"));
    const isAvailable = isTimeSlotAvailable(moment(moment.utc(arg.date).local()).format("h:mm A"));

    if (isAvailable) {
      getEndDate(moment(moment.utc(arg.date).local()).format("h:mm A"));
    }
    setShow(true);
  };

  const selectedDay = async () => {
    if (appointmentDate == "") {
      toast.error("Select date");
      return;
    }

    const isoDate = new Date(appointmentDate);
    const year = isoDate.getFullYear();
    const month = String(isoDate.getMonth() + 1).padStart(2, "0");
    const day = String(isoDate.getDate()).padStart(2, "0");
    const ymdFormat = `${year}-${month}-${day}`;

    setLoading1(true);
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/home/check-availability", {
      date: ymdFormat,
      practitioner: userData._id,
      time: 15,
    });

    if (Response.data.status === "success") {
      setTimeSlots(Response.data.data);
      setLoading1(false);
    } else {
      setLoading1(false);
    }
  };

  const getEndDate = async (startTime) => {
    const isoDate = new Date(appointmentDate);
    const year = isoDate.getFullYear();
    const month = String(isoDate.getMonth() + 1).padStart(2, "0");
    const day = String(isoDate.getDate()).padStart(2, "0");
    const ymdFormat = `${year}-${month}-${day}`;

    setLoading2(true);

    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/home/check-availability-end", {
      date: ymdFormat,
      practitioner: userData._id,
      time: 15,
      startTime: startTime,
    });

    if (Response.data.status === "success") {
      setTimeSlots2(Response.data.data);
      setLoading2(false);
    } else {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (appointmentDate != "") {
      selectedDay();
    }
  }, [appointmentDate]);

  async function fetchPatient() {
    if (window.location.hostname == "account.atsdhst.com") {
      var id = userData._id;
    } else {
      var id = adminData._id;
    }

    const options = {
      headers: { authorization: token },
    };

    const params = {
      id: id,
      company: selectedCompany ? selectedCompany._id : "",
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/patient/all?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setAllPatients(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  function addOneMinuteToTime(timeString) {
    // Parse the time string to create a Date object
    var date = new Date("2000-01-01 " + timeString);

    // Add one minute to the date
    date.setMinutes(date.getMinutes() + 1);

    // Format the result as "hh:mm AM/PM"
    var formattedTime = date.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

    return formattedTime;
  }

  function formatDate(inputDate) {
    // Create a new Date object
    var date = new Date(inputDate);

    // Get the individual components of the date
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    var day = ("0" + date.getDate()).slice(-2);

    // Create the formatted date string
    var formattedDate = year + "-" + month + "-" + day;

    return formattedDate;
  }

  async function handleBooking() {
    if (Saving) {
      return;
    }

    if (room == 0 && roomData.length > 0) {
      toast.error("Select room");
      return;
    }

    if (!patient && patient == "") {
      toast.error("Enter patient");
      return;
    }

    if (!appointmentDate && appointmentDate == "") {
      toast.error("Enter appointment date");
      return;
    }

    if (!startTime && startTime == "") {
      toast.error("Enter appointment start time");
      return;
    }

    if (!endTime && endTime == "") {
      toast.error("Enter appointment start time");
      return;
    }

    if (!payment && payment == "") {
      toast.error("Enter appointment payment");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/appointment/book",
        {
          appointmentDate: formatDate(appointmentDate),
          startTime: addOneMinuteToTime(startTime),
          endTime: addOneMinuteToTime(endTime),
          payment: payment,
          practitioner: userData._id,
          patient: patient,
          room: room,
          company: selectedCompany ? selectedCompany._id : "",
        },
        options
      );
      if (Response.data.status == "success") {
        fetchData();
        setAppointmentDate("");
        setStartTime("");
        setEndTime("");
        setPayment("");
        setRoom("");
        setValue("");
        setPatient("");
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);

        const timer = setTimeout(() => {
          window.location.reload();
        }, 3000);

        return () => clearTimeout(timer);
        //fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        //setShow(false);
      }
    }
  }
  async function fetchRoom() {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      id: userData._id,
      company: selectedCompany ? selectedCompany._id : "",
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/room/all?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setRoomData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }
  return (
    <PageLayout>
      <Row>
        {userData.subscription && Number(userData.subscription.only_ats) == 1 && (
          <>
            <Col xl={12}>
              <Breadcrumb title={"Dashboard"}></Breadcrumb>
            </Col>

            {window.location.hostname == "account.atsdhst.com" ? (
              <>
                <div className="row">
                  <div className="col-md">
                    <div className="mc-analytics-card bg-primary">
                      <div className="mc-analytics-card-group">
                        <div className="mc-analytics-card-content">
                          <h3>{data.patients}</h3>
                          <p>
                            <a href="/patients" className="text-white">
                              Total Clients
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="recharts-responsive-container" width="190.75" height="40">
                        <div className="recharts-wrapper" role="region">
                          <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                            <div className="recharts-default-tooltip">
                              <p className="recharts-tooltip-label"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {selectedCompany && !selectedCompany.users.includes(userData._id) && (
                    <div className="col-md">
                      <div className="mc-analytics-card bg-primary">
                        <div className="mc-analytics-card-group">
                          <div className="mc-analytics-card-content">
                            <h3>{data.planData && data.planData.subscription && data.planData.subscription.title} </h3>
                            <bold className="text-white">Plan status: </bold>{" "}
                            {data.planData && data.planData.planActive == 0 && (
                              <>
                                {data.planData.planStartTimestamp == 0 && data.planData.planActive == 0 && moment().format("X") > data.planData.trialExpTimestamp ? (
                                  <span className="p-1 rounded bg-danger text-white"> Expired</span>
                                ) : (
                                  <span className="p-1 rounded bg-success text-white">In Trial</span>
                                )}
                              </>
                            )}
                            {data.planData && data.planData.planActive == 1 && (
                              <>{data.planData && data.planData.planActive == 1 && moment().format("X") > data.planData.planExpTimestamp ? <span className="p-1  rounded bg-danger text-white">Expired</span> : <span className="p-1 rounded bg-success text-white">Active</span>}</>
                            )}
                          </div>
                        </div>
                        <div className="recharts-responsive-container" width="190.75" height="40">
                          <div className="recharts-wrapper" role="region">
                            <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                              <div className="recharts-default-tooltip">
                                <p className="recharts-tooltip-label"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedCompany && selectedCompany.practitioner == userData._id && (
                    <div className="col-md">
                      <div className="mc-analytics-card bg-primary">
                        <div className="mc-analytics-card-group">
                          <div className="mc-analytics-card-content">
                            <h3>{data.planData && data.planData.subscription && data.planData.subscription.title} </h3>
                            <bold className="text-white">Plan status: </bold>{" "}
                            {data.planData && data.planData.planActive == 0 && (
                              <>
                                {data.planData.planStartTimestamp == 0 && data.planData.planActive == 0 && moment().format("X") > data.planData.trialExpTimestamp ? (
                                  <span className="p-1 rounded bg-danger text-white"> Expired</span>
                                ) : (
                                  <span className="p-1 rounded bg-success text-white">In Trial</span>
                                )}
                              </>
                            )}
                            {data.planData && data.planData.planActive == 1 && (
                              <>{data.planData && data.planData.planActive == 1 && moment().format("X") > data.planData.planExpTimestamp ? <span className="p-1  rounded bg-danger text-white">Expired</span> : <span className="p-1 rounded bg-success text-white">Active</span>}</>
                            )}
                          </div>
                        </div>
                        <div className="recharts-responsive-container" width="190.75" height="40">
                          <div className="recharts-wrapper" role="region">
                            <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                              <div className="recharts-default-tooltip">
                                <p className="recharts-tooltip-label"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!selectedCompany && (
                    <div className="col-md">
                      <div className="mc-analytics-card bg-primary">
                        <div className="mc-analytics-card-group">
                          <div className="mc-analytics-card-content">
                            <h3>{data.planData && data.planData.subscription && data.planData.subscription.title} </h3>
                            <bold className="text-white">Plan status: </bold>{" "}
                            {data.planData && data.planData.planActive == 0 && (
                              <>
                                {data.planData.planStartTimestamp == 0 && data.planData.planActive == 0 && moment().format("X") > data.planData.trialExpTimestamp ? (
                                  <span className="p-1 rounded bg-danger text-white"> Expired</span>
                                ) : (
                                  <span className="p-1 rounded bg-success text-white">In Trial</span>
                                )}
                              </>
                            )}
                            {data.planData && data.planData.planActive == 1 && (
                              <>{data.planData && data.planData.planActive == 1 && moment().format("X") > data.planData.planExpTimestamp ? <span className="p-1  rounded bg-danger text-white">Expired</span> : <span className="p-1 rounded bg-success text-white">Active</span>}</>
                            )}
                          </div>
                        </div>
                        <div className="recharts-responsive-container" width="190.75" height="40">
                          <div className="recharts-wrapper" role="region">
                            <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                              <div className="recharts-default-tooltip">
                                <p className="recharts-tooltip-label"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-xl-3">
                  <div className="mc-analytics-card bg-primary">
                    <div className="mc-analytics-card-group">
                      <div className="mc-analytics-card-content">
                        <h3>{data.practitionerCount}</h3>
                        <p>
                          <a href="/practitioners" className="text-white">
                            Total Practitioners
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="recharts-responsive-container" width="190.75" height="40">
                      <div className="recharts-wrapper" role="region">
                        <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                          <div className="recharts-default-tooltip">
                            <p className="recharts-tooltip-label"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="mc-analytics-card bg-primary">
                    <div className="mc-analytics-card-group">
                      <div className="mc-analytics-card-content">
                        <h3>{data.totalExpired}</h3>

                        <a href="/practitioners" className="text-white">
                          Expired
                        </a>
                      </div>
                    </div>
                    <div className="recharts-responsive-container" width="190.75" height="40">
                      <div className="recharts-wrapper" role="region">
                        <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                          <div className="recharts-default-tooltip">
                            <p className="recharts-tooltip-label"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="mc-analytics-card bg-primary">
                    <div className="mc-analytics-card-group">
                      <div className="mc-analytics-card-content">
                        <h3>{data.totalInTrial}</h3>

                        <a href="/practitioners" className="text-white">
                          In Trial
                        </a>
                      </div>
                    </div>
                    <div className="recharts-responsive-container" width="190.75" height="40">
                      <div className="recharts-wrapper" role="region">
                        <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                          <div className="recharts-default-tooltip">
                            <p className="recharts-tooltip-label"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="mc-analytics-card bg-primary">
                    <div className="mc-analytics-card-group">
                      <div className="mc-analytics-card-content">
                        <h3>{data.totalActive}</h3>
                        <a href="/practitioners" className="text-white">
                          Active
                        </a>
                      </div>
                    </div>
                    <div className="recharts-responsive-container" width="190.75" height="40">
                      <div className="recharts-wrapper" role="region">
                        <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                          <div className="recharts-default-tooltip">
                            <p className="recharts-tooltip-label"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {userData.subscription && Number(userData.subscription.only_ats) == 0 && (
          <>
            <Col xl={12}>
              <Breadcrumb title={"Dashboard"}>
                <Button icon={"today"} text={"Add appointment"} href={"#"} className="mc-btn primary w-100" onClick={() => setShow(true)} />
              </Breadcrumb>
            </Col>

            {window.location.hostname == "account.atsdhst.com" ? (
              <>
                <div className="row">
                  <div className="col-md">
                    <div className="mc-analytics-card bg-primary">
                      <div className="mc-analytics-card-group">
                        <div className="mc-analytics-card-content">
                          <h3>{data.patients}</h3>
                          <p>
                            <a href="/patients" className="text-white">
                              Total Clients
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="recharts-responsive-container" width="190.75" height="40">
                        <div className="recharts-wrapper" role="region">
                          <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                            <div className="recharts-default-tooltip">
                              <p className="recharts-tooltip-label"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="mc-analytics-card bg-primary">
                      <div className="mc-analytics-card-group">
                        <div className="mc-analytics-card-content">
                          <h3>{data.todayBookings}</h3>

                          <a href="/appointments?mode=today" className="text-white">
                            Today Bookings
                          </a>
                        </div>
                      </div>
                      <div className="recharts-responsive-container" width="190.75" height="40">
                        <div className="recharts-wrapper" role="region">
                          <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                            <div className="recharts-default-tooltip">
                              <p className="recharts-tooltip-label"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="mc-analytics-card bg-primary">
                      <div className="mc-analytics-card-group">
                        <div className="mc-analytics-card-content">
                          <h3>{data.currentWeekBookings}</h3>

                          <a href="/appointments?mode=week" className="text-white">
                            7 Day Bookings
                          </a>
                        </div>
                      </div>
                      <div className="recharts-responsive-container" width="190.75" height="40">
                        <div className="recharts-wrapper" role="region">
                          <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                            <div className="recharts-default-tooltip">
                              <p className="recharts-tooltip-label"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedCompany && !selectedCompany.users.includes(userData._id) && (
                    <div className="col-md">
                      <div className="mc-analytics-card bg-primary">
                        <div className="mc-analytics-card-group">
                          <div className="mc-analytics-card-content">
                            <h3>{data.planData && data.planData.subscription && data.planData.subscription.title} </h3>
                            <bold className="text-white">Plan status: </bold>{" "}
                            {data.planData && data.planData.planActive == 0 && (
                              <>
                                {data.planData.planStartTimestamp == 0 && data.planData.planActive == 0 && moment().format("X") > data.planData.trialExpTimestamp ? (
                                  <span className="p-1 rounded bg-danger text-white"> Expired</span>
                                ) : (
                                  <span className="p-1 rounded bg-success text-white">In Trial</span>
                                )}
                              </>
                            )}
                            {data.planData && data.planData.planActive == 1 && (
                              <>{data.planData && data.planData.planActive == 1 && moment().format("X") > data.planData.planExpTimestamp ? <span className="p-1  rounded bg-danger text-white">Expired</span> : <span className="p-1 rounded bg-success text-white">Active</span>}</>
                            )}
                          </div>
                        </div>
                        <div className="recharts-responsive-container" width="190.75" height="40">
                          <div className="recharts-wrapper" role="region">
                            <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                              <div className="recharts-default-tooltip">
                                <p className="recharts-tooltip-label"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedCompany && selectedCompany.practitioner == userData._id && (
                    <div className="col-md">
                      <div className="mc-analytics-card bg-primary">
                        <div className="mc-analytics-card-group">
                          <div className="mc-analytics-card-content">
                            <h3>{data.planData && data.planData.subscription && data.planData.subscription.title} </h3>
                            <bold className="text-white">Plan status: </bold>{" "}
                            {data.planData && data.planData.planActive == 0 && (
                              <>
                                {data.planData.planStartTimestamp == 0 && data.planData.planActive == 0 && moment().format("X") > data.planData.trialExpTimestamp ? (
                                  <span className="p-1 rounded bg-danger text-white"> Expired</span>
                                ) : (
                                  <span className="p-1 rounded bg-success text-white">In Trial</span>
                                )}
                              </>
                            )}
                            {data.planData && data.planData.planActive == 1 && (
                              <>{data.planData && data.planData.planActive == 1 && moment().format("X") > data.planData.planExpTimestamp ? <span className="p-1  rounded bg-danger text-white">Expired</span> : <span className="p-1 rounded bg-success text-white">Active</span>}</>
                            )}
                          </div>
                        </div>
                        <div className="recharts-responsive-container" width="190.75" height="40">
                          <div className="recharts-wrapper" role="region">
                            <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                              <div className="recharts-default-tooltip">
                                <p className="recharts-tooltip-label"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!selectedCompany && (
                    <div className="col-md">
                      <div className="mc-analytics-card bg-primary">
                        <div className="mc-analytics-card-group">
                          <div className="mc-analytics-card-content">
                            <h3>{data.planData && data.planData.subscription && data.planData.subscription.title} </h3>
                            <bold className="text-white">Plan status: </bold>{" "}
                            {data.planData && data.planData.planActive == 0 && (
                              <>
                                {data.planData.planStartTimestamp == 0 && data.planData.planActive == 0 && moment().format("X") > data.planData.trialExpTimestamp ? (
                                  <span className="p-1 rounded bg-danger text-white"> Expired</span>
                                ) : (
                                  <span className="p-1 rounded bg-success text-white">In Trial</span>
                                )}
                              </>
                            )}
                            {data.planData && data.planData.planActive == 1 && (
                              <>{data.planData && data.planData.planActive == 1 && moment().format("X") > data.planData.planExpTimestamp ? <span className="p-1  rounded bg-danger text-white">Expired</span> : <span className="p-1 rounded bg-success text-white">Active</span>}</>
                            )}
                          </div>
                        </div>
                        <div className="recharts-responsive-container" width="190.75" height="40">
                          <div className="recharts-wrapper" role="region">
                            <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                              <div className="recharts-default-tooltip">
                                <p className="recharts-tooltip-label"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <h4 className="mt-5 mb-2 text-center">Upcoming Appointment</h4>

                {loading && (
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )}

                <div className="bg-white p-3 m-2 rounded">
                  <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                    initialView="dayGridMonth" // Set the initial view to month
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                    }}
                    events={myEventsList}
                    selectable
                    editable
                    eventClick={handleEventClick}
                    dateClick={handleDateClick}
                  />
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-xl-3">
                  <div className="mc-analytics-card bg-primary">
                    <div className="mc-analytics-card-group">
                      <div className="mc-analytics-card-content">
                        <h3>{data.practitionerCount}</h3>
                        <p>
                          <a href="/practitioners" className="text-white">
                            Total Practitioners
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="recharts-responsive-container" width="190.75" height="40">
                      <div className="recharts-wrapper" role="region">
                        <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                          <div className="recharts-default-tooltip">
                            <p className="recharts-tooltip-label"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="mc-analytics-card bg-primary">
                    <div className="mc-analytics-card-group">
                      <div className="mc-analytics-card-content">
                        <h3>{data.totalExpired}</h3>

                        <a href="/practitioners" className="text-white">
                          Expired
                        </a>
                      </div>
                    </div>
                    <div className="recharts-responsive-container" width="190.75" height="40">
                      <div className="recharts-wrapper" role="region">
                        <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                          <div className="recharts-default-tooltip">
                            <p className="recharts-tooltip-label"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="mc-analytics-card bg-primary">
                    <div className="mc-analytics-card-group">
                      <div className="mc-analytics-card-content">
                        <h3>{data.totalInTrial}</h3>

                        <a href="/practitioners" className="text-white">
                          In Trial
                        </a>
                      </div>
                    </div>
                    <div className="recharts-responsive-container" width="190.75" height="40">
                      <div className="recharts-wrapper" role="region">
                        <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                          <div className="recharts-default-tooltip">
                            <p className="recharts-tooltip-label"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="mc-analytics-card bg-primary">
                    <div className="mc-analytics-card-group">
                      <div className="mc-analytics-card-content">
                        <h3>{data.totalActive}</h3>
                        <a href="/practitioners" className="text-white">
                          Active
                        </a>
                      </div>
                    </div>
                    <div className="recharts-responsive-container" width="190.75" height="40">
                      <div className="recharts-wrapper" role="region">
                        <div tabindex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
                          <div className="recharts-default-tooltip">
                            <p className="recharts-tooltip-label"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedEvent && selectedEvent.event.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedEvent && console.log(selectedEvent.event)}
            <div>
              <strong>Date: </strong>
              {selectedEvent && moment(moment(selectedEvent.event.start).utc()).format("MM/DD/YYYY")}
            </div>
            <div>
              <strong>Start Time: </strong>
              {selectedEvent && moment(moment.utc(selectedEvent.event.start).local()).format("hh:mm A")}
            </div>
            <div>
              <strong>End Time: </strong>
              {selectedEvent && moment(moment.utc(selectedEvent.event.end).local()).format("hh:mm A")}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {selectedEvent && (
              <Button
                type="button"
                variant="secondary"
                className="mc-btn primary w-100 p-4 m-1"
                onClick={() => {
                  handleCloseModal();
                  navigate("/patient-details?id=" + selectedEvent.event.id);
                }}
              >
                Client details
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} appointment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title">Client</label>
                <div className="position-relative  w-100">
                  <input type="text" className="form-control w-100" value={value} onChange={handleChange2} placeholder="Start typing to find the client" />
                  {showSuggestions && (
                    <ul className="list-group position-absolute w-100 mt-1" style={{ zIndex: 10 }}>
                      {filteredOptions.slice(0, 10).map((option, i) => (
                        <li key={option._id} className="list-group-item" onClick={() => handleSelect(option)}>
                          {option.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </Col>
            <Col xl={12}>
              <div className="form-group">
                <label class="mc-label-field-title"> Select a room</label>
                <select className="form-control" value={room} onChange={(e) => setRoom(e.target.value)}>
                  <option value={"0"}> Select</option>
                  {roomData?.length > 0 && roomData.map((room, key) => <option value={room._id}> {room.name} </option>)}
                </select>
              </div>
            </Col>
            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title">appointment date</label>
                <DatePicker minDate={new Date()} placeholderText="Start date" className="mc-label-field-input w-100 h-md" selected={appointmentDate} onChange={(date) => setAppointmentDate(date)} dateFormat="MM/dd/yyyy" />
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title"> Appointment start time </label>
                {loading1 ? (
                  <div>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                ) : (
                  <select
                    className="form-control"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                      getEndDate(e.target.value);
                    }}
                  >
                    <option value={"0"}> Select</option>

                    {timeslots.map(
                      (item, index) =>
                        timeslots[index + 1] &&
                        item.isAvailable && (
                          <option key={index} value={moment(item.time, "HH:mm").format("h:mm A")}>
                            {moment(item.time, "HH:mm").format("h:mm A")}
                          </option>
                        )
                    )}
                  </select>
                )}
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title"> Appointment end time </label>
                {loading2 ? (
                  <div>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                ) : (
                  <select
                    className="form-control"
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                  >
                    <option value={"0"}> Select</option>

                    {timeslots2.map(
                      (item, index) =>
                        timeslots[index + 1] &&
                        item.isAvailable && (
                          <option key={index} value={moment(item.time, "HH:mm").format("h:mm A")}>
                            {moment(item.time, "HH:mm").format("h:mm A")}
                          </option>
                        )
                    )}
                  </select>
                )}
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title">Payment</label>
                <select class="mc-label-field-input w-100 h-md" value={payment} onChange={(e) => setPayment(e.target.value)}>
                  <option value={""}> Select </option>
                  <option value={"wallet"}> Use wallet balance </option>
                  <option value={"prepaid_cash"}> Prepaid by cash </option>
                  <option value={"prepaid_card"}> Prepaid by card </option>
                  <option value={"pending"}> Not paid </option>
                </select>
              </div>
            </Col>

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleBooking()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Check & Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </PageLayout>
  );
}
