import React from "react";
import { Row, Col } from "react-bootstrap";
import { LabelField } from "../../components/fields";
import { useState, useEffect } from "react";
import { Button } from "../../components/elements";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function Alerts() {
  const location = useLocation();

  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [timezone, setTimezone] = useState("");
  const [slug, setSlug] = useState("");
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDescrption, setSiteDescrption] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [Saving, setSaving] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [about, setAbout] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [terms, setTerms] = useState("");
  const [avalabel, setAvalabel] = useState(true);
  const [logo, setLogo] = useState("");

  async function updateSettings() {
    if (!timezone && timezone == "") {
      toast.error("Timezone is not required");
      return;
    }
    if (!avalabel) {
      toast.error("Slug is not avalabel");
      return;
    }

    if (!slug && slug == "") {
      toast.error("Enter site slug");
      return;
    }

    if (!siteTitle && siteTitle == "") {
      toast.error("Enter site title");
      return;
    }

    if (!siteDescrption && siteDescrption == "") {
      toast.error("Enter site description");
      return;
    }

    if (!phone && phone == "") {
      toast.error("Enter phone number");
      return;
    }

    if (phone != "" && phone.length > 10) {
      toast.error("Phone must be 10 digit only");
      return;
    }

    if (!email && email == "") {
      toast.error("Enter email address");
      return;
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      toast.error("Enter valid email address");
      return;
    }

    if (!address && address == "") {
      toast.error("Enter address");
      return;
    }
    setSaving(true);
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/practitioner-settings/update",
      { timezone: timezone, slug: slug, siteTitle: siteTitle, siteDescrption: siteDescrption, phone: phone, email: email, address: address, facebook: facebook, instagram: instagram, twitter: twitter, youtube: youtube, about: about, privacy: privacy, terms: terms, id: userData._id },
      options
    );
    if (Response.data.status == "success") {
      setSaving(false);
      toast.success(Response.data.message);
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (slug && slug.length >= 1) {
      checkAvability(slug);
    }
  }, [slug]);

  async function checkAvability(newslug = "") {
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/slug-availability?user=" + userData._id + "&slug=" + newslug, options);
    if (Response.data.status) {
      setAvalabel(false);
    } else {
      setAvalabel(true);
    }
  }

  async function fetchSettings() {
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/practitioner-settings?user=" + userData._id, options);
    if (Response && Response.data.status == "success") {
      if (Response.data.result) {
        setTimezone(Response.data.timezone);
        setSlug(Response.data.slug);
        setSiteTitle(Response.data.result.siteTitle);
        setSiteDescrption(Response.data.result.siteDesc);
        setPhone(Response.data.result.displayPhone);
        setEmail(Response.data.result.displayEmail);
        setAddress(Response.data.result.displayAddress);
        setFacebook(Response.data.result.facebook);
        setInstagram(Response.data.result.instagram);
        setTwitter(Response.data.result.twitter);
        setYoutube(Response.data.result.youtube);
        setAbout(Response.data.result.about);
        setPrivacy(Response.data.result.privacy);
        setTerms(Response.data.result.terms);
        setLogo(Response.data.logo);
        if (location.pathname == "/onboard") {
          setPhone(Response.data.phone);
          setEmail(Response.data.email);
        }
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  var createSlug = function (str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  };

  async function logoHandler(e) {
    const options = {
      "Content-Type": "application/x-www-form-urlencoded",
      headers: { authorization: token },
    };

    const logo = e.currentTarget.files[0];
    const logoData = new FormData();
    logoData.append("upload", logo);
    logoData.append("id", userData._id);
    var imageData = await axios.post(process.env.REACT_APP_API_URL + "app/practitioner-settings/uploadLogo", logoData, options);
    setLogo(imageData.data.filename);
  }

  return (
    <>
      <Row className="mt-3">
        <Col xl={12} className="ps-xl-5">
          <Row>
            <div className="col-md-8">
              <div class="form-group">
                <label class="mc-label-field-title">Logo</label>
                <input type="file" class="form-control" accept="image/*" onChange={(e) => logoHandler(e)} />
              </div>
            </div>
            <div className="col-md-4">{logo != "" && <img src={process.env.REACT_APP_API_URL + "logo/optimized/" + logo} class="img-fluid img-thumbnail" />}</div>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title"> Time zone</label>
                <select value={timezone} onChange={(e) => setTimezone(e.target.value)} class="form-control">
                  <option value=""> Select </option>
                  <option value="Pacific/Midway">Pacific/Midway (UTC-11:00)</option>
                  <option value="America/Adak">America/Adak (UTC-10:00)</option>
                  <option value="Pacific/Honolulu">Pacific/Honolulu (UTC-10:00)</option>
                  <option value="America/Anchorage">America/Anchorage (UTC-09:00)</option>
                  <option value="America/Los_Angeles">America/Los_Angeles (UTC-08:00)</option>
                  <option value="America/Denver">America/Denver (UTC-07:00)</option>
                  <option value="America/Chicago">America/Chicago (UTC-06:00)</option>
                  <option value="America/New_York">America/New_York (UTC-05:00)</option>
                  <option value="America/Caracas">America/Caracas (UTC-04:00)</option>
                  <option value="America/Buenos_Aires">America/Buenos_Aires (UTC-03:00)</option>
                  <option value="Atlantic/Azores">Atlantic/Azores (UTC-01:00)</option>
                  <option value="Europe/London">Europe/London (UTC+00:00)</option>
                  <option value="Europe/Paris">Europe/Paris (UTC+01:00)</option>
                  <option value="Europe/Athens">Europe/Athens (UTC+02:00)</option>
                  <option value="Asia/Dubai">Asia/Dubai (UTC+04:00)</option>
                  <option value="Asia/Kolkata">Asia/Kolkata (UTC+05:30)</option>
                  <option value="Asia/Hong_Kong">Asia/Hong_Kong (UTC+08:00)</option>
                  <option value="Australia/Sydney">Australia/Sydney (UTC+11:00)</option>
                </select>
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title"> Page slug</label>
                <div class="input-group">
                  <input placeholder="Enter only text" value={slug} onChange={(e) => setSlug(createSlug(e.target.value))} class="form-control" />
                  <span class="input-group-text" id="basic-addon2">
                    .atsdhst.com
                  </span>
                </div>
                {slug && slug.length > 3 && <span>{avalabel ? <span className="text-success">Congratulations! The slug is available for registration</span> : <span className="text-danger">Oops! The slug is not avalabel for registration</span>}</span>}
              </div>
            </Col>

            <Col xl={12}>
              <LabelField label="site title" type="text" fieldSize="w-100 h-md" value={siteTitle} onChange={(e) => setSiteTitle(e.target.value)} />
            </Col>

            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Description</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={siteDescrption} onChange={(e) => setSiteDescrption(e.target.value)}></textarea>
              </div>
            </Col>

            <div class="col-xl-6">
              <div style={{ textAlign: "left" }}>
                <div class="mc-label-field-title mb-2"> phone number</div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text p-2" style={{ fontSize: 18 }}>
                      +1
                    </span>
                  </div>
                  <input type="number" class="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
              </div>
            </div>

            <Col xl={6}>
              <LabelField label="email address" type="email" fieldSize="w-100 h-md" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Col>
            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> address</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={6}>
              <LabelField label="facebook" type="url" fieldSize="w-100 h-md" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
            </Col>
            <Col xl={6}>
              <LabelField label="instagram" type="url" fieldSize="w-100 h-md" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
            </Col>

            <Col xl={6}>
              <LabelField label="twitter" type="url" fieldSize="w-100 h-md" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
            </Col>

            <Col xl={6}>
              <LabelField label="youtube" type="url" fieldSize="w-100 h-md" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
            </Col>

            <Col xl={12}>
              <div className="mc-label-field-group label-col text-left">
                <label className="mc-label-field-title"> About</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={about} onChange={(e) => setAbout(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Terms</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={terms} onChange={(e) => setTerms(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Privacy</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={privacy} onChange={(e) => setPrivacy(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={12}>
              <Button className="btn btn-primary w-100" type="button" disable={!Saving} onClick={() => updateSettings()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save</>}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
