import React, { useContext } from "react";
import { DrawerContext } from "../context/Drawer";
import Section from "../components/elements/Section";
import { Logout } from "../components/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function Sidebar() {
  const { userData, selectedCompany, loginType } = useSelector((state) => state.counter);
  const { drawer } = useContext(DrawerContext);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(userData);
  return (
    <Section as="aside" className={`mc-sidebar thin-scrolling ${drawer ? "active" : ""}`}>
      <ul class="mc-sidebar-menu-list">
        <li className={location.pathname == "/crm" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/crm" onClick={() => navigate("/crm")}>
            <i class="material-icons">dashboard</i>
            <span>Dashboard </span>
          </a>
        </li>

        {window.location.hostname == "admin.atsdhst.com" && (
          <>
            <li className={location.pathname == "/subscriptions" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/subscriptions " onClick={() => navigate("/subscriptions")}>
                <i class="material-icons">money</i>
                <span>Subscriptions </span>
              </a>
            </li>
            <li className={location.pathname == "/practitioners" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/practitioners" onClick={() => navigate("/practitioners")}>
                <i class="material-icons">group</i>
                <span>Practitioners </span>
              </a>
            </li>

            <li className={location.pathname == "/company" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/company" onClick={() => navigate("/company")}>
                <i class="material-icons">group</i>
                <span>Manage company</span>
              </a>
            </li>

            <li className={location.pathname == "/category" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/category" onClick={() => navigate("/category")}>
                <i class="material-icons">category</i>
                <span>Category</span>
              </a>
            </li>

            <li className={location.pathname == "/modules" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/modules" onClick={() => navigate("/modules")}>
                <i class="material-icons">menu_book</i>
                <span>Modules</span>
              </a>
            </li>
            <li className={location.pathname == "/exercise-video" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/exercise-video" onClick={() => navigate("/exercise-video")}>
                <i class="material-icons">play_arrow</i>
                <span>Exercise Video </span>
              </a>
            </li>

            <li className={location.pathname == "/settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
              <a class="mc-sidebar-menu-btn" href="/settings" onClick={() => navigate("/settings")}>
                <i class="material-icons">settings</i>
                <span>Settings</span>
              </a>
            </li>
          </>
        )}

        {loginType != 3 && userData && window.location.hostname == "account.atsdhst.com" && (
          <>
            {selectedCompany && selectedCompany.practitioner == userData._id && userData.accountType == 1 && (
              <>
                {userData.subscription && Number(userData.subscription.only_ats) == 1 && (
                  <>
                    <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/patients" onClick={() => navigate("/patients")}>
                        <i class="material-icons">group</i>
                        <span>Manage clients</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/patients?tracker=yes">
                        <i class="material-icons">accessibility_new</i>
                        <span>Tracker</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/appointments" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">event</i>
                        <span>Manage appointment</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/practitioner-settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">settings</i>
                        <span>Settings</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/roles" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">fingerprint</i>
                        <span>Roles</span>
                      </a>
                    </li>{" "}
                    <li className={location.pathname == "/users" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">group</i>
                        <span>Users</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/marketing" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">campaign</i>
                        <span>Marketing</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/invoice" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/invoice" onClick={() => navigate("/invoice")}>
                        <i class="material-icons">money</i>
                        <span>Subscription & invoice</span>
                      </a>
                    </li>
                  </>
                )}
                {userData.subscription && userData.subscription.only_ats == 0 && (
                  <>
                    <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/patients" onClick={() => navigate("/patients")}>
                        <i class="material-icons">group</i>
                        <span>Manage clients</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/appointments" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/appointments" onClick={() => navigate("/appointments")}>
                        <i class="material-icons">event</i>
                        <span>Manage appointment</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/practitioner-settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/practitioner-settings" onClick={() => navigate("/practitioner-settings")}>
                        <i class="material-icons">settings</i>
                        <span>Settings</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/roles" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/roles" onClick={() => navigate("/roles")}>
                        <i class="material-icons">fingerprint</i>
                        <span>Roles</span>
                      </a>
                    </li>{" "}
                    <li className={location.pathname == "/users" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/users" onClick={() => navigate("/users")}>
                        <i class="material-icons">group</i>
                        <span>Users</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/invoice" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/invoice" onClick={() => navigate("/invoice")}>
                        <i class="material-icons">money</i>
                        <span>Subscription & invoice</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/marketing" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/marketing" onClick={() => navigate("/marketing")}>
                        <i class="material-icons">campaign</i>
                        <span>Marketing</span>
                      </a>
                    </li>
                  </>
                )}
              </>
            )}

            {userData.role && userData.role.access && selectedCompany && selectedCompany.practitioner != userData._id && (
              <>
                {userData.subscription && Number(userData.subscription.only_ats) == 1 && (
                  <>
                    <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/patients" onClick={() => navigate("/patients")}>
                        <i class="material-icons">group</i>
                        <span>Manage clients</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/patients?tracker=yes">
                        <i class="material-icons">accessibility_new</i>
                        <span>Tracker</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/appointments" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">event</i>
                        <span>Manage appointment</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/practitioner-settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">settings</i>
                        <span>Settings</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/roles" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">fingerprint</i>
                        <span>Roles</span>
                      </a>
                    </li>{" "}
                    <li className={location.pathname == "/users" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">group</i>
                        <span>Users</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/marketing" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">campaign</i>
                        <span>Marketing</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/invoice" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/invoice" onClick={() => navigate("/invoice")}>
                        <i class="material-icons">money</i>
                        <span>Subscription & invoice</span>
                      </a>
                    </li>
                  </>
                )}
                {userData.subscription && userData.subscription.only_ats == 0 && (
                  <>
                    {userData.role.access.clients && userData.role.access.clients.view && (
                      <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                        <a class="mc-sidebar-menu-btn" href="/patients" onClick={() => navigate("/patients")}>
                          <i class="material-icons">group</i>
                          <span>Manage clients</span>
                        </a>
                      </li>
                    )}
                    {userData.role && userData.role.access.appointments.view && (
                      <li className={location.pathname == "/appointments" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                        <a class="mc-sidebar-menu-btn" href="/appointments" onClick={() => navigate("/appointments")}>
                          <i class="material-icons">event</i>
                          <span>Manage appointment</span>
                        </a>
                      </li>
                    )}

                    {userData.role && userData.role.access.roles.view && (
                      <li className={location.pathname == "/roles" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                        <a class="mc-sidebar-menu-btn" href="/roles" onClick={() => navigate("/roles")}>
                          <i class="material-icons">fingerprint</i>
                          <span>Roles</span>
                        </a>
                      </li>
                    )}

                    {userData.role && userData.role.access.users.view && (
                      <li className={location.pathname == "/users" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                        <a class="mc-sidebar-menu-btn" href="/users" onClick={() => navigate("/users")}>
                          <i class="material-icons">group</i>
                          <span>Users</span>
                        </a>
                      </li>
                    )}

                    {userData.role && userData.role.access.marketing.view && (
                      <li className={location.pathname == "/marketing" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                        <a class="mc-sidebar-menu-btn" href="/marketing" onClick={() => navigate("/marketing")}>
                          <i class="material-icons">campaign</i>
                          <span>Marketing</span>
                        </a>
                      </li>
                    )}
                  </>
                )}
              </>
            )}

            {!selectedCompany && (
              <>
                {userData.subscription && Number(userData.subscription.only_ats) == 1 && (
                  <>
                    <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/patients" onClick={() => navigate("/patients")}>
                        <i class="material-icons">group</i>
                        <span>Manage clients</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/patients?tracker=yes">
                        <i class="material-icons">accessibility_new</i>
                        <span>Tracker</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/appointments" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">event</i>
                        <span>Manage appointment</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/practitioner-settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">settings</i>
                        <span>Settings</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/roles" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">fingerprint</i>
                        <span>Roles</span>
                      </a>
                    </li>{" "}
                    <li className={location.pathname == "/users" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">group</i>
                        <span>Users</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/marketing" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="#" onClick={() => toast.error("you don't have access to this area please upgrade your plan to access it")}>
                        <i class="material-icons">campaign</i>
                        <span>Marketing</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/invoice" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/invoice" onClick={() => navigate("/invoice")}>
                        <i class="material-icons">money</i>
                        <span>Subscription & invoice</span>
                      </a>
                    </li>
                  </>
                )}
                {userData.subscription && userData.subscription.only_ats == 0 && (
                  <>
                    <li className={location.pathname == "/patients" || location.pathname == "/patient-details" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/patients" onClick={() => navigate("/patients")}>
                        <i class="material-icons">group</i>
                        <span>Manage clients</span>
                      </a>
                    </li>

                    <li className={location.pathname == "/appointments" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/appointments" onClick={() => navigate("/appointments")}>
                        <i class="material-icons">event</i>
                        <span>Manage appointment</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/practitioner-settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/practitioner-settings" onClick={() => navigate("/practitioner-settings")}>
                        <i class="material-icons">settings</i>
                        <span>Settings</span>
                      </a>
                    </li>

                    <li className={location.pathname == "/invoice" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/invoice" onClick={() => navigate("/invoice")}>
                        <i class="material-icons">money</i>
                        <span>Subscription & invoice</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/marketing" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/marketing" onClick={() => navigate("/marketing")}>
                        <i class="material-icons">campaign</i>
                        <span>Marketing</span>
                      </a>
                    </li>

                    <li className={location.pathname == "/all-course" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/all-course" onClick={() => navigate("/all-course")}>
                        <i class="material-icons">menu_book</i>
                        <span>Courses</span>
                      </a>
                    </li>
                    <li className={location.pathname == "/my-course" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                      <a class="mc-sidebar-menu-btn" href="/my-course" onClick={() => navigate("/my-course")}>
                        <i class="material-icons">book</i>
                        <span>My Courses</span>
                      </a>
                    </li>
                  </>
                )}
              </>
            )}
          </>
        )}

        {loginType == 3 && userData && window.location.hostname == "account.atsdhst.com" && (
          <>
            {!selectedCompany && (
              <>
                <li className={location.pathname == "/all-course" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                  <a class="mc-sidebar-menu-btn" href="/all-course" onClick={() => navigate("/all-course")}>
                    <i class="material-icons">menu_book</i>
                    <span>Courses</span>
                  </a>
                </li>
                <li className={location.pathname == "/my-course" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
                  <a class="mc-sidebar-menu-btn" href="/my-course" onClick={() => navigate("/my-course")}>
                    <i class="material-icons">book</i>
                    <span>My Courses</span>
                  </a>
                </li>
              </>
            )}
          </>
        )}
      </ul>
      <Logout />
    </Section>
  );
}
