import { ThemeProvider } from "./context/Themes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Error } from "./pages/supports";
import {
  CRM,
  ForgotPassword,
  Login,
  Register,
  Onboard,
  PractitionerSettings,
  MyAccount,
  Settings,
  Subscriptions,
  Patients,
  PatientDetails,
  Appointments,
  Invoice,
  SetPassword,
  Practitioners,
  Company,
  Autologin,
  ExerciseVideo,
  Roles,
  Users,
  Marketing,
  Category,
  Module,
  ModuleAdd,
  ModuleEdit,
  AllCourse,
  CourseDetails,
  CourseStudayVideo,
  CourseStudayPDF,
  CourseStudayExam,
  MyCourse,
  Certificate,
  Subscribe,
} from "./pages/master";
import { Provider } from "react-redux";
import { store } from "./store";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Toaster
          position="bottom-center"
          toastOptions={{
            duration: 5000,
            style: {
              background: "#000000",
              color: "#fff",
              zIndex: 999999,
            },
          }}
        />

        <BrowserRouter>
          <Routes>
            {/* master Pages */}

            <Route path="/crm" element={<CRM />} />
            <Route path="/login" element={<Login />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            {/* Supports Pages */}
            <Route path="*" element={<Error />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/onboard" element={<Onboard />} />
            <Route path="/practitioner-settings" element={<PractitionerSettings />} />
            <Route path="/patients" element={<Patients />} />
            <Route path="/patient-details" element={<PatientDetails />} />
            <Route path="/appointments" element={<Appointments />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/practitioners" element={<Practitioners />} />
            <Route path="/company" element={<Company />} />
            <Route path="/autologin" element={<Autologin />} />
            <Route path="/exercise-video" element={<ExerciseVideo />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/users" element={<Users />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/category" element={<Category />} />
            <Route path="/modules" element={<Module />} />
            <Route path="/modules-add" element={<ModuleAdd />} />
            <Route path="/modules-edit" element={<ModuleEdit />} />
            <Route path="/all-course" element={<AllCourse />} />
            <Route path="/course-detail" element={<CourseDetails />} />
            <Route path="/course-study-video" element={<CourseStudayVideo />} />
            <Route path="/course-study-pdf" element={<CourseStudayPDF />} />
            <Route path="/course-study-exam" element={<CourseStudayExam />} />

            <Route path="/my-course" element={<MyCourse />} />
            <Route path="/certificate" element={<Certificate />} />
            <Route path="/subscribe" element={<Subscribe />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}
