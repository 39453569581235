import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
const Login = () => {
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [subscriptionData, setData] = useState([]);
  const { userData, token, selectedCompany } = useSelector((state) => state.counter);
  useEffect(() => {
    fetchSubscription();
  }, []);

  async function fetchSubscription() {
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/subscription/all");
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function subscribe(id, only_ats) {
    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "app/subscription/subscribe",
          {
            id: userData._id,
            subscription: id,
          },
          options
        )
        .then((response) => {
          if (response.data.status == "success") {
            toast(response.data.message);
            setSaving(false);
            if (only_ats == 1) {
              navigate("/crm");
            } else {
              navigate("/onboard");
            }
          } else {
            toast.error("Something went wrong");
            setSaving(false);
          }
        });
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <div>
      <div className="container">
        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-6">Pricing</h1>
          <p class="lead d-none">Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components and utilities with little customization.</p>
        </div>

        <div class="card-deck mb-5 text-center">
          <div className="row">
            {subscriptionData?.length > 0 &&
              subscriptionData.map(
                (item, key) =>
                  item.status == 1 && (
                    <>
                      {" "}
                      <div className="col-md-6">
                        <div class="card mb-4 box-shadow">
                          <div class="card-header">
                            <h4 class="my-0 font-weight-normal">{item.title}</h4>
                          </div>
                          <div class="card-body" style={{ minHeight: 350 }}>
                            <h1 class="card-title pricing-card-title">
                              ${item.price} <small class="text-muted">/ mo</small>
                            </h1>

                            {item.desc.split(/\n/).map((line) => (
                              <div key={line}>{line}</div>
                            ))}

                            <button type="button" class="btn btn-lg btn-block btn-primary mt-5" onClick={() => subscribe(item._id, item.only_ats)}>
                              {saving ? "Wait..." : "Start trial"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
