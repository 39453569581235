import { combineReducers } from "redux";
import counterReducer from "./reducer";
// import other reducers if you have them

const rootReducer = combineReducers({
    counter: counterReducer,
    // other reducers...
});

export default rootReducer;
