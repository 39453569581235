import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import Modal from "react-bootstrap/Modal";
import Domains from "./userDomains";
export default function ProductList() {
  const { userData, token, selectedCompany } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const [mode, setMode] = useState("add");
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [masterRole, setMasterRole] = useState([]);

  async function handleFAQ() {
    if (!fname && fname == "") {
      toast.error("Enter first name");
      return;
    }

    if (!lname && lname == "") {
      toast.error("Enter last name");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/user/add",
        {
          fname: fname,
          lname: lname,
          email: email,
          security: password,
          role: role,
          company: selectedCompany._id,
        },
        options
      );
      if (Response.data.status == "success") {
        setFname("");
        setLname("");
        setEmail("");
        setPassword("");
        setRole("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    } else {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/user/update/" + id,
        {
          fname: fname,
          lname: lname,
          email: email,
          security: password,
          role: role,
        },
        options
      );
      if (Response.data.status == "success") {
        setFname("");
        setLname("");
        setEmail("");
        setPassword("");
        setRole("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    }
  }

  useEffect(() => {
    fetchData(1);
    fetchRoles();
  }, []);

  async function fetchRoles() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/role/all?company=" + selectedCompany._id, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterRole(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      company: selectedCompany._id,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/user/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/user/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Users"}>
              {userData.role && userData.role.access.users.add && (
                <li className="mc-breadcrumb-item">
                  <Anchor
                    icon={"add"}
                    text={"Add"}
                    href={"#"}
                    className="mc-btn primary"
                    onClick={() => {
                      setShow(true);
                      setMode("add");
                      setFname("");
                      setLname("");
                      setEmail("");
                      setPassword("");
                      setRole("");
                    }}
                  />
                </li>
              )}

              {selectedCompany && selectedCompany.isOwner && (
                <li className="mc-breadcrumb-item">
                  <Anchor
                    icon={"add"}
                    text={"Add"}
                    href={"#"}
                    className="mc-btn primary"
                    onClick={() => {
                      setShow(true);
                      setMode("add");
                      setFname("");
                      setLname("");
                      setEmail("");
                      setPassword("");
                      setRole("");
                    }}
                  />
                </li>
              )}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Role</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <Tr key={key}>
                            <Td>
                              {item.fname} {item.lname}
                            </Td>
                            <Td>{item.email}</Td>
                            <Td>{item.role && item.role.title}</Td>

                            <Td>
                              <Box className="mc-table-action">
                                {userData.role && userData.role.access.users.edit && (
                                  <Anchor
                                    href="#"
                                    title="Edit"
                                    className="material-icons edit"
                                    onClick={() => {
                                      setMode("edit");

                                      setId(item._id);
                                      setShow(true);
                                      setFname(item.fname);
                                      setLname(item.lname);
                                      setEmail(item.email);
                                      setRole(item.role._id);
                                    }}
                                  >
                                    <Icon>edit</Icon>
                                  </Anchor>
                                )}

                                {userData.role && userData.role.access.users.delete && (
                                  <Button
                                    title="Delete"
                                    className="material-icons delete"
                                    onClick={() => {
                                      setId(item._id);
                                      setShowSwal(true);
                                    }}
                                  >
                                    <Icon>delete</Icon>
                                  </Button>
                                )}

                                {selectedCompany && selectedCompany.isOwner && (
                                  <>
                                    <Anchor
                                      href="#"
                                      title="Edit"
                                      className="material-icons edit"
                                      onClick={() => {
                                        setMode("edit");

                                        setId(item._id);
                                        setShow(true);
                                        setFname(item.fname);
                                        setLname(item.lname);
                                        setEmail(item.email);
                                        setRole(item.role._id);
                                      }}
                                    >
                                      <Icon>edit</Icon>
                                    </Anchor>

                                    <Button
                                      title="Delete"
                                      className="material-icons delete"
                                      onClick={() => {
                                        setId(item._id);
                                        setShowSwal(true);
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </Button>
                                  </>
                                )}
                              </Box>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} User</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <div className="col-md-12">
              <div className="form-group">
                <label>Roles</label>
                <select className="form-control" onChange={(e) => setRole(e.target.value)} value={role}>
                  <option value=""> Select </option>
                  {masterRole?.length > 0 &&
                    masterRole.map((item, key) => (
                      <option value={item._id} key={key}>
                        {item.title}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <Col xl={12}>
              <LabelField label="First name" type="text" fieldSize="w-100 h-md" value={fname} onChange={(e) => setFname(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Last name" type="text" fieldSize="w-100 h-md" value={lname} onChange={(e) => setLname(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Email" type="email" fieldSize="w-100 h-md" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Password" type="password" fieldSize="w-100 h-md" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Col>

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleFAQ()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This listing will be permanently deleted from your account.
        </SweetAlert>
      )}

      <Modal show={show2} onHide={handleClose2} backdrop="static" fullscreen={true} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Assign domains</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Domains userId={id} />
        </Modal.Body>
      </Modal>
    </PageLayout>
  );
}
