import React from "react";
import { Box, Form, Image } from "../../components/elements";
import data from "../../data/master/login.json";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import WebsiteSetting from "../blocks/WebsiteSetting";
import PaymentSetting from "../blocks/PaymentSetting";
import Packages from "../blocks/Packages";
import BusinessHours from "../blocks/BusinessHours";
import Holidays from "../blocks/Holidays";
import Rooms from "../blocks/Rooms";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Login = () => {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  const navigate = useNavigate();
  const [currenttab, setcurrenttab] = useState(0);

  return (
    <Box className="mc-auth" style={{ backgroundColor: "#000070" }}>
      <Image src={data?.pattern.src} alt={data?.pattern.alt} className="mc-auth-pattern" />
      <Box className="mc-auth-group" style={{ maxWidth: "100%" }}>
        <Form className="mc-auth-form wizard-card">
          <div class="wizard-header">
            <h4>Hi {userData.fname}, complete your profile</h4>
            <small>This information will let us know more about you.</small>
          </div>

          <div className="mt-3 mb-2">
            {currenttab == 5 && (
              <button type="button" class="btn btn-success mb-3" onClick={() => navigate("/crm")}>
                Finish & go to dashboard
              </button>
            )}

            <div direction="horizontal" className="mb-3">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-primary" disabled={currenttab == 0} onClick={() => setcurrenttab((prev) => Number(prev) - 1)}>
                  Previous
                </button>
                <button type="button" class="btn btn-primary" disabled={currenttab == 5} onClick={() => setcurrenttab((prev) => Number(prev) + 1)}>
                  Next
                </button>
              </div>
            </div>

            <Tabs activeKey={currenttab} id="uncontrolled-tab-example" className="mb-3" onSelect={(key) => setcurrenttab(key)}>
              <Tab eventKey={0} title="Website & Social">
                <WebsiteSetting />
              </Tab>
              <Tab eventKey={1} title="Business Hours">
                <BusinessHours />
              </Tab>
              <Tab eventKey={2} title="Rooms">
                <Rooms />
              </Tab>
              <Tab eventKey={3} title="Special off">
                <Holidays />
              </Tab>
              <Tab eventKey={4} title="Payment Method">
                <PaymentSetting />
              </Tab>{" "}
              <Tab eventKey={5} title="Packages">
                <Packages />
              </Tab>
            </Tabs>
          </div>
        </Form>
      </Box>
    </Box>
  );
};
export default Login;
