import React from "react";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout2";
import { CardLayout } from "./../../components/cards";
import { Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";

export default function Category() {
  const [data, setData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [sortBy, setSortBy] = useState("");

  // Calling Load data On Run Time
  useEffect(() => {
    LoadData();
    LoadCategory();
  }, []);

  useEffect(() => {
    LoadData();
  }, [category, sortBy]);

  function LoadData() {
    const params = {
      category: category,
      sortBy: sortBy,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    axios.get(process.env.REACT_APP_API_URL + "app/courses/all?" + queryString).then(
      (response) => {
        setData(response.data.data);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }
  function LoadCategory() {
    axios.get(process.env.REACT_APP_API_URL + "app/category/all").then(
      (response) => {
        setCategoryList(response.data.result);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  // Load All data To Display In Table
  function LoadData() {
    const params = {
      category: category,
      sortBy: sortBy,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    axios.get(process.env.REACT_APP_API_URL + "app/courses/all?" + queryString).then(
      (response) => {
        setData(response.data.data);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  return (
    <PageLayout>
      <br />
      <Row className="mt-5">
        <div>
          <h2>All Courses</h2>
        </div>

        <Col xl={12}>
          <CardLayout>
            <div className="course-list-section">
              <div className="">
                <div className="row">
                  <div className="col-md-4">
                    <div className="filter-item">
                      <select className="form-control" onChange={(e) => setSortBy(e.target.value)}>
                        <option value="latest">Sort By: Recently Added</option>
                        <option value="price-high">Sort By: Price High</option>
                        <option value="price-low">Sort By: Price Low</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="filter-item" style={{ marginLeft: 10 }}>
                      <select className="form-control" onChange={(e) => setCategory(e.target.value)}>
                        <option value="">All Category</option>
                        {categoryList?.length > 0 &&
                          categoryList.map((item, key) => (
                            <>
                              <option value={item._id}>Category: {item.name}</option>
                            </>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="result-show mt-2">
                      Showing : <span>{data?.length} Courses</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-3">
                <div className="course-list-part col-md-4">
                  {data?.length > 0 &&
                    data.map((item, key) => (
                      <div className="course-item">
                        <div className="course-image">
                          <img className="w-1/1" src={process.env.REACT_APP_IMG_URL + "content/" + item.thumb} />
                        </div>
                        <div className="course-content">
                          <div className="course-title">
                            <a href={"/course-detail?id=" + item.slug}>{item.title}</a>
                          </div>

                          <div className="course-des">{item.desc.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 100)}...</div>
                          <div className="course-action">
                            <a href={"/course-detail?id=" + item.slug}>
                              <button type="button" className="btn btn-primary">
                                Details <i className="fas fa-chevron-right ml-2"></i>
                              </button>
                            </a>

                            <div className="price">${Number(item.price).toFixed()}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
