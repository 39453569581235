import React, { useState, useEffect, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import Pagination from "react-js-pagination";
import qs from "qs";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Domains from "../blocks/assignDomain";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
function NavBar({ userId }) {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");
  const [keyword, seKeyword] = useState("");
  const [retreatURL, seRetreatURL] = useState("");
  const [marketingCollateral, setMarketingCollateral] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!show && userId && userId != "") {
      fetchData(1);
      setActivePage(1);
    }
  }, [show]);

  useEffect(() => {
    if (userId && userId != "") {
      fetchData(1);
    }
  }, [userId]);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      adminUser: userId,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/user-domain/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function deleteDomain() {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      subdomain: id,
      userId: userId,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    await axios.get(process.env.REACT_APP_API_URL + "app/sub-domain/assign-domain?" + queryString, options);
    setShowSwal(false);
    fetchData(1);
    setActivePage(1);
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }
  function UpdateStatus(id, displayHome) {
    let result = window.confirm("Are you sure?");
    if (result) {
      const options = {
        headers: { authorization: token },
      };

      axios.post(process.env.REACT_APP_API_URL + "app/subdomain-products/update/" + id, qs.stringify({ displayHome: displayHome }), options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          handlePageChange(1);
        } else {
          toast(response.data.message);
        }
      });
    }
  }
  return (
    <>
      <Row>
        {" "}
        <Col>
          <div class="form-group">
            <label>Keyword</label>
            <input type="text" placeholder="Title" className="form-control" onChange={(e) => seKeyword(e.target.value)} value={keyword} />
          </div>
        </Col>
        <Col>
          <div class="form-group">
            <label>Retreat URL</label>
            <input type="text" placeholder="Title" className="form-control" onChange={(e) => seRetreatURL(e.target.value)} value={retreatURL} />
          </div>
        </Col>
        <Col>
          <div class="form-group">
            <label>Marketing Collateral</label>
            <select class="form-control" onChange={(e) => setMarketingCollateral(e.target.value)} value={marketingCollateral}>
              <option value=""> Select </option>
              <option value="individual"> Individual </option>
              <option value="cohost"> Co-hosts </option>
            </select>
          </div>
        </Col>
        <Col>
          <div class="form-group">
            <Button
              icon={"search"}
              text={"Search"}
              className="mc-btn primary mt-4 w-100"
              onClick={() => {
                setActivePage(1);
                fetchData(1);
              }}
            />
          </div>
        </Col>
        <Col>
          <Button icon={"add"} text={"Assign"} className="mc-btn primary mt-4 w-100" onClick={() => handleShow()}></Button>
        </Col>
      </Row>
      <br />
      <Col xl={12}>
        <Box className="mc-table-responsive">
          <Table className="mc-table">
            <Thead className="mc-table-head primary">
              <Tr>
                <Th>Type</Th>
                <Th>Company/Name</Th>
                <Th>Retreat URL</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody className="mc-table-body even">
              {data?.length > 0 &&
                data.map((item, key) => (
                  <>
                    <Tr key={key}>
                      <Td>{item.marketingCollateral}</Td>
                      <Td>{item.name}</Td>
                      <Td>{item.retreatURL}.iamfit4travel.com</Td>
                      <Td>
                        <Box className="mc-table-action">
                          <Button
                            title="Delete"
                            className="material-icons delete"
                            onClick={() => {
                              setId(item.subdomain);
                              setShowSwal(true);
                            }}
                          >
                            <Icon>delete</Icon>
                          </Button>
                        </Box>
                      </Td>
                    </Tr>
                  </>
                ))}
            </Tbody>
          </Table>
        </Box>{" "}
        <Box className="mc-paginate">
          <Text className="mc-paginate-title">
            Total <b>{totalRecod}</b> Results Found
          </Text>
          <nav aria-label="Page navigation example">
            {totalRecod > perPage ? (
              <Pagination
                prevPageText="prev"
                nextPageText="next"
                firstPageText="first"
                lastPageText="last"
                activePage={activePage}
                itemsCountPerPage={perPage}
                totalItemsCount={totalRecod}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination justify-content-center"
              />
            ) : (
              ""
            )}
          </nav>
        </Box>
      </Col>{" "}
      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={deleteDomain} onCancel={() => setShowSwal(false)}>
          This listing will be permanently removed from the admin domain list.
        </SweetAlert>
      )}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Assign domain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Domains userId={userId} />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default NavBar;
