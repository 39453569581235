import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/settings.json";
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";

const Settings = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  const [Saving, setSaving] = useState(false);
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDescrption, setSiteDescrption] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [about, setAbout] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [terms, setTerms] = useState("");
  const [travelProfessionals, setTravelProfessionals] = useState("");

  const [section1, setSection1] = useState("");
  const [section2, setSection2] = useState("");
  const [section3, setSection3] = useState("");
  const [section4, setSection4] = useState("");
  const [section51, setSection51] = useState("");
  const [section52, setSection52] = useState("");
  const [section53, setSection53] = useState("");
  const [section61, setSection61] = useState("");
  const [section62, setSection62] = useState("");
  const [section63, setSection63] = useState("");
  const [section71, setSection71] = useState("");
  const [section72, setSection72] = useState("");
  const [section73, setSection73] = useState("");
  const [section81, setSection81] = useState("");
  const [section82, setSection82] = useState("");
  const [section83, setSection83] = useState("");
  const [section91, setSection91] = useState("");
  const [section92, setSection92] = useState("");
  const [section93, setSection93] = useState("");

  const [section10, setSection10] = useState("");
  const [section11, setSection11] = useState("");
  const [section12, setSection12] = useState("");
  const [section13, setSection13] = useState("");
  const [section14, setSection14] = useState("");
  const [section15, setSection15] = useState("");
  const [section16, setSection16] = useState("");
  const [section17, setSection17] = useState("");
  const [section18, setSection18] = useState("");
  const [section19, setSection19] = useState("");
  const [section20, setSection20] = useState("");
  const [section21, setSection21] = useState("");
  const [section22, setSection22] = useState("");
  const [section23, setSection23] = useState("");
  const [section24, setSection24] = useState("");
  const [section25, setSection25] = useState("");
  const [section26, setSection26] = useState("");

  useEffect(() => {
    fetchSettings();
  }, []);

  async function fetchSettings() {
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/settings", options);
    if (Response && Response.data.status == "success") {
      if (Response.data.result) {
        setSiteTitle(Response.data.result.siteTitle);
        setSiteDescrption(Response.data.result.siteDescrption);
        setPhone(Response.data.result.phone);
        setEmail(Response.data.result.email);
        setAddress(Response.data.result.address);
        setFacebook(Response.data.result.facebook);
        setInstagram(Response.data.result.instagram);
        setTwitter(Response.data.result.twitter);
        setYoutube(Response.data.result.youtube);
        setSiteTitle(Response.data.result.siteTitle);
        setSiteTitle(Response.data.result.siteTitle);
        setSiteTitle(Response.data.result.siteTitle);
        setAbout(Response.data.result.about);
        setPrivacy(Response.data.result.privacy);
        setTerms(Response.data.result.terms);
        setTravelProfessionals(Response.data.result.travel_professionals);

        setSection1(Response.data.result.section1);
        setSection2(Response.data.result.section2);
        setSection4(Response.data.result.section4);
        setSection52(Response.data.result.section52);
        setSection53(Response.data.result.section53);
        setSection62(Response.data.result.section62);
        setSection63(Response.data.result.section63);
        setSection72(Response.data.result.section72);
        setSection73(Response.data.result.section73);
        setSection82(Response.data.result.section82);
        setSection83(Response.data.result.section83);
        setSection92(Response.data.result.section92);
        setSection93(Response.data.result.section93);

        setSection10(Response.data.result.section10);
        setSection11(Response.data.result.section11);
        setSection12(Response.data.result.section12);
        setSection13(Response.data.result.section13);
        setSection14(Response.data.result.section14);
        setSection15(Response.data.result.section15);
        setSection16(Response.data.result.section16);
        setSection17(Response.data.result.section17);
        setSection18(Response.data.result.section18);
        setSection19(Response.data.result.section19);
        setSection20(Response.data.result.section20);
        setSection21(Response.data.result.section21);
        setSection22(Response.data.result.section22);
        setSection23(Response.data.result.section23);
        setSection24(Response.data.result.section24);
        setSection25(Response.data.result.section25);
        setSection26(Response.data.result.section26);
        setSection3(Response.data.result.section3);
        setSection51(Response.data.result.section51);
        setSection61(Response.data.result.section61);
        setSection71(Response.data.result.section71);
        setSection81(Response.data.result.section81);
        setSection91(Response.data.result.section91);
        setSection11(Response.data.result.section11);
        setSection16(Response.data.result.section16);
        setSection18(Response.data.result.section18);
        setSection24(Response.data.result.section24);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function ImageHandler(e, section) {
    const options = {
      "Content-Type": "application/x-www-form-urlencoded",
      headers: { authorization: token },
    };

    const logo = e.currentTarget.files[0];
    const logoData = new FormData();
    logoData.append("upload", logo);
    logoData.append("section", section);
    await axios.post(process.env.REACT_APP_API_URL + "app/settings/uploadHomeImage", logoData, options);
    fetchSettings();
  }

  async function updateSettings() {
    if (!siteTitle && siteTitle == "") {
      toast.error("Enter site title");
      return;
    }

    if (!siteDescrption && siteDescrption == "") {
      toast.error("Enter site descrption");
      return;
    }

    if (!phone && phone == "") {
      toast.error("Enter phone number");
      return;
    }

    if (!email && email == "") {
      toast.error("Enter email address");
      return;
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      toast.error("Enter valid email address");
      return;
    }

    if (!address && address == "") {
      toast.error("Enter address");
      return;
    }
    setSaving(true);
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/settings/update",
      {
        siteTitle: siteTitle,
        siteDescrption: siteDescrption,
        phone: phone,
        email: email,
        address: address,
        facebook: facebook,
        instagram: instagram,
        twitter: twitter,
        youtube: youtube,
        about: about,
        privacy: privacy,
        terms: terms,
        travel_professionals: travelProfessionals,

        section1: section1,
        section2: section2,
        section4: section4,
        section52: section52,
        section53: section53,
        section62: section62,
        section63: section63,
        section72: section72,
        section73: section73,
        section82: section82,
        section83: section83,
        section92: section92,
        section93: section93,
        section10: section10,
        section12: section12,
        section13: section13,
        section14: section14,
        section15: section15,
        section17: section17,
        section19: section19,
        section20: section20,
        section21: section21,
        section22: section22,
        section23: section23,
        section25: section25,
        section26: section26,
      },
      options
    );
    if (Response.data.status == "success") {
      setSaving(false);
      toast.success(Response.data.message);
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout className="p-sm-5">
        <Box className="mb-5">
          <Tabs defaultActiveKey="basic">
            <Tab eventKey="basic" title="Basic Information">
              <Row className="mt-3">
                <Col xl={1}></Col>
                <Col xl={10} className="ps-xl-5">
                  <Row>
                    <Col xl={12}>
                      <LabelField label="site title" type="text" fieldSize="w-100 h-md" value={siteTitle} onChange={(e) => setSiteTitle(e.target.value)} />
                    </Col>
                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> Description</label>
                        <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={siteDescrption} onChange={(e) => setSiteDescrption(e.target.value)}></textarea>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <LabelField label="phone number" type="text" fieldSize="w-100 h-md" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </Col>
                    <Col xl={6}>
                      <LabelField label="email address" type="email" fieldSize="w-100 h-md" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Col>
                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> address</label>
                        <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
                      </div>
                    </Col>

                    <Col xl={12}>
                      <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => updateSettings()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>save all changes</>}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xl={1}></Col>
              </Row>
            </Tab>
            <Tab eventKey="social" title="Social">
              <Row className="mt-3">
                <Col xl={1}></Col>
                <Col xl={10} className="ps-xl-5">
                  <Row>
                    <Col xl={6}>
                      <LabelField label="facebook" type="url" fieldSize="w-100 h-md" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                    </Col>
                    <Col xl={6}>
                      <LabelField label="instagram" type="url" fieldSize="w-100 h-md" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                    </Col>

                    <Col xl={6}>
                      <LabelField label="twitter" type="url" fieldSize="w-100 h-md" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
                    </Col>

                    <Col xl={6}>
                      <LabelField label="youtube" type="url" fieldSize="w-100 h-md" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
                    </Col>

                    <Col xl={12}>
                      <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => updateSettings()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>save all changes</>}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xl={1}></Col>
              </Row>
            </Tab>
            <Tab eventKey="cms" title="CMS">
              <Row className="mt-3">
                <Col xl={1}></Col>
                <Col xl={10} className="ps-xl-5">
                  <Row>
                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> About</label>

                        <SunEditor
                          setOptions={{
                            buttonList: [
                              ["font"],
                              ["fontSize", "formatBlock"],
                              ["align", "horizontalRule", "lineHeight", "list"],
                              ["bold", "underline", "italic", "strike"],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              ["blockquote", "outdent", "indent"],
                              ["table"],
                              ["link", "video"],
                              ["image"],
                              ["codeView", "fullScreen", "showBlocks"],
                            ],
                          }}
                          setContents={about}
                          height="150px"
                          onChange={setAbout}
                        />
                      </div>
                    </Col>

                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> Terms</label>

                        <SunEditor
                          setOptions={{
                            buttonList: [
                              ["font"],
                              ["fontSize", "formatBlock"],
                              ["align", "horizontalRule", "lineHeight", "list"],
                              ["bold", "underline", "italic", "strike"],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              ["blockquote", "outdent", "indent"],
                              ["table"],
                              ["link", "video"],
                              ["image"],
                              ["codeView", "fullScreen", "showBlocks"],
                            ],
                          }}
                          setContents={terms}
                          height="150px"
                          onChange={setTerms}
                        />
                      </div>
                    </Col>

                    <Col xl={12}>
                      <div className="mc-label-field-group label-col">
                        <label className="mc-label-field-title"> Privacy</label>

                        <SunEditor
                          setOptions={{
                            buttonList: [
                              ["font"],
                              ["fontSize", "formatBlock"],
                              ["align", "horizontalRule", "lineHeight", "list"],
                              ["bold", "underline", "italic", "strike"],
                              ["fontColor", "hiliteColor"],
                              ["removeFormat"],
                              ["blockquote", "outdent", "indent"],
                              ["table"],
                              ["link", "video"],
                              ["image"],
                              ["codeView", "fullScreen", "showBlocks"],
                            ],
                          }}
                          setContents={privacy}
                          height="150px"
                          onChange={setPrivacy}
                        />
                      </div>
                    </Col>

                    <Col xl={12}>
                      <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => updateSettings()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>save all changes</>}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xl={1}></Col>
              </Row>
            </Tab>

            <Tab eventKey="website" title="Website">
              <div className="p-3 text-center">
                <Button className={"mc-btn primary"} onClick={handleShow}>
                  Sections
                </Button>
              </div>

              <div className="p-3">
                <LabelField label="Section 1" type="text" fieldSize="w-100 h-md" value={section1} onChange={(e) => setSection1(e.target.value)} />
                <br />
                <div className="mc-label-field-group label-col">
                  <label className="mc-label-field-title"> Section 2</label>
                  <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section2} onChange={(e) => setSection2(e.target.value)}></textarea>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Section 3" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section3")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section3} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Section 4" type="text" fieldSize="w-100 h-md" value={section4} onChange={(e) => setSection4(e.target.value)} />
                <hr />
                <h5>Section 5</h5> <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Image" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section51")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section51} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Heading" type="text" fieldSize="w-100 h-md" value={section52} onChange={(e) => setSection52(e.target.value)} />
                <br />
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section53} onChange={(e) => setSection53(e.target.value)}></textarea>
                <hr />
                <h5>Section 6</h5> <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Image" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section61")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section61} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Heading" type="text" fieldSize="w-100 h-md" value={section62} onChange={(e) => setSection62(e.target.value)} />
                <br />
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section63} onChange={(e) => setSection63(e.target.value)}></textarea>
                <hr />
                <h5>Section 7</h5> <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Image" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section71")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section71} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Heading" type="text" fieldSize="w-100 h-md" value={section72} onChange={(e) => setSection72(e.target.value)} />
                <br />
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section73} onChange={(e) => setSection73(e.target.value)}></textarea>
                <hr />
                <h5>Section 8</h5> <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Image" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section81")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section81} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Heading" type="text" fieldSize="w-100 h-md" value={section82} onChange={(e) => setSection82(e.target.value)} />
                <br />
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section83} onChange={(e) => setSection83(e.target.value)}></textarea>
                <hr />
                <h5>Section 9</h5> <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Image" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section91")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section91} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Heading" type="text" fieldSize="w-100 h-md" value={section92} onChange={(e) => setSection92(e.target.value)} />
                <br />
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section93} onChange={(e) => setSection93(e.target.value)}></textarea>
                <br />
                <hr />
                <LabelField label="Section 10" type="text" fieldSize="w-100 h-md" value={section10} onChange={(e) => setSection10(e.target.value)} />
                <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Section 11" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section11")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section11} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Section 12" type="text" fieldSize="w-100 h-md" value={section12} onChange={(e) => setSection12(e.target.value)} />
                <br />
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section13} onChange={(e) => setSection13(e.target.value)}></textarea>
                <br />
                <LabelField label="Section 14" type="text" fieldSize="w-100 h-md" value={section14} onChange={(e) => setSection14(e.target.value)} />
                <br />
                <LabelField label="Section 15" type="text" fieldSize="w-100 h-md" value={section15} onChange={(e) => setSection15(e.target.value)} />
                <br />
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section26} onChange={(e) => setSection26(e.target.value)}></textarea>
                <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Section 16" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section16")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section16} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Section 17" type="text" fieldSize="w-100 h-md" value={section17} onChange={(e) => setSection17(e.target.value)} />
                <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Section 18" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section18")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section18} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Section 19" type="text" fieldSize="w-100 h-md" value={section19} onChange={(e) => setSection19(e.target.value)} />
                <br />
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section20} onChange={(e) => setSection20(e.target.value)}></textarea>
                <br />
                <LabelField label="Section 21" type="text" fieldSize="w-100 h-md" value={section21} onChange={(e) => setSection21(e.target.value)} />
                <br />
                <LabelField label="Section 22" type="text" fieldSize="w-100 h-md" value={section22} onChange={(e) => setSection22(e.target.value)} />
                <br />
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={section23} onChange={(e) => setSection23(e.target.value)}></textarea>
                <br />
                <div className="row">
                  <div className="col-md-9">
                    <LabelField label="Section 24" type="file" fieldSize="w-100 h-md" onChange={(e) => ImageHandler(e, "section24")} />
                  </div>
                  <div className="col-md-3">
                    <img src={process.env.REACT_APP_IMG_URL + "/cms/" + section24} class="img-fluid" />
                  </div>
                </div>
                <br />
                <LabelField label="Section 25" type="text" fieldSize="w-100 h-md" value={section25} onChange={(e) => setSection25(e.target.value)} />
                <br />
                <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => updateSettings()}>
                  {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>save all changes</>}
                </Button>
              </div>
            </Tab>
          </Tabs>
        </Box>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>View Sections</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src="/images/website.png" className="img-fluid" />
          </Modal.Body>
        </Modal>
      </CardLayout>
    </PageLayout>
  );
};

export default Settings;
