export const incrementCounter = () => {
  return {
    type: "INCREMENT_COUNTER",
  };
};

export const decrementCounter = () => {
  return {
    type: "DECREMENT_COUNTER",
  };
};

export const setUserData = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_USER_DATA",
    payload: payload,
  });
};

export const setLoginStatus = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_LOGIN_STATUS",
    payload: payload,
  });
};

export const setToken = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_TOKEN",
    payload: payload,
  });
};

export const setRefreshToken = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_REFRESH_TOKEN",
    payload: payload,
  });
};

export const setSaveIndicator = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_SAVE_INDICATOR",
    payload: payload,
  });
};

export const setReportData = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_REPORT_DATA",
    payload: payload,
  });
};

export const setChatData = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_CHAT_DATA",
    payload: payload,
  });
};

export const setPhysicalSensation = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_PHYSICAL_DATA",
    payload: payload,
  });
};
export const setMentalSensation = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_MENTAL_DATA",
    payload: payload,
  });
};
export const setSelectedAtsTab = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_SELECTED_ATS_TAB",
    payload: payload,
  });
};
export const setAdmin = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_ADMIN",
    payload: payload,
  });
};
export const setAdminData = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_ADMIN_DATA",
    payload: payload,
  });
};

export const setCompanyList = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_COMPANY_LIST",
    payload: payload,
  });
};

export const setSelectedCompany = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_SELECTED_COMPANY",
    payload: payload,
  });
};

export const setRole = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_ROLE",
    payload: payload,
  });
};

export const setLoginAccountType = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_LOGIN_ACC_TYPE",
    payload: payload,
  });
};

export const setSelectedAppointment = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_SELECTED_APP",
    payload: payload,
  });
};
