import React from "react";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { CardLayout } from "./../../components/cards";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box } from "./../../components/elements";

export default function MyCourse() {
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.counter);

  const [data, setData] = useState([]);

  // Calling Load data On Run Time
  useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    const params = {
      id: userData._id,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    axios.get(process.env.REACT_APP_API_URL + "app/courses/my?" + queryString).then(
      (response) => {
        setData(response.data.data);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"My Courses"}></Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <div className="course-list-section">
              <div className="row d-flex justify-content-between align-items-center">
                <div className="filter-list"></div>
                <div className="result-show">
                  Showing : <span>{data?.length} Courses</span>
                </div>
              </div>
              <div className="row mt-3">
                <Col xl={12}>
                  <Box className="mc-table-responsive">
                    <Table className="mc-table">
                      <Thead className="mc-table-head primary">
                        <Tr>
                          <Th>Course</Th>
                          <Th>Certificate</Th>
                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody className="mc-table-body even">
                        {data?.length > 0 &&
                          data.map((item, key) => (
                            <Tr key={key}>
                              <Td>{item.module.title}</Td>
                              <Td>
                                <Anchor target={"_blank"} href={"/certificate?id=" + item._id} title="Download certificate" className="edit">
                                  <i class="material-icons">download</i>
                                </Anchor>
                              </Td>

                              <Td>
                                <Box className="mc-table-action">
                                  <Anchor href={"/course-study-video?id=" + item.module.slug} title="Watch Video" className="view">
                                    Watch Video
                                  </Anchor>
                                  <Anchor href={"/course-study-pdf?id=" + item.module.slug} title="Read PDF" className="edit">
                                    Read PDF
                                  </Anchor>
                                  <Anchor href={"/course-study-exam?id=" + item.module.slug} title="Read PDF" className="bg-dark">
                                    Give Exam
                                  </Anchor>
                                </Box>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </Box>
                </Col>
              </div>
            </div>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
