import React from "react";
import PageLayout from "../../layouts/PageLayout2";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import toast from "react-hot-toast";

export default function Category() {
  const navigate = useNavigate();

  const { userData, token, loginStatus } = useSelector((state) => state.counter);
  const [purchased, setPurchased] = useState(false);
  const [cms, setCMS] = useState([]);

  // Calling Load data On Run Time
  useEffect(() => {
    LoadData();
  }, []);

  // Load All data To Display In Table
  function LoadData() {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);

    const params = {
      slug: urlParams.get("id"),
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    axios.get(process.env.REACT_APP_API_URL + "app/courses/detail?" + queryString).then(
      (response) => {
        AlreadyPurchased(response.data.module._id);
        setCMS(response.data.module);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  function AlreadyPurchased(id) {
    {
      userData &&
        userData._id &&
        axios.get(process.env.REACT_APP_API_URL + "app/courses/purchased?user=" + userData._id + "&course=" + id).then(
          (response) => {
            setPurchased(response.data.purchased);
          },
          (error) => {
            if (error.response.status == 401) {
              console.log("Error");
            }
          }
        );
    }
  }

  return (
    <PageLayout>
      <Row className="mt-5">
        <Col xl={12} className="mt-5">
          <section className="course-details rounded-top">
            <div className="container">
              <div className="row">
                <div className="course-header">
                  <div className="course-basic-info">
                    <div className="course-title">{cms && cms.title}</div>
                    <div className="sub-text text-white">{cms && cms.category && cms.category.name}</div>
                    <div className="sub-des">{cms && cms.short_desc}</div>
                    <div className="course-info"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-8">
                  <div className="details-left-part">
                    <div className="course-details-card arrow-points">
                      <div className="course-sub-detail-title">Course content</div>
                      <div className="feature-list" dangerouslySetInnerHTML={{ __html: cms.desc }} />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="sidebar">
                    <div className="details-right-part ">
                      <div className="details-img ">
                        <img
                          className="w-1/1 img-fluid"
                          src={process.env.REACT_APP_IMG_URL + "content/" + cms.thumb}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="details-content">
                        <div className="details-price h3">${Number(cms && cms.price).toFixed()}</div>

                        {loginStatus ? (
                          <>
                            {purchased ? (
                              <button type="button" className="btn btn-primary w-100">
                                Already purchased
                              </button>
                            ) : (
                              <>
                                <hr />
                                <div className="p-2 text-center">Make payment</div>
                                <PayPalButton
                                  amount={Number(cms.price).toFixed(2)}
                                  clientId={process.env.REACT_APP_PAYPAL_CLIENT}
                                  // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                  onSuccess={async (details, data) => {
                                    toast.success("Transaction completed");

                                    // OPTIONAL: Call your server to save the transaction
                                    await fetch(process.env.REACT_APP_API_URL + "app/courses/payment?txn=" + data.orderID + "&user=" + userData._id + "&amount=" + Number(cms.price).toFixed(2) + "&id=" + cms._id, {
                                      method: "GET",
                                      headers: { authorization: token, "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
                                    });
                                    navigate("/all-course");

                                    return true;
                                  }}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <a href="/register?type=normal" className="btn btn-primary w-100">
                            Register to purchase
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </PageLayout>
  );
}
