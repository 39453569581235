import React, { useEffect, useState, useRef } from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setChatData } from "../../actions";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch } from "react-redux";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import toast from "react-hot-toast";
import Pagination from "react-js-pagination";
import ReactPlayer from "react-player";
import { AllCheckerCheckbox, Checkbox, CheckboxGroup } from "@createnl/grouped-checkboxes";
export default function ProductList({ hide }) {
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const { reportData, chat, token, physicalSensation, mentalSensation, selectedAtsTab } = useSelector((state) => state.counter);
  const [selectedTab, setSelectedTab] = useState("HealthRecord");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const [message, setMessage] = useState("");
  const [segment, setSegment] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [muscle, setMuscle] = useState("");

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);

  const [filterName, setFilterName] = useState("");
  const [filterUrl, setFilterUrl] = useState("");

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState({});
  const [selectedZone, setSelectedZone] = useState("");
  const [ids, setIds] = useState([]);
  const [recommendations, setRecommendations] = useState([]);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (chat?.muscle) {
      setSelectedZone(chat.muscle);
    }
  }, [chat]);

  useEffect(() => {
    if (chat && chat.muscle && chat.muscle.trim() != "") {
      getMessages(chat.selectedAppointment, chat.segment, chat.muscle);
      setSegment(chat.segment);
      setMuscle(chat.muscle);
      setAppointmentId(chat.selectedAppointment);
    }
  }, [chat]);

  const getMessages = async (appointment, segment, muscle) => {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/recommendations/list?segment=" + segment + "&appointment=" + appointment + "&muscle=" + muscle, options);
    if (Response.data.success) {
      setData(Response.data.result);
    }
  };

  useEffect(() => {
    // Scroll to the bottom when component mounts or when data changes
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [data]);

  const sendMessages = async () => {
    if (message.trim() == "") {
      return toast.error("Enter message");
    }
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/recommendations/add/",
      qs.stringify({
        repors: appointmentId,
        segment: segment,
        by: "practitioner",
        message: message,
        muscle: muscle,
      }),
      options
    );
    if (Response.data.status == "success") {
      setMessage("");
      getMessages(appointmentId, segment, muscle);
    } else {
      toast.error(Response.data.message);
    }
  };

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: 1000,
      name: filterName,
      muscle: selectedZone,
      url: filterUrl,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/exercise/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData2(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  function findObjectsById(array, id) {
    const result = [];

    array.forEach((obj) => {
      if (obj._id === id) {
        result.push(obj);
      }
    });

    return result[0];
  }

  const onCheckboxChange = (checkboxes) => {
    var newarray = [];
    const values = checkboxes.filter((item) => item.checked).map((item) => item.value);

    for (let i = 0; i < values.length; i++) {
      newarray.push({
        id: values[i],
        name: findObjectsById(data2, values[i]).name,
        reps: "",
        sets: "",
        weight: "",
        timeRest: "",
        url: findObjectsById(data2, values[i]).url,
      });
    }

    setIds(newarray);
  };

  const updateObjectsById = (array, id, update) => {
    const updatedArray = array.map((obj) => {
      if (obj.id === id) {
        return { ...obj, ...update };
      }
      return obj;
    });

    setIds(updatedArray);
  };

  function sendRecomendation() {
    var msg = "";
    for (let i = 0; i < ids.length; i++) {
      msg = msg + "Exercise name: " + ids[i].name + " \n";
      msg = msg + "Reps: " + ids[i].reps + " \n";
      msg = msg + "Sets: " + ids[i].sets + " \n";
      msg = msg + "Weight: " + ids[i].weight + "Lbs." + " \n";
      msg = msg + "Rest time: " + ids[i].timeRest + "Sec." + " \n";
      msg = msg + "Ref video: " + ids[i].url + " \n";
      msg = msg + "-- \n";
    }
    setMessage(msg);
  }

  return (
    <>
      <div>
        <>
          <div className="card border-0">
            <div className="card-body" ref={scrollRef}>
              {data?.length > 0 &&
                data.map((item, key) => (
                  <>
                    {item.by == "practitioner" ? (
                      <div className="d-flex flex-row justify-content-start mb-4" key={key}>
                        <div className="p-3 ms-3" style={{ borderRadius: 15, backgroundColor: "rgba(57, 192, 237,.2)" }}>
                          <strong>You</strong>
                          <p className="small mb-0">
                            {item.message.split("\n").map((e) => (
                              <p>{e}</p>
                            ))}
                          </p>
                          <small>
                            <i className="text-muted">sent at {moment(item.createdAt).fromNow()} </i>
                          </small>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex flex-row justify-content-end mb-4" key={key}>
                        <div className="p-3 me-3 border" style={{ borderRadius: 15, backgroundColor: "#fbfbfb" }}>
                          <strong>User</strong>
                          <p className="small mb-0">{item.message}</p>
                          <small>
                            <i className="text-muted">sent at {moment(item.createdAt).fromNow()} </i>
                          </small>
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </div>
            {!hide && (
              <div className="card-footer border-0 bg-white">
                <div className="form-outline">
                  <textarea className="form-control" id="textAreaExample" placeholder=" Type your message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>

                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <button type="button" className="btn btn-primary w-100 mt-3" onClick={() => sendMessages()}>
                      Send
                    </button>
                    {/* <button
                    type="button"
                    className="btn btn-primary w-100 mt-3"
                    onClick={() => {
                      setShow2(true);
                      fetchData(1);
                    }}
                  >
                    Exercise Video
                  </button> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>

        <Offcanvas show={show2} onHide={handleClose2} placement={"bottom"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Exercise Video</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Row>
              <Col xl={3}>
                <div className="form-group">
                  <input placeholder="Filter by exercise name" type="text" className="form-control" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                </div>
              </Col>
              <Col xl={3}>
                <div className="form-group">
                  <input placeholder="Filter by exercise url" type="text" className="form-control" value={filterUrl} onChange={(e) => setFilterUrl(e.target.value)} />
                </div>
              </Col>
              <Col xl={3}>
                <Anchor
                  icon={"search"}
                  text={"Search"}
                  href={"#"}
                  className="mc-btn primary w-100"
                  onClick={() => {
                    setActivePage(1);
                    fetchData(1);
                  }}
                />
              </Col>{" "}
              <Col xl={3}>
                <Anchor
                  className="mc-btn primary w-100"
                  onClick={() => {
                    setShow4(true);
                  }}
                >
                  Assign
                </Anchor>
              </Col>
              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <CheckboxGroup onChange={onCheckboxChange}>
                    <Table className="mc-table">
                      <Thead className="mc-table-head primary">
                        <Tr>
                          <Th>
                            <AllCheckerCheckbox />
                          </Th>
                          <Th>Name</Th>
                          <Th>URL</Th>

                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody className="mc-table-body even">
                        {data2?.length > 0 &&
                          data2.map((item, key) => (
                            <Tr key={key}>
                              <td>
                                <Checkbox value={item._id} />
                              </td>
                              <Td>{item.name}</Td>
                              <Td>{item.url} </Td>

                              <Td>
                                <Box className="mc-table-action">
                                  <Button
                                    title="Delete"
                                    className="view p-3"
                                    onClick={() => {
                                      setShow3(true);
                                      setData3(item);
                                    }}
                                  >
                                    View
                                  </Button>
                                </Box>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </CheckboxGroup>
                </Box>
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas show={show3} onHide={handleClose3} placement={"bottom"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Exercise Details</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <strong>Exercises:</strong>{" "}
                    </div>
                    <div className="col-md-7">{data3.name} </div>

                    <div className="col-md-5">
                      <strong>Anatomy of Zones:</strong>{" "}
                    </div>
                    <div className="col-md-7">{data3.anatomy_of_zones} </div>

                    <div className="col-md-5">
                      <strong>Inside the Zones - Posterior:</strong>{" "}
                    </div>
                    <div className="col-md-7">{data3.posterior} </div>

                    <div className="col-md-5">
                      <strong>Inside the Zones-Anterior:</strong>{" "}
                    </div>
                    <div className="col-md-7">{data3.anterior} </div>

                    <div className="col-md-5">
                      <strong>Synergists:</strong>{" "}
                    </div>
                    <div className="col-md-7">{data3.synergists} </div>

                    <div className="col-md-5">
                      {" "}
                      <strong>Antagonists:</strong>
                    </div>
                    <div className="col-md-7">{data3.antagonists} </div>

                    <div className="col-md-5">
                      <strong>Stabilizers:</strong>
                    </div>
                    <div className="col-md-7">{data3.stabilizers} </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <ReactPlayer url={data3.url} width={550} className="rounded" />
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas show={show4} onHide={handleClose4} placement={"bottom"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Recommendations</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="container">
              <Table className="mc-table">
                <Thead className="mc-table-head">
                  <Tr>
                    <Th>Exercise Name</Th>
                    <Th>Reps</Th>
                    <Th>Sets</Th>
                    <Th>Weight</Th>
                    <Th>Time Rest</Th>
                  </Tr>
                </Thead>
                <Tbody className="mc-table-body even">
                  {ids?.length > 0 &&
                    ids.map((item, key) => (
                      <Tr key={key}>
                        <Td>{item.name}</Td>
                        <Td>
                          <select className="form-select" defaultValue={item.reps} onChange={(e) => updateObjectsById(ids, item.id, { reps: e.target.value })}>
                            <option value=""></option>
                            <option value="6">6</option>
                            <option value="8">8</option>
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="16">16</option>
                            <option value="20">20</option>
                          </select>
                        </Td>
                        <Td>
                          <select className="form-select" defaultValue={item.sets} onChange={(e) => updateObjectsById(ids, item.id, { sets: e.target.value })}>
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </select>
                        </Td>
                        <Td>
                          <div className="row">
                            <div className="col-md-8">
                              <input type="number" className="form-control" defaultValue={item.weight} onChange={(e) => updateObjectsById(ids, item.id, { weight: e.target.value })} />
                            </div>
                            <div className="col-md-4 h4">lbs.</div>
                          </div>
                        </Td>
                        <Td>
                          <select className="form-select" defaultValue={item.timeRest} onChange={(e) => updateObjectsById(ids, item.id, { timeRest: e.target.value })}>
                            <option value=""></option>
                            <option value="10">10 sec.</option>
                            <option value="20">20 sec.</option>
                            <option value="30">30 sec.</option>
                            <option value="40">40 sec.</option>
                            <option value="50">50 sec.</option>
                            <option value="60">60 sec.</option>
                          </select>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>

              <div className="text-center">
                <Anchor
                  className="mc-btn primary mt-3"
                  onClick={() => {
                    setShow4(false);
                    setShow2(false);
                    sendRecomendation();
                  }}
                >
                  Send now
                </Anchor>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}
