import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
export default function ProductList() {
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const { token } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  const [anatomy_of_zones, setAnatomyOfZones] = useState("");
  const [posterior, setPosterior] = useState("");
  const [anterior, setAnterior] = useState("");
  const [synergists, setSynergists] = useState("");
  const [antagonists, setAntagonists] = useState("");
  const [stabilizers, setStabilizers] = useState("");
  const [muscle, setMuscle] = useState("");

  const [filterName, setFilterName] = useState("");
  const [filterUrl, setFilterUrl] = useState("");

  const [mode, setMode] = useState("add");
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");

  async function handlepatient() {
    if (Saving) {
      return;
    }

    if (!name && name == "") {
      toast.error("Enter client name");
      return;
    }
    if (!url && url == "") {
      toast.error("Enter client name");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/exercise/add",
        {
          name: name,
          url: url,
          anatomy_of_zones: anatomy_of_zones,
          posterior: posterior,
          anterior: anterior,
          synergists: synergists,
          antagonists: antagonists,
          stabilizers: stabilizers,
          muscle: muscle,
        },
        options
      );
      if (Response.data.status == "success") {
        setUrl("");
        setName("");

        setAnatomyOfZones("");
        setPosterior("");
        setAnterior("");
        setSynergists("");
        setAntagonists("");
        setStabilizers("");
        setMuscle("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    } else {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/exercise/update/" + id,
        {
          name: name,
          url: url,
          anatomy_of_zones: anatomy_of_zones,
          posterior: posterior,
          anterior: anterior,
          synergists: synergists,
          antagonists: antagonists,
          stabilizers: stabilizers,
          muscle: muscle,
        },
        options
      );
      if (Response.data.status == "success") {
        setUrl("");
        setName("");

        setAnatomyOfZones("");
        setPosterior("");
        setAnterior("");
        setSynergists("");
        setAntagonists("");
        setStabilizers("");
        setMuscle("");
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    }
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      name: filterName,
      url: filterUrl,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/exercise/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/exercise/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  // useEffect(() => {
  //   console.log(activities);
  // }, [activities]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Manage exercise video"}>
              {window.location.hostname == "admin.atsdhst.com" && (
                <>
                  <li className="mc-breadcrumb-item">
                    <Anchor
                      icon={"add"}
                      text={"Add exercise video"}
                      href={"#"}
                      className="mc-btn primary"
                      onClick={() => {
                        setShow(true);
                        setMode("add");
                        setUrl("");
                        setName("");
                        setAnatomyOfZones("");
                        setPosterior("");
                        setAnterior("");
                        setSynergists("");
                        setAntagonists("");
                        setStabilizers("");
                      }}
                    />
                  </li>
                </>
              )}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={3}>
                <div className="form-group">
                  <input placeholder="Filter by exercise name" type="text" className="form-control" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                </div>
              </Col>

              {window.location.hostname == "admin.atsdhst.com" && (
                <>
                  <Col xl={3}>
                    <div className="form-group">
                      <input placeholder="Filter by exercise url" type="text" className="form-control" value={filterUrl} onChange={(e) => setFilterUrl(e.target.value)} />
                    </div>
                  </Col>
                </>
              )}

              <Col xl={3}>
                <Anchor
                  icon={"search"}
                  text={"Search"}
                  href={"#"}
                  className="mc-btn primary w-100"
                  onClick={() => {
                    setActivePage(1);
                    fetchData(1);
                  }}
                />
              </Col>

              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Name</Th>

                        {window.location.hostname == "admin.atsdhst.com" && (
                          <>
                            <Th>URL</Th>
                          </>
                        )}

                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <Tr key={key}>
                            <Td>{item.name}</Td>
                            {window.location.hostname == "admin.atsdhst.com" && (
                              <>
                                <Td>{item.url} </Td>
                              </>
                            )}

                            <Td>
                              <Box className="mc-table-action">
                                <Button
                                  href="#"
                                  title="View"
                                  className="edit p-3"
                                  onClick={() => {
                                    setMode("view");
                                    setName(item.name);
                                    setUrl(item.url);
                                    setAnatomyOfZones(item.anatomy_of_zones);
                                    setPosterior(item.posterior);
                                    setAnterior(item.anterior);
                                    setSynergists(item.synergists);
                                    setAntagonists(item.antagonists);
                                    setStabilizers(item.stabilizers);
                                    setShow2(true);
                                    setMuscle(item.muscle);
                                  }}
                                >
                                  View
                                </Button>

                                {window.location.hostname == "admin.atsdhst.com" && (
                                  <>
                                    <Button
                                      href="#"
                                      title="Edit"
                                      className="edit p-3"
                                      onClick={() => {
                                        setMode("edit");
                                        setName(item.name);
                                        setUrl(item.url);

                                        setAnatomyOfZones(item.anatomy_of_zones);
                                        setPosterior(item.posterior);
                                        setAnterior(item.anterior);
                                        setSynergists(item.synergists);
                                        setAntagonists(item.antagonists);
                                        setStabilizers(item.stabilizers);
                                        setMuscle(item.muscle);
                                        setId(item._id);
                                        setShow(true);
                                      }}
                                    >
                                      Edit
                                    </Button>

                                    <Button
                                      title="Delete"
                                      className="delete p-3"
                                      onClick={() => {
                                        setId(item._id);
                                        setShowSwal(true);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </>
                                )}
                              </Box>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} exercise video</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <LabelField label="name" type="text" fieldSize="w-100 h-md" value={name} onChange={(e) => setName(e.target.value)} />
            </Col>
            <Col xl={12}>
              <LabelField label="name" type="text" fieldSize="w-100 h-md" value={muscle} onChange={(e) => setMuscle(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Video URL" type="url" fieldSize="w-100 h-md" value={url} onChange={(e) => setUrl(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Anatomy of Zones" type="url" fieldSize="w-100 h-md" value={anatomy_of_zones} onChange={(e) => setAnatomyOfZones(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Posterior" type="url" fieldSize="w-100 h-md" value={posterior} onChange={(e) => setPosterior(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Anterior" type="url" fieldSize="w-100 h-md" value={anterior} onChange={(e) => setAnterior(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Synergists" type="url" fieldSize="w-100 h-md" value={synergists} onChange={(e) => setSynergists(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Antagonists" type="url" fieldSize="w-100 h-md" value={antagonists} onChange={(e) => setAntagonists(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Stabilizers" type="url" fieldSize="w-100 h-md" value={stabilizers} onChange={(e) => setStabilizers(e.target.value)} />
            </Col>
          </Row>

          <Button className="mc-btn primary w-100 mt-3" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handlepatient()}>
            {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
          </Button>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Exercise detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="table-responsive">
            {" "}
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>: {name}</td>
                </tr>

                <tr>
                  <td>Muscle</td>
                  <td>: {muscle}</td>
                </tr>

                <tr>
                  <td>Anatomy of Zones</td>
                  <td>: {anatomy_of_zones}</td>
                </tr>
                <tr>
                  <td>Posterior</td>
                  <td>: {posterior}</td>
                </tr>
                <tr>
                  <td>Anterior</td>
                  <td>: {anterior}</td>
                </tr>
                <tr>
                  <td>Synergists</td>
                  <td>: {synergists}</td>
                </tr>
                <tr>
                  <td>Antagonists</td>
                  <td>: {antagonists}</td>
                </tr>
                <tr>
                  <td>Stabilizers</td>
                  <td>: {stabilizers}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This data will be permanently deleted.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
