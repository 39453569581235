import React from "react";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import BreakModal from "./BreaksModal";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

export default function Alerts() {
  const days = ["Sun", "Mon", "Tues", "Wed", "Thrus", "Fri", "Sat"];
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showBreaksModal, setShowBreaksModal] = useState(false);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(0);
  const [businessHours, setBusinessHours] = useState([]);

  useEffect(() => {
    // Fetch and load business hours from the server
    fetchBusinessHours();
  }, []);

  const fetchBusinessHours = () => {
    const options = {
      headers: { authorization: token },
    };
    axios
      .get(process.env.REACT_APP_API_URL + "app/business-hours?user=" + userData._id, options)
      .then((response) => {
        setBusinessHours(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching business hours:", error);
      });
  };

  const handleSave = () => {
    const options = {
      headers: { authorization: token },
    };
    axios
      .post(process.env.REACT_APP_API_URL + "app/business-hours/update", { hours: businessHours }, options)
      .then((response) => {
        toast.success("Business hours saved");
      })
      .catch((error) => {
        toast.error("Error saving business hours:", error.message);
      });
  };

  const handleSaveBreaks = (dayOfWeek, breaks) => {
    // Log the breaks for debugging purposes

    try {
      // Update the business hours data with the new breaks
      const updatedBusinessHours = businessHours.map((hour) => (hour.dayOfWeek === dayOfWeek ? { ...hour, breakTimes: breaks } : hour));
      // Set the updated business hours in the state
      setBusinessHours(updatedBusinessHours);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <Row className="mt-3">
        <Col xl={12} className="ps-xl-5">
          <Row>
            <Col xl={12}>
              <div className="mc-table-responsive">
                <Table className="mc-table">
                  <Thead className="mc-table-head primary">
                    <tr>
                      <th>Day of the week</th>
                      <th>Is Open</th>
                      <th>Open Time</th>
                      <th>Close Time</th>
                      {/* <th>Break Times</th> */}
                    </tr>
                  </Thead>
                  <Tbody className="mc-table-body even">
                    {businessHours.map((hours, index) => (
                      <tr key={index}>
                        <td>{days[index]}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={hours.isOpen}
                            onChange={() => {
                              const updatedHours = [...businessHours];
                              updatedHours[index].isOpen = !updatedHours[index].isOpen;
                              setBusinessHours(updatedHours);
                            }}
                          />
                        </td>
                        <td>
                          <DatePicker
                            selected={new Date("2011/11/30 " + hours.openTime)}
                            onChange={(date) => {
                              const updatedHours = [...businessHours];
                              updatedHours[index].openTime = date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
                              setBusinessHours(updatedHours);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </td>
                        <td>
                          <DatePicker
                            selected={new Date("2011/11/30 " + hours.closeTime)}
                            onChange={(date) => {
                              const updatedHours = [...businessHours];
                              updatedHours[index].closeTime = date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
                              setBusinessHours(updatedHours);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </td>
                        {/* <td>
                          <Button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              setShowBreaksModal(true);
                              setSelectedDayOfWeek(index);
                            }}
                          >
                            Manage Breaks
                          </Button>
                          <BreakModal show={showBreaksModal} onHide={() => setShowBreaksModal(false)} dayOfWeek={selectedDayOfWeek} breaks={businessHours.find((hour) => hour.dayOfWeek === selectedDayOfWeek)?.breakTimes || []} onSave={handleSaveBreaks} />
                        </td> */}
                      </tr>
                    ))}
                  </Tbody>
                </Table>

                <div className="text-center mt-4">
                  <button className="btn btn-primary" onClick={handleSave} type="button">
                    Save Business Hours
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
