import React from "react";
import { Breadcrumb, ContentData } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { CardLayout } from "./../../components/cards";
import { Anchor, Box, Button } from "./../../components/elements";
import { Col, Row, Table, Modal, ModalHeader, ModalBody, Spinner, closeButton } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import SweetAlert from "react-bootstrap-sweetalert";

export default function Category() {
  const navigate = useNavigate();

  const { userData, token, selectedCompany, refreshToken } = useSelector((state) => state.counter);
  const [PerPage, setPerpage] = useState(25);
  const [TotalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [standalone, setStandalone] = useState("");
  const [bundle, setBundle] = useState("");
  const [isContentOpen, setContentOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState("");

  // Calling Load data On Run Time
  useEffect(() => {
    LoadData(1);
  }, []);

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    LoadData(pageNumber);
  }

  // Filter The Result
  function doFilter() {
    LoadData(1);
  }

  function doReset() {
    setStandalone("");
    setBundle("");
    setFilterName("");
    LoadData(1, true);
    setIsFilterOpen(false);
  }

  // Load All data To Display In Table
  function LoadData(pageNumber, reset = false) {
    const params = {
      page: PerPage * (pageNumber - 1),
      limit: PerPage,
      name: !reset ? filterName : "",
      standalone: !reset ? standalone : "",
      bundle: !reset ? bundle : "",
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    const options = {
      headers: { authorization: token },
    };

    axios.get(process.env.REACT_APP_API_URL + "app/modules/list?" + queryString, {}, options).then(
      (response) => {
        console.log("Data: ", response.data.result);
        setData(response.data.result);
        setTotalRecod(response.data.totalCount);
        setIsFilterOpen(false);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  // Soft Delete Record
  async function DeleteRecord(id) {
    const options = {
      headers: { authorization: token },
    };
    const data = qs.stringify({ id: id });

    axios.post(process.env.REACT_APP_API_URL + "app/modules/delete", data, options).then((response) => {
      if (response.data.success == true) {
        toast(response.data.message);
        LoadData(1);
      } else {
        toast("Something went wrong");
      }
    });
  }

  // Update The Status
  function UpdateStatus(id) {
    const params = {
      id: id,
    };
    const options = {
      headers: { authorization: token },
    };

    axios.post(process.env.REACT_APP_API_URL + "app/modules/status?", qs.stringify(params), options).then((response) => {
      if (response.data.success == true) {
        toast(response.data.message);
        LoadData(1);
      } else {
        toast(response.data.message);
      }
    });
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Module"}>
              <li className="mc-breadcrumb-item">
                <a class="mc-btn primary" href="/modules-add">
                  <i class="material-icons">add</i>
                  <span>Add</span>
                </a>
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <div className="table-responsive">
              <Table className="text-nowrap mt-3 align-middle" borderless>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 &&
                    data.map((item, key) => (
                      <tr className="border-top" key={key}>
                        <td>{item.title}</td>
                        <td>{item.price} </td>
                        <td>
                          {item.active === 0 ? (
                            <a href="#" onClick={() => UpdateStatus(item._id)}>
                              Inactive
                            </a>
                          ) : (
                            <a href="#" onClick={() => UpdateStatus(item._id)}>
                              Active
                            </a>
                          )}
                        </td>

                        <td>
                          <Box className="mc-table-action">
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => {
                                setContentOpen(true);
                                setSelectedModule(item._id);
                              }}
                              className={"view p-3"}
                            >
                              Content
                            </Button>
                            <Button size="sm" color="primary" onClick={() => navigate("/modules-edit?id=" + item._id)} className={"edit p-3"}>
                              Edit
                            </Button>
                            <Button title="Delete" className="delete p-3" onClick={(e) => DeleteRecord(item._id)}>
                              Delete
                            </Button>
                          </Box>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="Page navigation example">
                  {TotalRecod > PerPage ? (
                    <Pagination
                      prevPageText="prev"
                      nextPageText="next"
                      firstPageText="first"
                      lastPageText="last"
                      activePage={activePage}
                      itemsCountPerPage={PerPage}
                      totalItemsCount={TotalRecod}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                      innerClass="pagination justify-content-center"
                    />
                  ) : (
                    ""
                  )}
                </nav>
              </div>
            </div>
          </CardLayout>
        </Col>
      </Row>

      <Modal show={isFilterOpen} onHide={() => setIsFilterOpen(false)}>
        <ModalHeader closeButton>Filters</ModalHeader>
        <ModalBody>
          <div className="user"></div>
          <div className="form-group mb-3">
            <input type="text" className="form-control" onChange={(e) => setFilterName(e.target.value)} value={filterName} placeholder="Filter by meeting id" />
          </div>

          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="standalone" checked={standalone} onClick={() => (standalone ? setStandalone(false) : setStandalone(true))} />
            <label class="form-check-label" for="standalone">
              Standalone
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="bundle" checked={bundle} onClick={() => (bundle ? setBundle(false) : setBundle(true))} />
            <label class="form-check-label" for="bundle">
              Bundle
            </label>
          </div>

          <div className="form-group mt-4">
            <button type="submit" className="btn btn-primary d-block w-100 mb-3" onClick={(e) => doFilter()}>
              Filter Result
            </button>

            <button type="button" className="btn btn-secondary d-block w-100 mb-3" onClick={(e) => doReset()}>
              Reset
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal size="lg" show={isContentOpen} onHide={() => setContentOpen(false)}>
        <ModalHeader closeButton>Manage Content</ModalHeader>
        <ModalBody>
          <ContentData selectedModule={selectedModule} />
        </ModalBody>
      </Modal>
    </PageLayout>
  );
}
