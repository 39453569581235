import React from "react";
import { Row, Col } from "react-bootstrap";
import { LabelField } from "../../components/fields";
import { useState, useEffect } from "react";
import { Button } from "../../components/elements";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
export default function Alerts() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [Saving, setSaving] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  async function updateSettings() {
    if (!clientId && clientId == "") {
      toast.error("Enter paypal client id");
      return;
    }

    if (!clientSecret && clientSecret == "") {
      toast.error("Enter paypal client secret");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/practitioner-paypal/update",
      {
        clientId: clientId,
        clientSecret: clientSecret,
        id: userData._id,
      },
      options
    );
    if (Response.data.status == "success") {
      setSaving(false);
      toast.success(Response.data.message);
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  useEffect(() => {
    fetchSettings();
  }, []);

  async function fetchSettings() {
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/practitioner-paypal?user=" + userData._id, options);
    if (Response && Response.data.status == "success") {
      if (Response.data.result) {
        setClientId(Response.data.result.clientId);
        setClientSecret(Response.data.result.clientSecret);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <>
      <Row className="mt-3">
        <Col xl={12} className="ps-xl-5">
          <Row>
            <Col xl={12}>
              <LabelField label="Paypal client id" type="clientId" fieldSize="w-100 h-md" value={clientId} onChange={(e) => setClientId(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Paypal client secret" type="clientId" fieldSize="w-100 h-md" value={clientSecret} onChange={(e) => setClientSecret(e.target.value)} />
            </Col>

            <Col xl={12}>
              <Button className="btn btn-primary w-100" type="button" disable={!Saving} onClick={() => updateSettings()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save</>}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
