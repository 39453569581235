import React from "react";
import { Box, Text, Form, Image, Anchor, Heading, Input, Label, Button } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/register.json";
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setUserData, setLoginStatus, setLoginAccountType, setToken, setRefreshToken, setCompanyList, setSelectedCompany } from "../../actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
const mapStateToProps = (state) => ({
  counter: state.counter.counter,
});

const mapDispatchToProps = {
  setUserData,
  setLoginStatus,
  setToken,
  setRefreshToken,
  setCompanyList,
  setSelectedCompany,
  setLoginAccountType,
};

const Login = ({ setUserData, setLoginStatus, setLoginAccountType, setToken, setRefreshToken, setCompanyList, setSelectedCompany }) => {
  const [disableButton, setDisableButton] = useState(true);

  const navigate = useNavigate();

  const [Subscription, setSubscription] = useState("6576f0adedb2fa4d5db7318a_month");
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [Phone, setPhone] = useState("");
  const [Saving, setSaving] = useState(false);
  const [subscriptionData, setData] = useState([]);
  const [accountType, setAccountType] = useState("");
  const [company, setCompany] = useState("");
  const [normalUser, setNormalUser] = useState(false);

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  useEffect(() => {
    fetchSubscription();
  }, []);

  useEffect(() => {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    setEmail(urlParams.get("email"));

    if (urlParams.get("type") && urlParams.get("type") == "normal") {
      setNormalUser(true);
      setAccountType(3);
    }
  }, []);

  async function fetchSubscription() {
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/subscription/all");
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function handleLogin() {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    setEmail(urlParams.get("email"));

    if (urlParams.get("type") != "normal") {
      if (!accountType && accountType == "") {
        toast.error("Select account type");
        return;
      }

      if (accountType == "1" && !company && company == "") {
        toast.error("Enter company name");
        return;
      }
    }

    if (accountType == "0" && !Fname && Fname == "") {
      toast.error("Enter your first name");
      return;
    }
    if (accountType == "0" && !Lname && Lname == "") {
      toast.error("Enter your last name");
      return;
    }

    if (accountType == "1" && !Fname && Fname == "") {
      toast.error("Enter your first name");
      return;
    }
    if (accountType == "1" && !Lname && Lname == "") {
      toast.error("Enter your last name");
      return;
    }

    if (!Email && Email == "") {
      toast.error("Enter email address");
      return;
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (Email && !Email.match(mailformat)) {
      toast.error("Enter valid email address");
      return;
    }

    if (!Phone && Phone == "") {
      toast.error("Enter phone");
      return;
    }

    if (!Password && Password == "") {
      toast.error("Enter password");
      return;
    }

    if (!checkPassword(Password)) {
      toast.error("Password must have atleast min 8 letter, one special character, one number and one capital letter");
      return;
    }

    if (!ConfirmPassword && ConfirmPassword == "") {
      toast.error("Enter confirm password");
      return;
    }

    if (ConfirmPassword != Password) {
      toast.error("Password and confirm password must be same");
      return;
    }
    if (disableButton) {
      toast.error("Please accept terms & conditions");
      return;
    }

    setSaving(true);
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/register", {
      fname: Fname,
      lname: Lname,
      email: Email,
      phone: Phone,
      password: Password,
      subscription: Subscription,
      accountType: accountType,
      company: company,
    });
    if (Response.data.success == true) {
      setUserData(Response.data.data);
      setLoginStatus(true);
      setToken(Response.data.token);
      setRefreshToken(Response.data.refreshtoken);
      setSaving(false);

      setCompanyList(Response.data.company);
      setSelectedCompany(Response.data.company[0]);

      toast.success(Response.data.message);

      if (accountType == 3) {
        setLoginAccountType(3);
        navigate("/crm");
      } else {
        navigate("/subscribe");
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <Box className="mc-register">
      <Box className="mc-register-banner">
        <Image className="mc-register-banner-pattern" src={data?.pattern.src} alt={data?.pattern.alt} />

        <Box className="mc-register-banner-content">
          <Logo src={data?.logo.src} alt={data?.logo.alt} href={data?.logo.path} className="mc-auth-logo" />
          <Heading as="h2" className="mc-register-banner-title text-white">
            {data?.title.banner}
          </Heading>
          <Text as="p" className="mc-register-banner-descrip text-white">
            {data?.descrip}
          </Text>
          <Anchor icon={data?.anchor.icon} text={data?.anchor.text} href={"/"} className="mc-btn primary" />
        </Box>
      </Box>
      <Form className="mc-register-form">
        <Heading as="h4" className="mc-auth-title mt-5">
          {data?.title.from}
        </Heading>

        {!normalUser && (
          <div class="mc-icon-field h-sm">
            <i class="material-icons">highlight</i>
            <select onChange={(e) => setAccountType(e.target.value)} value={accountType}>
              <option value={""}> Select account type </option>
              <option value={"0"}> Individual practitioner </option>
              {/* {<option value={"1"}> Company </option>} */}
            </select>
          </div>
        )}

        {accountType == "0" ? (
          <div className="row">
            <div className="col-md-6">
              <IconField icon={"account_circle"} type={"text"} classes={"h-sm"} placeholder={"First name"} onChange={(e) => setFname(e.target.value)} value={Fname} />
            </div>
            <div className="col-md-6">
              <IconField icon={"account_circle"} type={"text"} classes={"h-sm"} placeholder={"Last name"} onChange={(e) => setLname(e.target.value)} value={Lname} />
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6">
                <IconField icon={"account_circle"} type={"text"} classes={"h-sm"} placeholder={"First name"} onChange={(e) => setFname(e.target.value)} value={Fname} />
              </div>
              <div className="col-md-6">
                <IconField icon={"account_circle"} type={"text"} classes={"h-sm"} placeholder={"Last name"} onChange={(e) => setLname(e.target.value)} value={Lname} />
              </div>
            </div>

            {!normalUser && (
              <div className="col-md-12">
                <IconField icon={"account_circle"} type={"text"} classes={"h-sm"} placeholder={"Company name"} onChange={(e) => setCompany(e.target.value)} value={company} />
              </div>
            )}
          </>
        )}

        <IconField icon={"email"} type={"email"} classes={"h-sm"} placeholder={"Email"} onChange={(e) => setEmail(e.target.value)} value={Email} />
        <IconField icon={"phone"} type={"tel"} classes={"h-sm"} placeholder={"Phone"} onChange={(e) => setPhone(e.target.value)} value={Phone} />
        <IconField icon={"lock"} type={"password"} classes={"h-sm"} placeholder={"Password"} passwordVisible={true} onChange={(e) => setPassword(e.target.value)} value={Password} help={true} />

        <IconField icon={"verified_user"} type={"password"} classes={"h-sm"} placeholder={"Repeat password"} passwordVisible={true} onChange={(e) => setConfirmPassword(e.target.value)} value={ConfirmPassword} />

        <Box className="mc-auth-checkbox">
          <Input type="checkbox" id="checkbox" onClick={() => (disableButton ? setDisableButton(false) : setDisableButton(true))} />
          <Label text={data?.checkLabel} htmlFor="checkbox" />
        </Box>

        <Button className={`mc-auth-btn h-sm`} type={"button"} disable={!Saving} onClick={() => handleLogin()}>
          {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>sign up</>}
        </Button>

        <Box className="mc-register-navigate">
          <Text as="span">{data?.navigate.title}</Text>
          <Anchor href="/">{data?.navigate.text}</Anchor>
        </Box>
      </Form>
    </Box>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
