import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import moment from "moment";
import { FiLogIn } from "react-icons/fi";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import qs from "qs";
import { FaRegTrashAlt } from "react-icons/fa";

export default function ProductList() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(50);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [showSwal, setShowSwal] = useState(false);
  const [id, setId] = useState("");
  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      type: 0,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/user/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };
    const data = qs.stringify({ id: id });
    try {
      axios.post(process.env.REACT_APP_API_URL + "app/user/delete/" + id, data, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          setShowSwal(false);
          fetchData(1);
        } else {
          toast("Something went wrong");
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Practitioners"}></Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Phone</Th>
                        <Th>Plan</Th>
                        <Th>Exp.Date</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <Tr key={key}>
                            <Td>
                              {item.fname} {item.lname}
                            </Td>
                            <Td>{item.email}</Td> <Td>{item.phone}</Td>
                            <Td>{item.subscription && item.subscription.title}</Td>
                            <Td>
                              {item.planActive == 0 && (
                                <>{item.planStartTimestamp == 0 && item.planActive == 0 && moment().format("X") > item.trialExpTimestamp ? <span className="p-1 rounded bg-danger text-white">Trial Expired</span> : <span className="p-1 rounded bg-success text-white">In Trial</span>}</>
                              )}
                              {item && item.planActive == 1 && <>{item && item.planActive == 1 && moment().format("X") > item.planExpTimestamp ? <span className="p-1  rounded bg-danger text-white">Expired</span> : <span className="p-1 rounded bg-success text-white">Active</span>}</>}
                            </Td>
                            <Td>
                              <a
                                href="#"
                                onClick={() => {
                                  window.open("https://account.atsdhst.com/autologin?id=" + item._id + "&token=" + token);
                                }}
                              >
                                <FiLogIn size={25} title="Login" color="blue" />
                              </a>
                              <Button
                                color="danger"
                                onClick={() => {
                                  setId(item._id);
                                  setShowSwal(true);
                                }}
                                className="delete p-3"
                              >
                                <FaRegTrashAlt size={25} title="Delete" color="red" />
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>{" "}
      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This data will be permanently deleted.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
