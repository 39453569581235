import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
//import moment from "moment";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

export default function ProductList() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);

  const [date, setDate] = useState(new Date());
  const [event, setEvent] = useState("");

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [fullDay, setFullDay] = useState(false);

  const [mode, setMode] = useState("add");
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");

  function parseTimeStringToDateTime(timeString, dateString) {
    //moment(dateString + " " + timeString).format("X");
    return moment.tz(dateString + " " + timeString, "Asia/Kolkata").unix();
  }
  function convertTimestampToAMPM(timestamp) {
    // Create a new Date object in UTC
    var date = new Date(timestamp);
    var utcHours = date.getUTCHours();
    var utcMinutes = date.getUTCMinutes();
    var utcSeconds = date.getUTCSeconds();

    // Convert to AM/PM format
    var ampm = utcHours >= 12 ? "PM" : "AM";

    // Adjust hours for AM/PM format
    utcHours = utcHours % 12;
    utcHours = utcHours ? utcHours : 12; // The hour '0' should be '12' in AM/PM

    // Add leading zeros to minutes and seconds
    utcMinutes = utcMinutes < 10 ? "0" + utcMinutes : utcMinutes;
    utcSeconds = utcSeconds < 10 ? "0" + utcSeconds : utcSeconds;

    // Create a formatted string
    var formattedTime = utcHours + ":" + utcMinutes + " " + ampm;

    return formattedTime;
  }

  function formatDate(inputDate) {
    // Create a new Date object
    var date = new Date(inputDate);

    // Get the individual components of the date
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    var day = ("0" + date.getDate()).slice(-2);

    // Create the formatted date string
    var formattedDate = year + "-" + month + "-" + day;

    return formattedDate;
  }

  async function handleFAQ() {
    if (!date && date == "") {
      toast.error("Enter special off date");
      return;
    }

    if (!event && event == "") {
      toast.error("Enter special off reason");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (!fullDay) {
      var start = parseTimeStringToDateTime(startTime, moment(date).format("YYYY-MM-DD"));
      var end = parseTimeStringToDateTime(endTime, moment(date).format("YYYY-MM-DD"));
    } else {
      var start = 0;
      var end = 0;
    }

    if (mode == "add") {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/holiday/add",
        {
          user: userData._id,
          date: formatDate(date),
          event: event,
          startTime: start,
          endTime: end,
          fullDay: fullDay,
          dateUnix: moment(date).format("X"),
        },
        options
      );
      if (Response.data.status == "success") {
        setEvent("");
        setDate("");

        setStartTime("");
        setEndTime("");
        setFullDay(false);
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    } else {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/holiday/update/" + id,
        {
          date: formatDate(date),
          event: event,
          startTime: start,
          endTime: end,
          fullDay: fullDay,
          dateUnix: moment(date).format("X"),
        },
        options
      );
      if (Response.data.status == "success") {
        setEvent("");
        setDate("");
        setStartTime("");
        setEndTime("");
        setFullDay(false);
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    }
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      user: userData._id,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/holiday/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/holiday/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <>
      <Row>
        <Col xl={12} className="text-center">
          <Anchor
            text={"Add special off"}
            href={"#"}
            className="btn btn-success "
            onClick={() => {
              setShow(true);
              setDate("");
              setEvent("");
              setStartTime("");
              setEndTime("");
              setFullDay(false);
              setMode("add");
            }}
          />
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Reason</Th>
                        <Th>Date</Th>
                        <Th>Fullday</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <Tr key={key}>
                            <Td>{item.event}</Td>
                            <Td>{item.date}</Td>
                            <Td>{item.fullDay ? "Yes" : "No"}</Td>
                            <Td>
                              <Box className="mc-table-action">
                                <Anchor
                                  href="#"
                                  title="Edit"
                                  className="material-icons edit"
                                  onClick={() => {
                                    setMode("edit");
                                    setDate(new Date(item.date));
                                    setEvent(item.event);
                                    setId(item._id);
                                    setShow(true);

                                    setStartTime(convertTimestampToAMPM(item.startTime * 1000));
                                    setEndTime(convertTimestampToAMPM(item.endTime * 1000));

                                    setFullDay(item.fullDay);
                                  }}
                                >
                                  <Icon>edit</Icon>
                                </Anchor>

                                <Button
                                  title="Delete"
                                  className="material-icons delete"
                                  onClick={() => {
                                    setId(item._id);
                                    setShowSwal(true);
                                  }}
                                >
                                  <Icon>delete</Icon>
                                </Button>
                              </Box>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} special off</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title">Special off date</label>
                <DatePicker minDate={moment().toDate()} className="mc-label-field-input w-100 h-md" selected={date} onChange={(date) => setDate(date)} dateFormat="dd/MM/yyyy" />
              </div>
            </Col>

            <Col xl={12}>
              <LabelField label="special off reason" type="text" fieldSize="w-100 h-md" value={event} onChange={(e) => setEvent(e.target.value)} />
            </Col>

            <Col xl={12}>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" checked={fullDay} onClick={() => (fullDay ? setFullDay(false) : setFullDay(true))} />
                <label class="form-check-label">Whole day?</label>
              </div>
            </Col>

            {!fullDay && (
              <>
                <Col xl={12}>
                  <div class="mc-label-field-group label-col">
                    <label class="mc-label-field-title"> Start time {startTime}</label>
                    <DatePicker
                      selected={new Date("2011/11/30 " + startTime)}
                      onChange={(date) => {
                        setStartTime(date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }));
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="mc-label-field-input w-100 h-md"
                    />
                  </div>
                </Col>

                <Col xl={12}>
                  <div class="mc-label-field-group label-col">
                    <label class="mc-label-field-title"> End time</label>
                    <DatePicker
                      selected={new Date("2011/11/30 " + endTime)}
                      onChange={(date) => {
                        setEndTime(date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }));
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="mc-label-field-input w-100 h-md"
                    />
                  </div>
                </Col>
              </>
            )}

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleFAQ()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This holiday will be permanently deleted.
        </SweetAlert>
      )}
    </>
  );
}
