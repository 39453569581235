const initialState = {
  counter: 0,
  userData: {},
  role: {},
  loginStatus: false,
  token: "",
  refreshToken: "",
  saveIndicator: false,
  reportData: {
    openModal: false,
    selectedAppointment: "",
    initial: false,
    male: false,
    tab: "HealthRecord",
  },
  chat: {
    openModal: false,
    selectedAppointment: "",
    segment: "",
  },
  physicalSensation: {},
  mentalSensation: {},
  selectedAtsTab: "HealthRecord",
  admin: false,
  adminData: {},
  companyList: [],
  selectedCompany: {},
  loginType: 0,
  selectedAppointmentId: "",
};

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INCREMENT_COUNTER":
      return {
        ...state,
        counter: action.payload,
      };
    case "DECREMENT_COUNTER":
      return {
        ...state,
        counter: action.payload,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "SET_LOGIN_STATUS":
      return {
        ...state,
        loginStatus: action.payload,
      };

    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };

    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        refreshToken: action.payload,
      };

    case "SET_SAVE_INDICATOR":
      return {
        ...state,
        saveIndicator: action.payload,
      };
    case "SET_REPORT_DATA":
      return {
        ...state,
        reportData: action.payload,
      };

    case "SET_CHAT_DATA":
      return {
        ...state,
        chat: action.payload,
      };

    case "SET_PHYSICAL_DATA":
      return {
        ...state,
        physicalSensation: action.payload,
      };

    case "SET_MENTAL_DATA":
      return {
        ...state,
        mentalSensation: action.payload,
      };
    case "SET_SELECTED_ATS_TAB":
      return {
        ...state,
        selectedAtsTab: action.payload,
      };

    case "SET_ADMIN":
      return {
        ...state,
        admin: action.payload,
      };

    case "SET_ADMIN_DATA":
      return {
        ...state,
        adminData: action.payload,
      };
    case "SET_COMPANY_LIST":
      return {
        ...state,
        companyList: action.payload,
      };
    case "SET_SELECTED_COMPANY":
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case "SET_ROLE":
      return {
        ...state,
        role: action.payload,
      };

    case "SET_LOGIN_ACC_TYPE":
      return {
        ...state,
        loginType: action.payload,
      };

    case "SET_SELECTED_APP":
      return {
        ...state,
        selectedAppointmentId: action.payload,
      };

    default:
      return state;
  }
};

export default counterReducer;
