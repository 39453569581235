import React from "react";
import { Box, Form, Heading, Button, Anchor, Image } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setUserData, setLoginStatus, setToken, setRefreshToken } from "../../actions";
import { useState, useEffect } from "react";
const mapStateToProps = (state) => ({
  counter: state.counter.counter,
});

const mapDispatchToProps = {
  setUserData,
  setLoginStatus,
  setToken,
  setRefreshToken,
};

const Login = ({ setUserData, setLoginStatus, setToken, setRefreshToken }) => {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [Saving, setSaving] = useState(false);

  async function handleLogin() {
    if (!Email && Email == "") {
      toast.error("Enter email address");
      return;
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!Email.match(mailformat)) {
      toast.error("Enter valid email address");
      return;
    }
    if (!otp && otp == "") {
      toast.error("Enter OTP");
      return;
    }
    if (!Password && Password == "") {
      toast.error("Enter password");
      return;
    }
    setSaving(true);
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/set-password", {
      email: Email,
      otp: otp,
      password: Password,
    });
    if (Response.data.status == "success") {
      toast.success(Response.data.message);
      navigate("/login");
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  useEffect(() => {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    setEmail(urlParams.get("email"));
  }, []);
  return (
    <Box className="mc-auth">
      <Image src={data?.pattern.src} alt={data?.pattern.alt} className="mc-auth-pattern" />
      <Box className="mc-auth-group">
        <Logo src={data?.logo.src} alt={data?.logo.alt} href={data?.logo.path} className="mc-auth-logo" />

        <Form className="mc-auth-form">
          <IconField icon={"email"} type={"email"} classes={"w-100 h-sm"} value={Email} placeholder={"Enter your email"} onChange={(e) => setEmail(e.target.value)} />
          <IconField icon={"lock"} type={"password"} classes={"w-100 h-sm"} placeholder={"Enter OTP"} passwordVisible={true} onChange={(e) => setOtp(e.target.value)} />
          <IconField icon={"lock"} type={"password"} classes={"w-100 h-sm"} placeholder={"Enter your password"} passwordVisible={true} onChange={(e) => setPassword(e.target.value)} />

          <Button className={`mc-auth-btn ${data?.button.fieldSize}`} type={data?.button.type} disable={!Saving} onClick={() => handleLogin()}>
            {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Update</>}
          </Button>

          <Anchor className="btn btn-dark w-100" href={"/register"}>
            Create account
          </Anchor>
        </Form>
      </Box>
    </Box>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
