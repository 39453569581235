import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import qs from "qs";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
export default function ProductList() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [validity, setValidity] = useState("");

  const [yearlyPrice, setYearlyPrice] = useState("");
  const [trial, setTrial] = useState("0");
  const [patientLimit, setPatientLimit] = useState("");

  const [desc, setDesc] = useState("");
  const [mode, setMode] = useState("add");
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");

  async function handlesubscription() {
    if (!title && title == "") {
      toast.error("Enter subscription title");
      return;
    }

    if (!price && price == "") {
      toast.error("Enter subscription price");
      return;
    }

    if (!validity && validity == "") {
      toast.error("Enter subscription validity");
      return;
    }

    if (!trial && trial == "") {
      toast.error("Enter subscription trial");
      return;
    }

    if (!patientLimit && patientLimit == "") {
      toast.error("Enter subscription patient limit");
      return;
    }

    if (!desc && desc == "") {
      toast.error("Enter subscription descrption");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/subscription/add",
        {
          title: title,
          desc: desc,
          price: price,
          validity: validity,
          yearlyPrice: yearlyPrice,
          trial: trial,
          patientLimit: patientLimit,
        },
        options
      );
      if (Response.data.status == "success") {
        setTitle("");
        setDesc("");
        setPrice("");
        setValidity("");
        setYearlyPrice("");
        setTrial("");
        setPatientLimit("");
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    } else {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/subscription/update/" + id,
        {
          title: title,
          desc: desc,
          price: price,
          validity: validity,
          yearlyPrice: yearlyPrice,
          trial: trial,
          patientLimit: patientLimit,
        },
        options
      );
      if (Response.data.status == "success") {
        setTitle("");
        setPrice("");
        setValidity("");
        setDesc("");
        setYearlyPrice("");
        setTrial("");
        setPatientLimit("");
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setShow(false);
      }
    }
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/subscription/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/subscription/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  function UpdateStatus(id, status) {
    let result = window.confirm("Are you sure?");
    if (result) {
      const options = {
        headers: { authorization: token },
      };

      axios.post(process.env.REACT_APP_API_URL + "app/subscription/update/" + id, qs.stringify({ status: status }), options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
        } else {
          toast(response.data.message);
        }
      });
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Manage subscription"}>
              {/* <li className="mc-breadcrumb-item">
                <Anchor
                  icon={"add"}
                  text={"Add"}
                  href={"#"}
                  className="mc-btn primary"
                  onClick={() => {
                    setShow(true);
                    setTitle("");
                    setDesc("");
                    setPrice("");
                    setValidity("");
                    setYearlyPrice("");
                    setTrial("0");
                    setPatientLimit("");
                  }}
                />
              </li> */}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Title</Th>
                        <Th>Validity</Th>
                        <Th>Price</Th>
                        <Th>Trial</Th>
                        <Th>Active</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <Tr key={key}>
                            <Td>{item.title}</Td>
                            <Td>{item.validity} Month(s)</Td>
                            <Td>${item.price}</Td>
                            <Td>{item.trial} Day(s)</Td>
                            <Td>
                              <>
                                {item.status == 0 ? (
                                  <a href="#" onClick={() => UpdateStatus(item._id, 1)}>
                                    <FiThumbsDown size={25} title="No" color="red" />
                                  </a>
                                ) : (
                                  <a href="#" onClick={() => UpdateStatus(item._id, 0)}>
                                    <FiThumbsUp size={25} title=" Yes" color="green" />
                                  </a>
                                )}
                              </>
                            </Td>
                            <Td>
                              <Box className="mc-table-action">
                                <Anchor
                                  href="#"
                                  title="Edit"
                                  className="material-icons edit"
                                  onClick={() => {
                                    setMode("edit");
                                    setTitle(item.title);
                                    setPrice(item.price);
                                    setValidity(item.validity);
                                    setDesc(item.desc);
                                    setYearlyPrice(item.yearlyPrice);
                                    setTrial(item.trial);
                                    setPatientLimit(item.patientLimit);
                                    setId(item._id);
                                    setShow(true);
                                  }}
                                >
                                  <Icon>edit</Icon>
                                </Anchor>

                                <Button
                                  title="Delete"
                                  className="material-icons delete"
                                  onClick={() => {
                                    setId(item._id);
                                    setShowSwal(true);
                                  }}
                                >
                                  <Icon>delete</Icon>
                                </Button>
                              </Box>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} subscription</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <LabelField label="title" type="text" fieldSize="w-100 h-md" value={title} onChange={(e) => setTitle(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="validity (In months)" type="number" fieldSize="w-100 h-md" value={validity} onChange={(e) => setValidity(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="price" type="number" fieldSize="w-100 h-md" value={price} onChange={(e) => setPrice(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Yearly Price (Optional)" type="number" fieldSize="w-100 h-md" value={yearlyPrice} onChange={(e) => setYearlyPrice(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Trial (In Days)" type="number" fieldSize="w-100 h-md" value={trial} onChange={(e) => setTrial(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Patient Limit" type="number" fieldSize="w-100 h-md" value={patientLimit} onChange={(e) => setPatientLimit(e.target.value)} />
            </Col>

            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Description</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handlesubscription()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This data will be permanently deleted.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
