import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import Offcanvas from "react-bootstrap/Offcanvas";
import PageLayout from "../../layouts/PageLayout";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Icon } from "./../../components/elements";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Box, Text } from "./../../components/elements";
import Pagination from "react-js-pagination";
import { CardLayout } from "./../../components/cards";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
var moment = require("moment-timezone");
moment.tz.setDefault("America/New_York");

export default function CRM() {
  const { token, userData, selectedCompany } = useSelector((state) => state.counter);

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterAptId, setFilterAptId] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [payment, setPayment] = useState("");
  const [room, setRoom] = useState(0);
  const [roomData, setRoomData] = useState([]);
  const [mode, setMode] = useState("add");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [timeslots, setTimeSlots] = useState([]);
  const [timeslots2, setTimeSlots2] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [value, setValue] = useState("");
  const [patient, setPatient] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [dateMode, setDateMode] = useState(false);

  const handleChange2 = (event) => {
    setValue(event.target.value);
    if (event.target.value && event.target.value.length > 2) {
      setShowSuggestions(true);
    }
  };

  const handleSelect = (value) => {
    setValue(value.name);
    setPatient(value._id);
    setShowSuggestions(false);
    // You can perform any action here when an option is selected
  };

  const filteredOptions = allPatients.filter((option) => option.name.toLowerCase().includes(value.toLowerCase()));

  useEffect(() => {
    fetchData(1);
    fetchPatient();
    fetchRoom();
    if (userData.timezone != "") {
      moment.tz.setDefault(userData.timezone);
    }
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const mode = urlParams.get("mode");

    if (mode && mode != "") {
      setDateMode(true);
    }

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      startdate: filterStartDate,
      enddate: filterEndDate,
      practitioner: selectedCompany && selectedCompany._id ? "" : userData._id,
      company: selectedCompany && selectedCompany ? selectedCompany._id : "",
      id: filterAptId,
      mode: mode,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/all-appointment?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  function convertTimestampToAMPM(timestamp) {
    // Create a new Date object in UTC
    var date = new Date(timestamp);
    date.setMinutes(date.getMinutes() - 1);

    var utcHours = date.getUTCHours();
    var utcMinutes = date.getUTCMinutes();
    var utcSeconds = date.getUTCSeconds();

    // Convert to AM/PM format
    var ampm = utcHours >= 12 ? "PM" : "AM";

    // Adjust hours for AM/PM format
    utcHours = utcHours % 12;
    utcHours = utcHours ? utcHours : 12; // The hour '0' should be '12' in AM/PM

    // Add leading zeros to minutes and seconds
    utcMinutes = utcMinutes < 10 ? "0" + utcMinutes : utcMinutes;
    utcSeconds = utcSeconds < 10 ? "0" + utcSeconds : utcSeconds;

    // Create a formatted string
    var formattedTime = utcHours + ":" + utcMinutes + " " + ampm;

    return formattedTime;
  }

  const selectedDay = async () => {
    if (appointmentDate == "") {
      toast.error("Select date");
      return;
    }

    const isoDate = new Date(appointmentDate);
    const year = isoDate.getFullYear();
    const month = String(isoDate.getMonth() + 1).padStart(2, "0");
    const day = String(isoDate.getDate()).padStart(2, "0");
    const ymdFormat = `${year}-${month}-${day}`;

    setLoading1(true);
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/home/check-availability", {
      date: ymdFormat,
      practitioner: userData._id,
      time: 15,
    });

    if (Response.data.status === "success") {
      setTimeSlots(Response.data.data);
      setLoading1(false);
    } else {
      setLoading1(false);
    }
  };

  const getEndDate = async (startTime) => {
    const isoDate = new Date(appointmentDate);
    const year = isoDate.getFullYear();
    const month = String(isoDate.getMonth() + 1).padStart(2, "0");
    const day = String(isoDate.getDate()).padStart(2, "0");
    const ymdFormat = `${year}-${month}-${day}`;

    setLoading2(true);

    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/home/check-availability-end", {
      date: ymdFormat,
      practitioner: userData._id,
      time: 15,
      startTime: startTime,
    });

    if (Response.data.status === "success") {
      setTimeSlots2(Response.data.data);
      setLoading2(false);
    } else {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (appointmentDate != "") {
      selectedDay();
    }
  }, [appointmentDate]);

  function addOneMinuteToTime(timeString) {
    // Parse the time string to create a Date object
    var date = new Date("2000-01-01 " + timeString);

    // Add one minute to the date
    date.setMinutes(date.getMinutes() + 1);

    // Format the result as "hh:mm AM/PM"
    var formattedTime = date.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

    return formattedTime;
  }

  async function fetchPatient() {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      id: userData._id,
      company: selectedCompany ? selectedCompany._id : "",
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/patient/all?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setAllPatients(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  function formatDate(inputDate) {
    // Create a new Date object
    var date = new Date(inputDate);

    // Get the individual components of the date
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    var day = ("0" + date.getDate()).slice(-2);

    // Create the formatted date string
    var formattedDate = year + "-" + month + "-" + day;

    return formattedDate;
  }

  async function handleBooking() {
    if (Saving) {
      return;
    }

    if (room == 0 && roomData.length > 0) {
      toast.error("Select room");
      return;
    }

    if (!patient && patient == "") {
      toast.error("Enter patient");
      return;
    }

    if (!appointmentDate && appointmentDate == "") {
      toast.error("Enter appointment date");
      return;
    }

    if (!startTime && startTime == "") {
      toast.error("Enter appointment start time");
      return;
    }

    if (!endTime && endTime == "") {
      toast.error("Enter appointment start time");
      return;
    }

    if (!payment && payment == "") {
      toast.error("Enter appointment payment");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/appointment/book",
        {
          appointmentDate: formatDate(appointmentDate),
          startTime: addOneMinuteToTime(startTime),
          endTime: addOneMinuteToTime(endTime),
          payment: payment,
          practitioner: userData._id,
          patient: patient,
          room: room,
          company: selectedCompany ? selectedCompany._id : "",
        },
        options
      );
      if (Response.data.status == "success") {
        setAppointmentDate("");
        setStartTime("");
        setEndTime("");
        setPayment("");
        setRoom("");
        setValue("");
        setPatient("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);

        fetchData(1);
        setActivePage(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        //setShow(false);
      }
    }
  }

  async function fetchRoom() {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      id: userData._id,
      company: selectedCompany ? selectedCompany._id : "",
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/room/all?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setRoomData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Appointments"}>
              <Button icon={"today"} text={"Add appointment"} href={"#"} className="mc-btn primary w-100" onClick={() => setShow(true)} />
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            {!dateMode && (
              <>
                <Row>
                  <Col xl={3}>
                    <div className="form-group">
                      <label>#Appointment / client name</label>
                      <input type="text" className="form-control" value={filterAptId} onChange={(e) => setFilterAptId(e.target.value)} />
                    </div>
                  </Col>

                  <Col xl={3}>
                    <div className="form-group">
                      <label>Start date</label>
                      <DatePicker showMonthDropdown showYearDropdown dropdownMode="select" placeholderText="Start date" className="mc-label-field-input w-100 h-md" selected={filterStartDate} onChange={(date) => setFilterStartDate(date)} dateFormat="dd/MM/yyyy" />
                    </div>
                  </Col>

                  <Col xl={3}>
                    <div className="form-group">
                      <label>End date</label>
                      <DatePicker showMonthDropdown showYearDropdown dropdownMode="select" placeholderText="Start date" className="mc-label-field-input w-100 h-md" selected={filterEndDate} onChange={(date) => setFilterEndDate(date)} dateFormat="dd/MM/yyyy" />
                    </div>
                  </Col>

                  <Col xl={3}>
                    <br />
                    <Button
                      icon={"search"}
                      text={"Search"}
                      href={"#"}
                      className="mc-btn primary w-100"
                      onClick={() => {
                        setActivePage(1);
                        fetchData(1);
                      }}
                    />
                  </Col>
                </Row>
                <br />
              </>
            )}

            <Box className="mc-table-responsive">
              <Table className="mc-table">
                <Thead className="mc-table-head primary">
                  <Tr>
                    <Th>#ID</Th>
                    <Th>Date/Time</Th>
                    <Th>Room</Th>
                    <Th>Client</Th>
                  </Tr>
                </Thead>
                <Tbody className="mc-table-body even">
                  {data?.length > 0 &&
                    data.map((item, key) => (
                      <Tr key={key}>
                        <Td>
                          {item.uniqueId}{" "}
                          {item.cancelled == 1 && (
                            <>
                              -{" "}
                              <span className="bg-danger p-1 rounded text-white">
                                <small>Cancalled</small>
                              </span>
                            </>
                          )}
                        </Td>
                        <Td>
                          {moment(item.appointmentDate).format("MM/DD/YYYY")} <br /> {convertTimestampToAMPM(item.startTime * 1000)} - {convertTimestampToAMPM(item.endTime * 1000)}
                        </Td>
                        <Td> {item.room && item.room.name && item.room.name}</Td>
                        <Td>
                          {item.patient && (
                            <a class="mc-sidebar-menu-btn" href={"/patient-details?id=" + item.patient._id}>
                              <i class="material-icons">link</i>
                              <span>
                                {item.patient && item.patient.name && item.patient.name} {item.patient.gender != "" && "(" + item.patient.gender + ")"} <br /> {item.patient.email != "" && " " + item.patient.email + ""}
                              </span>
                            </a>
                          )}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
            <Box className="mc-paginate">
              <Text className="mc-paginate-title">
                Total <b>{totalRecod}</b> Results Found
              </Text>
              <nav aria-label="Page navigation example">
                {totalRecod > perPage ? (
                  <Pagination
                    prevPageText="prev"
                    nextPageText="next"
                    firstPageText="first"
                    lastPageText="last"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalRecod}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                  />
                ) : (
                  ""
                )}
              </nav>
            </Box>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} appointment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Col xl={12}>
            <div class="mc-label-field-group label-col">
              <label class="mc-label-field-title">Client</label>
              <div className="position-relative  w-100">
                <input type="text" className="form-control w-100" value={value} onChange={handleChange2} placeholder="Start typing to find the client" />
                {showSuggestions && (
                  <ul className="list-group position-absolute w-100 mt-1" style={{ zIndex: 10 }}>
                    {filteredOptions.slice(0, 10).map((option, i) => (
                      <li key={option._id} className="list-group-item" onClick={() => handleSelect(option)}>
                        {option.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </Col>
          <br />
          <Row>
            <Col xl={12}>
              <div className="form-group">
                <label class="mc-label-field-title">Select a room</label>
                <select className="form-control" value={room} onChange={(e) => setRoom(e.target.value)}>
                  <option value={"0"}> Select</option>
                  {roomData?.length > 0 && roomData.map((room, key) => <option value={room._id}> {room.name} </option>)}
                </select>
              </div>
            </Col>
            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title">appointment date</label>
                <DatePicker minDate={new Date()} placeholderText="Start date" className="mc-label-field-input w-100 h-md" selected={appointmentDate} onChange={(date) => setAppointmentDate(date)} dateFormat="MM/dd/yyyy" />
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title"> Appointment start time </label>
                {loading1 ? (
                  <div>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                ) : (
                  <select
                    className="form-control"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                      getEndDate(e.target.value);
                    }}
                  >
                    <option value={"0"}> Select</option>

                    {timeslots.map(
                      (item, index) =>
                        timeslots[index + 1] &&
                        item.isAvailable && (
                          <option key={index} value={moment(item.time, "HH:mm").format("h:mm A")}>
                            {moment(item.time, "HH:mm").format("h:mm A")}
                          </option>
                        )
                    )}
                  </select>
                )}
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title"> Appointment end time </label>
                {loading2 ? (
                  <div>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                ) : (
                  <select
                    className="form-control"
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                  >
                    <option value={"0"}> Select</option>

                    {timeslots2.map(
                      (item, index) =>
                        timeslots[index + 1] &&
                        item.isAvailable && (
                          <option key={index} value={moment(item.time, "HH:mm").format("h:mm A")}>
                            {moment(item.time, "HH:mm").format("h:mm A")}
                          </option>
                        )
                    )}
                  </select>
                )}
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title">Payment</label>
                <select class="mc-label-field-input w-100 h-md" value={payment} onChange={(e) => setPayment(e.target.value)}>
                  <option value={""}> Select </option>
                  <option value={"wallet"}> Use wallet balance </option>
                  <option value={"prepaid_cash"}> Prepaid by cash </option>
                  <option value={"prepaid_card"}> Prepaid by card </option>
                  <option value={"pending"}> Not paid </option>
                </select>
              </div>
            </Col>

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleBooking()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Check & Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </PageLayout>
  );
}
