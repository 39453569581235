import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, Card, Row, Col, Table, Spinner, ButtonGroup, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import qs from "qs";

function ContentData({ selectedContent }) {
  const { userData, token, selectedCompany, refreshToken } = useSelector((state) => state.counter);
  const [question, setQuestion] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [correct, setCorrect] = useState("1");

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const [Saving, setSaving] = useState(false);
  const [quizId, setQuizId] = useState("");

  const [data, setData] = useState([]);
  useEffect(() => {
    LoadData();
    setSaving(false);
  }, []);

  // Load All data To Display In Table
  function LoadData() {
    const params = {
      id: selectedContent,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    const options = {
      headers: { authorization: token },
    };

    axios.get(process.env.REACT_APP_API_URL + "app/modules/content-quiz?" + queryString, {}, options).then(
      (response) => {
        setData(response.data.result);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  async function handleMCQUpdate() {
    if (question == "") return toast.error("Question is required");
    if (option1 == "") return toast.error("Option 1 is required");
    if (option2 == "") return toast.error("Option 2 is required");

    setSaving(true);

    const options = {
      headers: { authorization: token },
    };

    const data = [];
    data["content"] = selectedContent;
    data["question"] = question;
    data["option1"] = option1;
    data["option2"] = option2;
    data["option3"] = option3;
    data["option4"] = option4;
    data["correct"] = correct;
    data["id"] = quizId;

    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/modules/mcq-update", qs.stringify(data), options);
    if (Response.data.success == true) {
      setSaving(false);
      toast(Response.data.message);
      LoadData();
      setIsOpen2(false);
    } else {
      toast(Response.data.message);
      setSaving(false);
    }
  }
  async function handleMCQCreate() {
    if (question == "") return toast.error("Question is required");
    if (option1 == "") return toast.error("Option 1 is required");
    if (option2 == "") return toast.error("Option 2 is required");

    setSaving(true);

    const options = {
      headers: { authorization: token },
    };

    const data = [];
    data["content"] = selectedContent;
    data["question"] = question;
    data["option1"] = option1;
    data["option2"] = option2;
    data["option3"] = option3;
    data["option4"] = option4;
    data["correct"] = correct;

    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/modules/mcq-create", qs.stringify(data), options);
    if (Response.data.success == true) {
      setSaving(false);
      toast(Response.data.message);
      LoadData();
      setIsOpen(false);

      setQuestion("");
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setCorrect("");
    } else {
      toast(Response.data.message);
      setSaving(false);
    }
  }
  function setEditData(data) {
    setQuestion(data.question);
    setOption1(data.option1);
    setOption2(data.option2);
    setOption3(data.option3);
    setOption4(data.option4);
    setCorrect(data.correct);
    setQuizId(data._id);
  }
  // Soft Delete Record
  async function DeleteRecord(id) {
    const options = {
      headers: { authorization: token },
    };
    const data = qs.stringify({ id: id });

    axios.post(process.env.REACT_APP_API_URL + "app/modules/mcq-delete", data, options).then((response) => {
      if (response.data.success == true) {
        toast(response.data.message);
        LoadData(1);
        setIsOpen(false);
      } else {
        toast("Something went wrong");
      }
    });
  }
  return (
    <>
      <div className="">
        <button type="submit" className="btn btn-primary float-end" onClick={(e) => setIsOpen(true)}>
          Add new question
        </button>
      </div>
      <br /> <br />
      <div className="table-responsive">
        <Table className="text-nowrap mt-3 align-middle" borderless>
          <thead>
            <tr>
              <th>Question</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item, key) => (
                <tr className="border-top" key={key}>
                  <td>{item.question}</td>

                  <td>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={(e) => {
                          setIsOpen2(true);
                          setEditData(item);
                        }}
                      >
                        Edit
                      </Button>
                      <Button size="sm" color="danger" onClick={(e) => DeleteRecord(item._id)}>
                        Delete
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <Modal size="lg" show={isOpen} onHide={() => setIsOpen(false)}>
        <ModalHeader closeButton>Add Question</ModalHeader>
        <ModalBody>
          <Card>
            <Card.Body>
              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Question</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setQuestion(e.target.value)} value={question} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Option 1</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setOption1(e.target.value)} value={option1} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Option 2</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setOption2(e.target.value)} value={option2} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Option 3</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setOption3(e.target.value)} value={option3} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Option 4</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setOption4(e.target.value)} value={option4} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Correct </Col>
                <Col className="col-md-6">
                  <select className="form-control" onChange={(e) => setCorrect(e.target.value)} value={correct}>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                    <option value="3">Option 3</option>
                    <option value="4">Option 4</option>
                  </select>
                </Col>
              </Row>

              <Row className="justify-content-center mt-4">
                <Col className="col-md-4 mt-3">
                  {Saving ? (
                    <button type="submit" className="btn btn-primary  mb-3" disabled={true}>
                      <Spinner as="span" animation="grow" size="sm" />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary  mb-3" onClick={() => handleMCQCreate()}>
                      Save
                    </button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </ModalBody>
      </Modal>{" "}
      <Modal size="lg" show={isOpen2} onHide={() => setIsOpen2(false)}>
        <ModalHeader closeButton>Edit Question</ModalHeader>
        <ModalBody>
          <Card>
            <Card.Body>
              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Question</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setQuestion(e.target.value)} value={question} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Option 1</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setOption1(e.target.value)} value={option1} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Option 2</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setOption2(e.target.value)} value={option2} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Option 3</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setOption3(e.target.value)} value={option3} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Option 4</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setOption4(e.target.value)} value={option4} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Correct </Col>
                <Col className="col-md-6">
                  <select className="form-control" onChange={(e) => setCorrect(e.target.value)} value={correct}>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                    <option value="3">Option 3</option>
                    <option value="4">Option 4</option>
                  </select>
                </Col>
              </Row>

              <Row className="justify-content-center mt-4">
                <Col className="col-md-4 mt-3">
                  {Saving ? (
                    <button type="submit" className="btn btn-primary  mb-3" disabled={true}>
                      <Spinner as="span" animation="grow" size="sm" />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary  mb-3" onClick={() => handleMCQUpdate()}>
                      Save
                    </button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
}
export default ContentData;
