import React from "react";
import { Box, Form, Heading, Button, Anchor, Image, Text } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/forgot.json";
import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Saving, setSaving] = useState(false);

  async function handleLogin() {
    if (!Email && Email == "") {
      toast.error("Enter email address");
      return;
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!Email.match(mailformat)) {
      toast.error("Enter valid email address");
      return;
    }

    setSaving(true);
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/send-otp", {
      email: Email,
    });
    if (Response.data.status == "success") {
      toast.success(Response.data.message);
      navigate("/set-password?email=" + Email);
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <Box className="mc-auth">
      <Image className="mc-auth-pattern" src={data?.pattern.src} alt={data?.pattern.alt} />
      <Box className="mc-auth-group">
        <Logo src={data?.logo.src} alt={data?.logo.alt} href={data?.logo.path} className="mc-auth-logo" />

        <Form className="mc-auth-form">
          <IconField icon={"email"} type={"email"} classes={"w-100 h-sm"} placeholder={"Enter your email"} onChange={(e) => setEmail(e.target.value)} />

          <Button className={`mc-auth-btn ${data?.button.fieldSize}`} type={data?.button.type} disable={!Saving} onClick={() => handleLogin()}>
            {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>{data?.button.text}</>}
          </Button>
        </Form>
        <Box className="mc-auth-navigate">
          <Text as="span">{data?.navigate.title}</Text>
          <Anchor href={data?.navigate.path}>{data?.navigate.text}</Anchor>
        </Box>
      </Box>
    </Box>
  );
}
