import React from "react";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { CardLayout } from "./../../components/cards";
import { useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";

import toast from "react-hot-toast";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
export default function Category() {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.counter);
  const [uploadData, setUploadData] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [introVideo, setIntroVideo] = useState("");
  const [slug, setSlug] = useState("");
  const [thumb, setThumb] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [Saving, setSaving] = useState(false);

  // Load Modules Data
  async function loadModules() {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/modules/all?id=" + id);
    let prodData = Response.data.data[0];

    setTitle(prodData.title);
    setDesc(prodData.desc);
    setMetaTitle(prodData.metaTitle);
    setMetaDesc(prodData.metaDesc);
    setIntroVideo(prodData.introVideo);
    setPrice(prodData.price);
    setCategory(prodData.category);
    setSlug(prodData.slug);
    setThumb(prodData.thumb);
  }

  async function handleModulesCreate() {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");

    if (category == "") return toast.error("Category is required");
    if (title == "") return toast.error("Title is required");
    if (desc == "") return toast.error("Descrption is required");
    if (metaTitle == "") return toast.error("Meta title is required");
    if (metaDesc == "") return toast.error("Meta descrption is required");

    setSaving(true);

    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const uploadFormData = new FormData();

    uploadFormData.append("category", category);
    uploadFormData.append("upload", uploadData);
    uploadFormData.append("title", title);
    uploadFormData.append("desc", desc);
    uploadFormData.append("metaTitle", metaTitle);
    uploadFormData.append("metaDesc", metaDesc);
    uploadFormData.append("introVideo", introVideo);
    uploadFormData.append("price", price);
    uploadFormData.append("id", id);
    uploadFormData.append("slug", slug);
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/modules/update", uploadFormData, options);
    if (Response.data.success == true) {
      setSaving(false);
      toast(Response.data.message);
      navigate("/modules");
    } else {
      toast(Response.data.message);
      setSaving(false);
    }
  }

  // On File Select Load It To Memory
  function fileHandler(e) {
    const file = e.currentTarget.files[0];
    setUploadData(file);
  }

  useEffect(() => {
    LoadCategory();
    loadModules();
    setSaving(false);
  }, []);

  function LoadCategory() {
    const options = {
      headers: { authorization: token },
    };

    axios.get(process.env.REACT_APP_API_URL + "app/category/all", {}, options).then(
      (response) => {
        setCategoryList(response.data.result);
      },
      (error) => {
        if (error.response.status == 401) {
          console.log("Error");
        }
      }
    );
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Add Module"}></Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <>
              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Category</Col>
                <Col className="col-md-6">
                  <select className="form-control" onChange={(e) => setCategory(e.target.value)} value={category}>
                    <option value=""> -- Select --</option>

                    {categoryList.map((item, i) => (
                      <option value={item._id}> {item.name} </option>
                    ))}
                  </select>
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Title</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setTitle(e.target.value)} value={title} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Description</Col>
                <Col className="col-md-6">
                  <SunEditor
                    setOptions={{
                      buttonList: [
                        ["font"],
                        ["fontSize", "formatBlock"],
                        ["align", "horizontalRule", "lineHeight", "list"],
                        ["bold", "underline", "italic", "strike"],
                        ["fontColor", "hiliteColor"],
                        ["removeFormat"],
                        ["blockquote", "outdent", "indent"],
                        ["table"],
                        ["link", "video"],
                        ["image"],
                        ["codeView", "fullScreen", "showBlocks"],
                      ],
                    }}
                    setContents={desc}
                    height="250px"
                    onChange={setDesc}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Meta Title</Col>
                <Col className="col-md-6">
                  <input className="form-control" onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Meta Description</Col>
                <Col className="col-md-6">
                  <textarea className="form-control" onChange={(e) => setMetaDesc(e.target.value)}>
                    {metaDesc}
                  </textarea>
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Intro Video </Col>
                <Col className="col-md-6">
                  <input type="url" className="form-control" onChange={(e) => setIntroVideo(e.target.value)} value={introVideo} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Price </Col>
                <Col className="col-md-6">
                  <input type="number" className="form-control" onChange={(e) => setPrice(e.target.value)} value={price} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-2">
                <Col className="col-md-2 pt-1">Image</Col>
                <Col className="col-md-6">
                  <input name="file" accept="image/*" type="file" className={"form-control"} onChange={(e) => fileHandler(e)} />
                </Col>
              </Row>

              <Row className="justify-content-center mt-4">
                <Col className="col-md-4 mt-3">
                  {Saving ? (
                    <button type="submit" className="btn btn-primary  mb-3" disabled={true}>
                      <Spinner as="span" animation="grow" size="sm" />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary  mb-3" onClick={() => handleModulesCreate()}>
                      Save
                    </button>
                  )}
                </Col>
              </Row>
            </>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
